// @flow
import axios from 'axios'

import type { FeatureToggles } from 'types/FeatureToggle'

export type Config = {
	apiUrl?: string,
	logStore?: boolean,
	featureToggles?: FeatureToggles,
	casUrl?: string,
	casLogoutUrl?: string,
	casStrongAuthOnly?: boolean,
}

const cache: { [string]: any } = {

}

async function fetch(path: string): Promise<any> {
	const origin: string = window.location.origin
	const url: string = origin + path

	let data = cache[url]

	if (data) {
		return data
	}

	data = (await axios.get(url)).data
	cache[url] = data

	return data
}

export async function fetchConfig(): Promise<Config> {
	const globalConfig: Config = await fetch('/params/global.params.json')
	const envConfig: Config = await fetch('/params/environment/params.json').catch(() => ({}))
	const overrideConfig: Config = await fetch('/params/override/params.json').catch(() => ({}))
	return { ...globalConfig, ...envConfig, ...overrideConfig }
}
