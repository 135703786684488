// @flow
import moment from 'moment'

import { getError, getValue, isSuccess } from 'types/Try'

import type { Try } from 'types/Try'

import SubscriptionRestService from '../subscription-rest-service'

export async function getMinimalStartDate(): Promise<moment> {
	const response: Try<string> = await SubscriptionRestService.getMinimalStartDate()
	if (isSuccess(response)) {
		return moment(getValue(response))
	} else {
		throw getError(response)
	}
}