// @flow
import moment from 'moment-timezone'

export const SERVER_DATE_FORMAT = 'YYYY-MM-DD'
const SERVER_TIME_FORMAT = 'HH:mm:ss'
const SERVER_DATE_TIME_FORMAT = `${SERVER_DATE_FORMAT}T${SERVER_TIME_FORMAT}`
const TALLINN_TIME_ZONE = 'Europe/Tallinn'
export const UI_DATE_FORMAT = 'DD.MM.YYYY'
const UI_TIME_FORMAT = 'HH:mm'
const UI_DATE_TIME_FORMAT = `${UI_DATE_FORMAT} ${UI_TIME_FORMAT}`

export const dateTimeUtcToDateTallinn = (value: string): string => moment.utc(value, SERVER_DATE_TIME_FORMAT)
	.tz(TALLINN_TIME_ZONE)
	.format(UI_DATE_FORMAT)

export const dateTimeUtcToTimeTallinn = (value: string): string => moment.utc(value, SERVER_DATE_TIME_FORMAT)
	.tz(TALLINN_TIME_ZONE)
	.format(UI_TIME_FORMAT)

export const dateTimeUtcToDateTimeTallinn = (value: string): string => moment.utc(value, SERVER_DATE_TIME_FORMAT)
	.tz(TALLINN_TIME_ZONE)
	.format(UI_DATE_TIME_FORMAT)

export const dateTimeUtcToUiDate = (value: string): Date => moment.utc(value, SERVER_DATE_TIME_FORMAT)
	.tz(TALLINN_TIME_ZONE)
	.toDate()

export const uiDateToServerDateTimeString = (value: Date): string => moment(value)
	.utc()
	.format(SERVER_DATE_TIME_FORMAT)

export const uiDateToServerDateString = (value: Date): string => moment(value).format(SERVER_DATE_FORMAT)

export const isoDateToUiDate = (value: string): string => moment(value)
	.format(UI_DATE_FORMAT)