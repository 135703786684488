// @flow
import { RestClient } from 'helpers'

import { API_URL } from 'Api'

import type { Try } from 'types/Try'
import type { Pageable } from 'types/Pageable'
import type { LocalCustomerDto, CustomerSearchDto } from 'types/customer'

const CUSTOMERS_URL: string = `${API_URL}/customers`

export async function search(searchText: string): Promise<Try<Pageable<CustomerSearchDto>>> {
	return RestClient.get(`${CUSTOMERS_URL}/search?searchText=${searchText}`)
}

export async function searchLocal(searchText: string): Promise<Try<Pageable<LocalCustomerDto>>> {
	return RestClient.get(`${CUSTOMERS_URL}/per/search?searchText=${searchText}`)
}