// @flow
import AsyncSelect from 'react-select/async'
import { debounce } from 'lodash'
import { NotificationManager } from 'react-notifications'
import { useTranslation } from 'react-i18next'
import { FormControl, FormHelperText } from '@mui/material'
import type { Try } from 'types/Try'
import { getError, getValue, isSuccess } from 'types/Try'
import { LOCALE_AUTO_SUGGEST_PRODUCTS, LOCALE_NAMESPACE_COMMON } from 'constants/locale'
import { getAllProducts } from 'views/Product/List/serviceWrapper'
import { MIN_LENGTH, NOTIFICATION_SHOW_TIME_MS, WAIT_TIME_MS } from 'shared/products/constants'

import type { Pageable } from 'types/Pageable'
import type { Translator } from 'helpers'
import type { Element } from 'react'
import type { FieldRenderProps } from 'react-final-form'
import type { ProductDto, ProductFilters, ProductOption } from 'types/product'

export type AutoSuggestProductViewProps = {|
	onBlur?: () => void,
	onChange: (ProductDto) => void,
	onFocus?: () => void,
	formControlClassName: string,
|}

function AutoSuggestProductView({
	onBlur,
	onFocus,
	formControlClassName,
	input,
	meta: { touched, submitFailed, error },
}: AutoSuggestProductViewProps & FieldRenderProps): Element<*> {
	const { t }: { t: Translator } = useTranslation(LOCALE_NAMESPACE_COMMON, {
		keyPrefix: LOCALE_AUTO_SUGGEST_PRODUCTS,
	})

	const handleBlur = (): void => {
		if (onBlur) {
			onBlur()
		}
	}

	const handleFocus = (): void => {
		if (onFocus) {
			onFocus()
		}
	}

	const handleOptionsLoading = (searchValue: string, setOptionsCallback: Function): void =>
		debounceSuggestProducts(searchValue, setOptionsCallback)

	const debounceSuggestProducts: (searchValue: string, setOptionsCallback: Function) => void = debounce(
		(searchValue, setOptionsCallback) => {
			suggestProducts(searchValue).then((options) => {
				setOptionsCallback(options)
			})
		},
		WAIT_TIME_MS
	)

	function buildSearchRequest(searchValue: string): ProductFilters {
		return isNaN(searchValue)
			? {
					name: searchValue,
				}
			: {
					code: searchValue,
				}
	}

	const suggestProducts = async (searchValue: string): Promise<Array<ProductOption>> => {
		if (searchValue && searchValue.length >= MIN_LENGTH) {
			const response: Try<Pageable<ProductDto>> = await getAllProducts(-1, 10, {
				...buildSearchRequest(searchValue),
			})
			if (isSuccess(response)) {
				return mapToOptions(getValue(response).content)
			} else {
				NotificationManager.error(getError(response).message, t('search.fail'), NOTIFICATION_SHOW_TIME_MS)
				return []
			}
		} else {
			return []
		}
	}

	const mapToOptions = (products: Array<ProductDto>): Array<ProductOption> =>
		products
			.map((product: ProductDto): ProductOption => ({ product, label: createLabel(product) }))
			.filter((option: ProductOption): boolean => Boolean(option.label))

	const createLabel = (product: ProductDto) => {
		if (product.name && product.code) {
			return `${product.name} (${product.code})`
		}
		return ''
	}

	return (
		<FormControl className={formControlClassName} error={error && (touched || submitFailed)}>
			<AsyncSelect
				loadOptions={handleOptionsLoading}
				onChange={input.onChange}
				value={input.value}
				placeholder={t('placeholder')}
				noOptionsMessage={() => t('no_options')}
				loadingMessage={() => t('loading')}
				onBlur={handleBlur}
				onFocus={handleFocus}
				isClearable
				defaultOptions
				maxMenuHeight={200}
				styles={{
					// Fixes the overlapping problem of the component
					menu: (provided) => ({ ...provided, zIndex: 9999 }),
				}}
			/>
			{error && (touched || submitFailed) ? (
				<FormHelperText>{(touched || submitFailed) && Object.values(error)}</FormHelperText>
			) : null}
		</FormControl>
	)
}

export default AutoSuggestProductView
