// @flow
import { getCurrentUser } from 'shared/current-user'
import { connect } from 'react-redux'

import type { Store } from 'types/Store'

import PermissionComponent from './PermissionComponent'

import type { PermissionComponentProps } from './PermissionComponent'

const mapStateToProps = (state: Store) => ({
	userInfo: getCurrentUser(state),
})

const mapDispatchToProps = {}

export default connect<PermissionComponentProps, any, _, _, _, _>(
	mapStateToProps,
	mapDispatchToProps
)(PermissionComponent)
