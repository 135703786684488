// @flow
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import type { Translator } from 'helpers'
import type { Element } from 'react'

import { getSubscriptionsUrl } from 'Paths'
import { LOCALE_NAMESPACE_SUBSCRIPTIONS, LOCALE_SUBSCRIPTIONS } from 'constants/locale'

import './CancelDialog.scss'

export type CancelDialogProps = {|
	open: boolean,
	setOpen: (boolean) => void,
	isNewSubscription: boolean,
|}

function CancelDialog({ open, setOpen, isNewSubscription }: CancelDialogProps): Element<*> {
	const { t }: { t: Translator } = useTranslation(LOCALE_NAMESPACE_SUBSCRIPTIONS, { keyPrefix: LOCALE_SUBSCRIPTIONS })

	return (
		<Dialog
			open={open}
			onClose={(): void => {
				setOpen(false)
			}}
		>
			<DialogContent>
				<div className="create-subscription-form-cancel-dialog">
					<DialogContentText className="title">
						<span className="span">{isNewSubscription ? t('form.cancel_create_dialog') : t('form.cancel_edit_dialog')}</span>
					</DialogContentText>
				</div>
			</DialogContent>
			<DialogActions>
				<div className="sub-btn-dialog-container">
					<button
						onClick={(): void => {
							setOpen(false)
						}}
						type="button"
						className="btn-success btn-primary btn btn-no"
					>
						{t('form.cancel_dialog.no')}
					</button>
					<Link to={getSubscriptionsUrl()}>
						<button
							className="btn-success btn-primary btn btn-yes"
							type="button"
							onClick={(): void => {
								setOpen(false)
							}}
						>
							{t('form.cancel_dialog.yes')}
						</button>
					</Link>
				</div>
			</DialogActions>
		</Dialog>
	)
}

export default CancelDialog