// @flow
import { AppBar } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import { LOCALE_NAMESPACE_SUBSCRIPTIONS, LOCALE_SUBSCRIPTIONS } from 'constants/locale'
import { ROLE } from 'constants/auth/roles'
import * as formFields from 'constants/subscription/form/SubscriptionForm'

import type { FormApi } from 'final-form'
import type { Translator } from 'helpers'
import type { SubscriptionFormValues } from 'types/subscription/form/SubscriptionForm'
import type { DeliveryDto } from 'types/subscription/Subscription'

import { getSubscriptionsUrl } from 'Paths'
import { Permission } from 'components'
import SubscriptionSuspendView from 'views/Subscription/Suspend'
import SubscriptionRedirectView from 'views/Subscription/Redirect'
import SubscriptionCancelView from 'views/Subscription/Cancel'

import CancelDialog from './CancelDialog/CancelDialog'

import './Footer.scss'

export type FooterProps = {|
	form: FormApi<SubscriptionFormValues>,
	isNewSubscription: boolean,
	subscriptionStartDate: ?string,
	activeDelivery: ?DeliveryDto,
	isNeedsAttention: boolean,
|}

function Footer({ form, isNewSubscription, subscriptionStartDate, activeDelivery, isNeedsAttention }: FooterProps) {
	const { t }: { t: Translator } = useTranslation(LOCALE_NAMESPACE_SUBSCRIPTIONS, { keyPrefix: LOCALE_SUBSCRIPTIONS })

	const [open, setOpen] = useState(false)
	const [openSuspendPanel, setOpenSuspendPanel] = useState(false)
	const [openRedirectPanel, setOpenRedirectPanel] = useState(false)
	const [openCancelPanel, setOpenCancelPanel] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	function handleIsSaveAndNew(value: boolean): void {
		form.change(formFields.IS_ADD_AND_NEW, value)
	}

	return (
		<AppBar position="fixed" className="create-subscription-form-bottom-bar" color="inherit" sx={{ top: 'auto', bottom: 0, left: 0 }}>
			<div className="container">
				<div className="row">
					<div className="col-8">
						<div className="btn-container">
							{(isNewSubscription && (
								<div className="form-buttons">
									<button type="button" onClick={handleClickOpen} className="btn-success btn-primary btn btn-cancel">
										{t('form.cancel')}
									</button>
									<button type="submit" className="btn-success btn-primary btn btn-create" onClick={() => handleIsSaveAndNew(false)}>
										{t('form.create_subscription')}
									</button>
									<button type="submit" className="btn-success btn-primary btn btn-create" onClick={() => handleIsSaveAndNew(true)}>
										{t('form.create_and_new_subscription')}
									</button>
								</div>
							)) || (
								<div className="form-buttons">
									{!isNeedsAttention && (
										<Link to={getSubscriptionsUrl()}>
											<button
												className="btn-success btn-primary btn btn-back"
												type="button"
												onClick={(): void => {
													setOpen(false)
												}}
											>
												{t('form.back_to_list')}
											</button>
										</Link>
									)}
									{isNeedsAttention && (
										<Permission availableForRole={ROLE.ROLE_PER_EXT_EXAMPLE}>
											<button type="button" onClick={handleClickOpen} className="btn-success btn-primary btn btn-cancel-edit">
												{t('form.cancel')}
											</button>
										</Permission>
									)}
									{activeDelivery && (
										<Permission availableForRole={ROLE.ROLE_PER_EXT_EXAMPLE}>
											<span className="width-60">
												<button
													type="button"
													onClick={(): void => {
														setOpenRedirectPanel(true)
													}}
													className="btn-success btn-primary btn btn-cancel"
												>
													{t('form.redirect_subscription')}
												</button>
												<button
													type="button"
													onClick={(): void => {
														setOpenSuspendPanel(true)
													}}
													className="btn-success btn-primary btn btn-cancel"
												>
													{t('form.suspend_subscription')}
												</button>
												<button
													type="button"
													onClick={(): void => {
														setOpenCancelPanel(true)
													}}
													className="btn-success btn-primary btn btn-cancel"
												>
													{t('form.cancel_subscription')}
												</button>
											</span>
										</Permission>
									)}
									{isNeedsAttention && (
										<Permission availableForRole={ROLE.ROLE_PER_EXT_EXAMPLE}>
											<button type="submit" className="btn-success btn-primary btn btn-create">
												{t('form.apply_changes')}
											</button>
										</Permission>
									)}
								</div>
							)}
						</div>
					</div>
					<CancelDialog open={open} setOpen={setOpen} isNewSubscription={isNewSubscription} />
					<SubscriptionSuspendView
						open={openSuspendPanel}
						setOpen={setOpenSuspendPanel}
						subscriptionStartDate={subscriptionStartDate}
						subscriptionId={form.getState().values?.id}
						delivery={activeDelivery}
					/>
					<SubscriptionRedirectView
						open={openRedirectPanel}
						setOpen={setOpenRedirectPanel}
						subscriptionStartDate={subscriptionStartDate}
						name={form.getState().values?.name}
						surname={form.getState().values?.surname}
						companyName={form.getState().values?.companyName}
						subscriptionId={form.getState().values?.id}
						delivery={activeDelivery}
					/>
					<SubscriptionCancelView
						open={openCancelPanel}
						setOpen={setOpenCancelPanel}
						subscriptionStartDate={subscriptionStartDate}
						deliveryEndDate={activeDelivery?.appliedTo}
						subscriptionId={form.getState().values?.id}
					/>
				</div>
			</div>
		</AppBar>
	)
}

export default Footer