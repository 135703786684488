// @flow
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { MenuItem } from '@mui/material'
import { NotificationManager } from 'react-notifications'
import moment from 'moment'
import { OnChange } from 'react-final-form-listeners'

import DatePickerView from 'components/Form/DatePicker'
import TitleLine from 'components/Form/TitleLine'
import { renderSelectField, renderTextField } from 'components/Form/redux-material-ui/FormFieldBridges'
import { getError, getValue, isSuccess } from 'types/Try'
import { getAllProductsByState } from 'services/product-rest-service/ProductRestService'

import * as formFields from 'constants/subscription/form/SubscriptionForm'
import { LOCALE_NAMESPACE_SUBSCRIPTIONS, LOCALE_SUBSCRIPTIONS } from 'constants/locale'
import { PERIOD_UNIT } from 'constants/subscription/Subscription'
import { OMNIVA_CUSTOMER } from 'constants/customer'

import type { Translator } from 'helpers'
import type { Try } from 'types/Try'
import type { Pageable } from 'types/Pageable'
import type { ProductDto } from 'types/product'
import type { Element } from 'react'
import type { SubscriptionFormValues } from 'types/subscription/form/SubscriptionForm'
import type { FormApi } from 'final-form'
import type { SubscriptionFieldsCheckResponse } from 'types/subscription/Subscription'

import { SelectItems } from '../../SubscriptionFormUtilities'
import { handleSubscriptionEndDateChange } from '../../SubscriptionCreateActions'

const NOTIFICATION_SHOW_TIME_MS: number = 10000

export type SubscriptionDataSectionProps = {|
	form: FormApi<SubscriptionFormValues>,
	values: ?SubscriptionFormValues,
	isNewSubscription: boolean,
	checkFieldsResult?: ?SubscriptionFieldsCheckResponse,
	isNeedsAttention: boolean,
	minStartDate: moment,
|}

function SubscriptionDataSectionView({
	form,
	values,
	isNewSubscription,
	checkFieldsResult,
	isNeedsAttention,
	minStartDate,
}: SubscriptionDataSectionProps) {
	const { t }: { t: Translator } = useTranslation(LOCALE_NAMESPACE_SUBSCRIPTIONS, { keyPrefix: LOCALE_SUBSCRIPTIONS })
	const [productOptions, setProductOptions] = useState<Array<Element<typeof MenuItem>>>([])
	const [applicableToMinDate, setApplicableToMinDate] = useState<moment>(moment())
	const [datePickerDisabled, setDatePickerDisabled] = useState<boolean>(false)

	useEffect((): void => {
		getAllProductsByState('ACTIVE').then((response: Try<Pageable<ProductDto>>): void => {
			if (isSuccess(response)) {
				const result: Array<ProductDto> = getValue(response).content
				const options: Array<Element<typeof MenuItem>> = result.map((product: ProductDto) => (
					<MenuItem key={product.id} value={product.id}>
						{product.name} ({product.code})
					</MenuItem>
				))
				setProductOptions(options)
			} else {
				NotificationManager.error(getError(response).message, t('search.fail'), NOTIFICATION_SHOW_TIME_MS)
				setProductOptions([])
			}
		})
	}, [t])

	function calculateEndDate() {
		if (values && values[formFields.SUBSCRIPTION_START_DATE]) {
			if (values[formFields.PERIOD] && values[formFields.PERIOD_UNIT]) {
				const endDate =
					values[formFields.PERIOD_UNIT] !== 'NOT_DETERMINED_END'
						? moment(values[formFields.SUBSCRIPTION_START_DATE])
								.add(values[formFields.PERIOD], values[formFields.PERIOD_UNIT])
								.subtract(1, 'days')
								.format('YYYY-MM-DD')
						: undefined
				handleSubscriptionEndDateChange(form, endDate)
			}
		}
	}

	return (
		<div>
			<TitleLine title={t('form.subscription_data')} />
			<div className="py-3">
				<label className="form-label">{t('form.subscription_code')}</label>
				<Field
					disabled={!isNewSubscription}
					className="field"
					name={formFields.SUBSCRIPTION_CODE}
					component={renderTextField}
				/>
			</div>
			<div className="py-3">
				<label className="form-label">{t('form.publication')}</label>
				<span className="required-label">*</span>
				<Field
					disabled={!isNewSubscription}
					formControlClassName="form-control"
					className="field publication-select"
					name={formFields.PUBLICATION}
					component={renderSelectField}
				>
					{productOptions}
				</Field>
			</div>
			<div className="py-3">
				<label className="form-label">{t('form.quantity')}</label>
				<span className="required-label">*</span>
				<Field
					disabled={!isNewSubscription}
					className="field"
					name={formFields.QUANTITY}
					component={renderTextField}
				/>
			</div>
			<div className="py-3">
				<label className="form-label">{t('form.period')}</label>
				<div className="periods">
					<div className="number">
						<Field
							disabled={!isNewSubscription}
							className="field narrow-field"
							name={formFields.PERIOD}
							component={renderTextField}
						/>
						<OnChange name={formFields.PERIOD}>
							{(): void => {
								calculateEndDate()
							}}
						</OnChange>
					</div>
					<Field
						disabled={!isNewSubscription}
						formControlClassName="form-control"
						className="field narrow-field"
						name={formFields.PERIOD_UNIT}
						component={renderSelectField}
					>
						{SelectItems(PERIOD_UNIT, 'period_unit')}
					</Field>
					<OnChange name={formFields.PERIOD_UNIT}>
						{(value: string): void => {
							if (value === 'NOT_DETERMINED_END') {
								setDatePickerDisabled(true)
								calculateEndDate()
							} else {
								setDatePickerDisabled(false)
								calculateEndDate()
							}
						}}
					</OnChange>
				</div>
			</div>
			<div className="py-3">
				<label className="form-label">{t('form.subscription_dates')}</label>
				<span className="required-label">*</span>
				<div className="dates">
					<div className="left-date">
						{!isNewSubscription && isNeedsAttention && !checkFieldsResult?.periodOk && (
							<div className="red-text">{values?.subscriptionStartDate}</div>
						)}
						<Field
							disabled={!isNewSubscription}
							name={formFields.SUBSCRIPTION_START_DATE}
							width={180}
							component={DatePickerView}
							minDate={isNewSubscription && minStartDate}
						/>
						<OnChange name={formFields.SUBSCRIPTION_START_DATE}>
							{(date: moment): void => {
								setApplicableToMinDate(moment(date) || moment())
								calculateEndDate()
							}}
						</OnChange>
					</div>
					<div className="right-date">
						{!isNewSubscription && isNeedsAttention && !checkFieldsResult?.periodOk && (
							<div className="red-text">{values?.subscriptionEndDate}</div>
						)}
						<Field
							name={formFields.SUBSCRIPTION_END_DATE}
							width={180}
							component={DatePickerView}
							minDate={applicableToMinDate}
							disabled={datePickerDisabled || !isNewSubscription}
						/>
					</div>
				</div>
			</div>
			{!isNewSubscription && values?.registrant && values?.registrant.cmsId === OMNIVA_CUSTOMER.cmsId && (
				<div>
					<div className="py-3">
						<label className="form-label">{t('form.subscription_cost')}</label>
						<Field
							disabled={!isNewSubscription}
							className="field"
							name={formFields.SUBSCRIPTION_COST}
							component={renderTextField}
						/>
					</div>
					<div className="py-3">
						<label className="form-label">{t('form.receipt_number')}</label>
						<Field
							disabled={!isNewSubscription}
							className="field"
							name={formFields.RECEIPT_NUMBER}
							component={renderTextField}
						/>
					</div>
				</div>
			)}
		</div>
	)
}

export default SubscriptionDataSectionView
