// @flow
import { handleActions } from 'redux-actions'

import { ROWS_PER_PAGE_OPTIONS, HISTORY_COLUMN } from 'constants/subscription/Subscription'

import type { Action, EventListStore } from 'types/Store'
import type { EventSort, RowsPerPageChange } from 'types/subscription/Subscription'

import { updatePage, updateRowsPerPage, updateSort } from './eventListActions'

const initialState: EventListStore = {
	page: 1,
	rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
	sort: {
		name: HISTORY_COLUMN.DATE,
		direction: 'desc',
	},
}

export default handleActions<EventListStore, Action>({
	[updatePage.toString()](state, { payload }: { payload: number }) {
		return {
			...state,
			page: payload,
		}
	},
	[updateRowsPerPage.toString()](state, { payload }: { payload: RowsPerPageChange }) {
		return {
			...state,
			rowsPerPage: payload.rowsPerPage,
			page: payload.page,
		}
	},
	[updateSort.toString()](state, { payload }: { payload: EventSort }) {
		return {
			...state,
			sort: payload,
		}
	},
}, initialState)