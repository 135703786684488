// @flow

export type ErrorWithResponseCode = Error & {
	response?: {
		status?: number,
	},
}

export type Try<T> = T | Error

export function isSuccess(data: any): boolean %checks {
	return !(data === null || data === undefined || (data instanceof Error))
}

export function isFatal(data: any): boolean %checks {
	return data && (data instanceof Error)
}

export function getValue<T>(tryValue: Try<T>): T {
	if (tryValue instanceof Error) {
		throw new Error('Expected value')
	}
	return tryValue
}

export function getError <T>(tryValue: Try<T>): ErrorWithResponseCode {
	if (tryValue instanceof Error) {
		return tryValue
	}
	throw new Error('Expected error')
}