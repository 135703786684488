// @flow

import Sort from 'constants/sort'

export default class SortRequest {
	field: string
	direction: string

	constructor(field: string, direction: string = Sort.ASC) {
		this.field = field
		this.direction = direction

		if (direction !== Sort.ASC && direction !== Sort.DESC) {
			throw new TypeError(`direction should be "${Sort.ASC}" or "${Sort.DESC}" but present: ${direction}`)
		}

		if (field === '') {
			throw new TypeError('field must not be empty string')
		}
	}

	toString(): string {
		return `${this.field},${this.direction}`
	}

	static fromString(str: string): SortRequest {
		if (!str) {
			throw new TypeError(`Cannot construct Sort form a string ${str}. Correct formats are "field" or "field,direction"`); // eslint-disable-line
		}
		const [field, direction] = str.split(',')
		return new SortRequest(field, direction)
	}
}