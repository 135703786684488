// @flow
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import useSWR from 'swr'

import AutoSuggestProductView from 'components/AutoSuggestProduct/AutoSuggestProductView'
import { renderCheckbox, renderSelectField, renderTextField } from 'components/Form/redux-material-ui/FormFieldBridges'
import AutoSuggestAddressViewWithText from 'components/AutoSuggestAddressWithText/AutoSuggestAddressViewWithText'
import DatePickerView from 'components/Form/DatePicker'
import { LOCALE_NAMESPACE_SUBSCRIPTIONS, LOCALE_NAMESPACE_SUBSCRIPTIONS_FILTER } from 'constants/locale'
import { STATUS } from 'constants/subscription/Subscription'
import * as formFields from 'constants/subscription/filter/SubscriptionFilterForm'
import { isSuccess, getValue } from 'types/Try'

import type { FormRenderProps } from 'react-final-form'
import type { SubscriptionFilters } from 'types/subscription/Subscription'
import type { Translator } from 'helpers'
import type { Store } from 'types/Store'

import { getRegistrant } from '../serviceWrapper'
import { SelectItems, SelectItemsWithoutTranslation } from '../../SubscriptionFormUtilities'
import './FilterSubscriptionsView.scss'

export type FilterSubscriptionsProps = {
	onFilterSubmit: (SubscriptionFilters) => void,
	onFilterClear: () => void,
	initialValues: SubscriptionFilters | Object,
}

function FilterSubscriptionsView({ onFilterSubmit, onFilterClear, initialValues }: FilterSubscriptionsProps) {
	const { t }: { t: Translator } = useTranslation(LOCALE_NAMESPACE_SUBSCRIPTIONS, {
		keyPrefix: LOCALE_NAMESPACE_SUBSCRIPTIONS_FILTER,
	})

	const customerId = useSelector((state: Store) => state.currentUser?.userInfo?.customer?.customerId)
	const { data: subscriptionRegistrants } = useSWR(`registrant-${customerId || ''}`, getRegistrant)

	const registrantsOptions: Object = isSuccess(subscriptionRegistrants)
		? getValue(subscriptionRegistrants).content.reduce(
				(acc, val) => ({
					...acc,
					[val.id]: val.name,
				}),
				{ ALL: t('registrant.ALL') }
			)
		: {}

	return (
		<div className="filter-subscriptions">
			<Form onSubmit={onFilterSubmit} initialValues={initialValues}>
				{({ handleSubmit, form, pristine }: FormRenderProps<SubscriptionFilters>): React$Element<*> => (
					<form id="filter-form" className="filter-form mb-3" onSubmit={handleSubmit}>
						<div className="first-line">
							<div className="subscription-code padding-10">
								<Field
									className="filter-field"
									name={formFields.SUBSCRIPTION_CODE}
									component={renderTextField}
									placeholder={t('label.subscription_code')}
								/>
							</div>
							<Field
								className="filter-field"
								name={formFields.RECIPIENT_NAME}
								component={renderTextField}
								filterField={formFields.RECIPIENT_NAME}
								placeholder={t('label.recipient_firstname')}
							/>
							<Field
								className="filter-field"
								name={formFields.RECIPIENT_SURNAME}
								component={renderTextField}
								filterField={formFields.RECIPIENT_SURNAME}
								placeholder={t('label.recipient_surname')}
							/>
						</div>
						<div className="second-line">
							<div className="form-fields">
								<span className="auto-suggest-product-container">
									<Field
										name={formFields.PUBLICATION}
										component={AutoSuggestProductView}
										height={180}
										className="mb-2"
										filterField={formFields.PUBLICATION}
										placeholder={t('label.index')}
									/>
								</span>
								<span className="auto-suggest-product-container">
									<Field
										name={formFields.DESTINATION}
										component={AutoSuggestAddressViewWithText}
										filterField={formFields.DESTINATION}
										placeholder={t('label.destination')}
										form={form}
									/>
								</span>
								<span className="status-filter ">
									<Field
										name={formFields.STATUS}
										filterField={formFields.STATUS}
										component={renderSelectField}
									>
										{SelectItems(STATUS, 'status')}
									</Field>
								</span>
							</div>
						</div>
						<div className="third-line">
							<div className="form-fields">
								<span className="include-expired bottom">
									<Field
										name={formFields.INCLUDE_EXPIRED}
										type="checkbox"
										component={renderCheckbox}
										id="includeExpired"
									/>
									<label htmlFor={formFields.INCLUDE_EXPIRED}>
										{t('label.search_for_expired_subscriptions')}
									</label>
								</span>
								<span className="registrant-filter bottom">
									<Field
										name={formFields.REGISTRANT}
										filterField={formFields.REGISTRANT}
										component={renderSelectField}
									>
										{SelectItemsWithoutTranslation(registrantsOptions)}
									</Field>
								</span>
								<span className="registration-date-filter">
									<label htmlFor={formFields.PROVIDED_FROM}>{t('label.registration.period')}</label>
									<div className="dates-container">
										<Field name={formFields.PROVIDED_FROM} component={DatePickerView} />
										<div className="dash">&mdash;</div>
										<Field name={formFields.PROVIDED_TO} component={DatePickerView} />
									</div>
								</span>
							</div>
						</div>
						<div className="form-buttons">
							<button className="btn search-button mr-1" type="submit" disabled={pristine}>
								{t('button.search')}
							</button>

							<button
								className="btn clear-button"
								type="button"
								onClick={() => {
									form.reset()
									onFilterClear()
								}}
							>
								{t('button.clear_filters')}
							</button>
						</div>
					</form>
				)}
			</Form>
		</div>
	)
}

export default FilterSubscriptionsView
