// @flow
import { createRoot } from 'react-dom/client'

import type { StoreOptions } from 'store'
import type { Config } from 'AppConfig'
import type { Store as ReduxStore } from 'redux'

import Api from 'Api'
import Cas from 'Cas'
import configureStore from 'store'
import { setupAutoLogout } from 'helpers/rest/RestInterceptors'
import { fetchConfig } from 'AppConfig'

import 'react-notifications/lib/notifications.css'

import App from './App'

import './index.scss'

(async function main(): Promise<void> {
	const config: Config = await fetchConfig()
	applyConfig(config)
	const store: ReduxStore<*, *> = configureStore(getStoreOptions(config))
	setupAutoLogout(store.dispatch)
	renderApp(config, store)
}())

const getStoreOptions = (config: Config): StoreOptions => ({
	logStore: config.logStore || false,
})

const applyConfig = (config: Config): void => {
	Api.setDefaultPathDescription(config.apiUrl || '')
	Cas.setCasUrl(config.casUrl || '')
	Cas.setCasLogoutUrl(config.casLogoutUrl || '')
	Cas.setCasLogoutUrl(config.casLogoutUrl || '')
	Cas.setStrongAuthOnly(config.casStrongAuthOnly)
	Api.setFeatureToggles(config.featureToggles || {})
}

function renderApp(config: Config, store: ReduxStore<*, *>): void {
	const rootElement: ?HTMLElement = document.getElementById('root')
	if (rootElement) {
		const root: * = createRoot(rootElement)
		root.render(<App config={config} store={store} />)
	}
}