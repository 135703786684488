// @flow
import Cas from 'Cas'

import { PATH_CAS_REDIRECT } from 'Paths'

function CasAuth() {
	const target: string = `${window.location.origin}${PATH_CAS_REDIRECT}`
	window.location.href = `${Cas.getCasUrl()}/login
		?TARGET=${target}
		&FAIL_TARGET=${target}
		&locale=ET_ee
		&NEW=${String(Cas.getStrongAuthOnly())}`
	return null
}

export default CasAuth