// @flow
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { isNull } from 'lodash'

import { getLanguage, setLanguage } from 'helpers/localization/i18n'

import { LOCALE_LANGUAGE_SELECT, LOCALE_NAMESPACE_COMMON, SUPPORTED_LANGUAGES } from 'constants/locale'

import type { Element } from 'react'
import type { Translator } from 'helpers'

import './LanguageSelectForLoginView.scss'

function LanguageSelectForLoginView(): Element<*> {
	const { t }: { t: Translator } = useTranslation(LOCALE_NAMESPACE_COMMON, { keyPrefix: LOCALE_LANGUAGE_SELECT })

	const getLanguageItem = (code: string): React$Element<*> => (
		<Fragment key={code}>
			<input
				type="radio"
				id={code}
				name="radioLanguage"
				value={code}
				onChange={(): void => {
					setLanguage(code)
				}}
				checked={getLanguage() === code}
			/>

			<label
				htmlFor={code}
			>
				{ t(`language.code.${code}`) }
			</label>
		</Fragment>
	)

	const getLanguageItemList = () => SUPPORTED_LANGUAGES.map(getLanguageItem).filter(x => !isNull(x))

	return (
		<div className="language-select">
			{getLanguageItemList()}
		</div>
	)
}

export default LanguageSelectForLoginView