// @flow
import { useTranslation } from 'react-i18next'
import { NotificationManager } from 'react-notifications'
import { Chip, Grid } from '@mui/material'
import { useEffect, useState } from 'react'

import { CustomFooter, LocalizedWithVisualFixMuiDataTable } from 'components'
import TitleLine from 'components/Form/TitleLine'
import { getSubscriptionEvents } from 'views/Subscription/Form/History/serviceWrapper'
import { getError, getValue, isSuccess } from 'types/Try'
import { dateTimeUtcToDateTallinn } from 'helpers/converter/datesTransfomer'

import { LOCALE_NAMESPACE_SUBSCRIPTIONS, LOCALE_SUBSCRIPTIONS } from 'constants/locale'
import { HISTORY_COLUMN, ROWS_PER_PAGE_OPTIONS } from 'constants/subscription/Subscription'

import type { Element } from 'react'
import type { Translator } from 'helpers'
import type { ApplicationPeriod, Client, EventDto, EventSort, EventTableRowWrapper, EventTypeEnum, RowsPerPageChange } from 'types/subscription/Subscription'
import type { Pageable } from 'types/Pageable'
import type { Try } from 'types/Try'
import type { TableChangeAction, TableState } from 'types/components/Table'

const COLUMN_PREFIX: string = 'history.column'
const NOTIFICATION_SHOW_TIME_MS: number = 10000

export type HistoryListViewProps = {|
	subscriptionId: ?string,
	page: number,
	rowsPerPage: number,
	sort: ?EventSort,
	updatePage: (page: number) => void,
	updateRowsPerPage: (change: RowsPerPageChange) => void,
	updateSort: (sort: EventSort) => void,
|}

function HistoryListView({ subscriptionId, page, rowsPerPage, sort, updatePage, updateRowsPerPage, updateSort }: HistoryListViewProps): Element<*> {
	const { t }: { t: Translator } = useTranslation(LOCALE_NAMESPACE_SUBSCRIPTIONS, { keyPrefix: LOCALE_SUBSCRIPTIONS })

	const [data, setData] = useState<Array<EventTableRowWrapper>>([])
	const [totalElements, setTotalElements] = useState<number>(0)

	useEffect((): void => {
		getSubscriptionEvents(Number(subscriptionId), page - 1, rowsPerPage, sort)
			.then((response: Try<Pageable<EventDto>>): void => {
				if (isSuccess(response)) {
					setTotalElements(response.totalElements || 0)
					const tableData = wrapDtos(getValue(response).content)
					setData(tableData)
				} else {
					setData([])
					NotificationManager.error(getError(response).message, t('history_search.fail'), NOTIFICATION_SHOW_TIME_MS)
				}
			})
	}, [subscriptionId, page, rowsPerPage, sort, t])

	return (
		<div>
			<TitleLine title={t('form.subscription_history')} />
			<Grid className="subscription-list-view client-area" container justify="flex-start" alignItems="flex-start">
				<Grid item xs={12} className="table-grid">
					<LocalizedWithVisualFixMuiDataTable
						data={data}
						columns={[
							{
								name: HISTORY_COLUMN.DATE,
								label: t(`${COLUMN_PREFIX}.date`),
							},
							{
								name: HISTORY_COLUMN.EVENT,
								label: t(`${COLUMN_PREFIX}.event`),
								options: {
									customBodyRender: (value: EventTypeEnum) => (
										value ? (
											<Chip variant="outlined" label={formatEventType(value, t)} />
										) : null
									),
								},
							},
							{
								name: HISTORY_COLUMN.APPLICATION_PERIOD,
								label: t(`${COLUMN_PREFIX}.application_period`),
								options: {
									customBodyRender: (value: ApplicationPeriod) => (
										value ? (
											<div>{formatDate(value.applicationStartDate)} - {formatDate(value.applicationEndDate)}</div>
										) : null
									),
								},
							},
							{
								name: HISTORY_COLUMN.RECIPIENT,
								label: t(`${COLUMN_PREFIX}.recipient`),
								options: {
									customBodyRender: (value: Client) => (
										value ? (
											<div>
												<div>{value.name} {value.surname}</div>
												<div>{value.companyName}</div>
											</div>
										) : null
									),
								},
							},
							{
								name: HISTORY_COLUMN.EVENT_PERFORMER,
								label: t(`${COLUMN_PREFIX}.event_performer`),
							},
						]}
						options={{
							serverSide: true,
							download: false,
							print: false,
							selectableRows: 'none',
							sortOrder: sort,
							search: false,
							filter: false,
							page,
							rowsPerPage,
							count: totalElements,
							customFooter: (
								count: number,
								footerPage: number,
								footerRowsPerPage: number,
								changeRowsPerPage: (value: number) => void,
								changePage: (value: number) => void,
							): Element<*> => (
								<CustomFooter
									page={footerPage}
									rowsPerPage={footerRowsPerPage}
									changeRowsPerPage={changeRowsPerPage}
									changePage={changePage}
									totalElements={count}
									rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
								/>
							),
							onTableChange: (action: TableChangeAction, tableState: TableState): void => {
								if (action === 'changePage') {
									updatePage(tableState.page)
								} else if (action === 'changeRowsPerPage') {
									updateRowsPerPage({
										rowsPerPage: tableState.rowsPerPage,
										page: tableState.page,
									})
								} else if (action === 'sort') {
									const { name, direction } = tableState.sortOrder
									if (name && direction) {
										updateSort({ name, direction })
									}
								}
							},
						}}
					/>
				</Grid>
			</Grid>
		</div>
	)
}

function wrapDtos(tableData: Array<EventDto>): Array<EventTableRowWrapper> {
	return tableData.map((value: EventDto): EventTableRowWrapper => ({
		date: formatDate(value.created),
		event: value.eventType,
		applicationPeriod: {
			applicationStartDate: value.appliedFrom,
			applicationEndDate: value.appliedTo,
		},
		recipient: value.recipient,
		eventPerformer: value.registeredBy.name,
	}))
}

function formatDate(utcDate: ?string): string {
	return (utcDate && dateTimeUtcToDateTallinn(utcDate)) || ''
}

function formatEventType(eventType: EventTypeEnum, t: Translator): string {
	return (eventType && t(`event_type.${eventType}`)) || ''
}

export default HistoryListView