// @flow
import AsyncSelect from 'react-select/async'
import { debounce } from 'lodash'
import { NotificationManager } from 'react-notifications'
import { useTranslation } from 'react-i18next'
import { FormControl, FormHelperText } from '@mui/material'

import { CustomerRestService, ServicePointRestService } from 'services'
import { getError, getValue, isSuccess } from 'types/Try'

import { LOCALE_AUTO_SUGGEST_HUB_OR_CUSTOMER, LOCALE_NAMESPACE_COMMON } from 'constants/locale'

import type { Translator } from 'helpers'
import type { Element } from 'react'
import type { FieldRenderProps } from 'react-final-form'
import type { LocalCustomerDto, LocalCustomerOption } from 'types/customer'
import type { Try } from 'types/Try'
import type { Pageable } from 'types/Pageable'
import type { ServicePoint } from 'types/Location'
import type { ServicePointOption } from 'types/components/AutoSuggestServicePoint'

export type AutoSuggestHubOrCustomerProps = {|
	className?: string,
	classNamePrefix?: string,
	formControlClassName: string,
|}

const MIN_LENGTH: number = 3
const WAIT_TIME_MS: number = 500
const NOTIFICATION_SHOW_TIME_MS: number = 10000

function AutoSuggestHubOrLocalCustomerView({
	className,
	classNamePrefix,
	formControlClassName,
	input,
	meta: { touched, submitFailed, error },
}: AutoSuggestHubOrCustomerProps & FieldRenderProps): Element<*> {
	const { t }: { t: Translator } = useTranslation(LOCALE_NAMESPACE_COMMON, { keyPrefix: LOCALE_AUTO_SUGGEST_HUB_OR_CUSTOMER })

	const handleOptionsLoading = (searchValue: string, setOptionsCallback: Function): void => debounceSuggestHubsAndCustomers(searchValue, setOptionsCallback)

	const debounceSuggestHubsAndCustomers: (searchValue: string, setOptionsCallback: Function) => void = debounce((searchValue, setOptionsCallback) => {
		suggestHubsAndCustomers(searchValue).then(options => {
			setOptionsCallback(options)
		})
	}, WAIT_TIME_MS)

	const suggestHubsAndCustomers = async (searchValue: string): Promise<Array<ServicePointOption | LocalCustomerOption>> => {
		if (searchValue && searchValue.length >= MIN_LENGTH) {
			const hubs: Array<ServicePointOption> = await suggestPostOffices(searchValue)
			const customers: Array<LocalCustomerOption> = await suggestCustomers(searchValue)
			return hubs.concat(customers)
		} else {
			return []
		}
	}

	const suggestPostOffices = async (searchValue: string): Promise<Array<ServicePointOption>> => {
		const response: Try<Pageable<ServicePoint>> = await ServicePointRestService.searchPostOfficesByName(searchValue)
		if (isSuccess(response)) {
			return mapServicePointsToOptions(getValue(response).content)
		} else {
			NotificationManager.error(getError(response).message, t('search_post_office.fail'), NOTIFICATION_SHOW_TIME_MS)
			return []
		}
	}

	const mapServicePointsToOptions = (servicePoints: Array<ServicePoint>): Array<ServicePointOption> => servicePoints
		.map((servicePoint: ServicePoint): ServicePointOption => ({ servicePoint, label: servicePoint.name || '' }))
		.filter((option: ServicePointOption): boolean => Boolean(option.label))

	const suggestCustomers = async (searchValue: string): Promise<Array<LocalCustomerOption>> => {
		const response: Try<Pageable<LocalCustomerDto>> = await CustomerRestService.searchLocal(searchValue)
		if (isSuccess(response)) {
			return mapCustomersToOptions(getValue(response).content)
		} else {
			NotificationManager.error(getError(response).message, t('search_customer.fail'), NOTIFICATION_SHOW_TIME_MS)
			return []
		}
	}

	const mapCustomersToOptions = (customers: Array<LocalCustomerDto>): Array<LocalCustomerOption> => customers
		.map((customer: LocalCustomerDto): LocalCustomerOption => ({ customer, label: createLabel(customer) }))
		.filter((option: LocalCustomerOption): boolean => Boolean(option.label))

	const createLabel = (customer: LocalCustomerDto) => {
		if (customer.name && customer.code) {
			return `${customer.name} (${customer.code})`
		}
		return ''
	}

	return (
		<FormControl className={formControlClassName} error={error && (touched || submitFailed)}>
			<AsyncSelect
				className={className}
				classNamePrefix={classNamePrefix}
				loadOptions={handleOptionsLoading}
				onChange={input.onChange}
				placeholder={t('placeholder')}
				noOptionsMessage={() => t('no_options')}
				loadingMessage={() => t('loading')}
				onBlur={input.onBlur}
				onFocus={input.onFocus}
				isClearable
				defaultOptions
			/>
			{(error && (touched || submitFailed)) ? (
				<FormHelperText>{(touched || submitFailed) && Object.values(error)}</FormHelperText>
			) : null}
		</FormControl>
	)
}

export default AutoSuggestHubOrLocalCustomerView