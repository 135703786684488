// @flow
export const ROWS_PER_PAGE_OPTIONS: Array<number> = Object.freeze([10, 20, 50])

export const COLUMN: * = Object.freeze({
	REGISTRANT_SUBSCRIPTION_CODE: 'registrantSubscriptionCode',
	PRODUCT_NAME_AND_CODE: 'productNameAndCode',
	RECIPIENT: 'recipient',
	DESTINATION: 'destination',
	DELIVERY_PERIODS: 'deliveryPeriodsDetails',
	REGISTRANT: 'registrant',
	QUANTITY: 'quantity',
	STATUS: 'status',
})

export const PERIOD_UNIT: * = Object.freeze({
	DAY: 'DAY',
	MONTH: 'MONTH',
	YEAR: 'YEAR',
	NOT_DETERMINED_END: 'NOT_DETERMINED_END',
})

export const DELIVERY_COLUMN: * = Object.freeze({
	DELIVERY_PERIOD: 'deliveryPeriod',
	DELIVERY_DESTINATION: 'deliveryDestination',
	DELIVERY_RATIONALE: 'deliveryRationale',
})

export const HISTORY_COLUMN: * = Object.freeze({
	DATE: 'date',
	EVENT: 'event',
	APPLICATION_PERIOD: 'applicationPeriod',
	RECIPIENT: 'recipient',
	EVENT_PERFORMER: 'eventPerformer',
})

export const DELIVERY_RATIONALE: * = Object.freeze({
	NEW_SUBSCRIPTION: 'NEW_SUBSCRIPTION',
	REDIRECT: 'REDIRECT',
	SUSPEND: 'SUSPEND',
	CANCEL: 'CANCEL',
	DELIVERY_CHANGE: 'DELIVERY_CHANGE',
	ROUTE_CHANGE: 'ROUTE_CHANGE',
})

export const EVENT_TYPE: * = Object.freeze({
	NEW_SUBSCRIPTION: 'NEW_SUBSCRIPTION',
	SUSPEND: 'SUSPEND',
	CANCEL: 'CANCEL',
	REDIRECT: 'REDIRECT',
	EDIT: 'EDIT',
	DELIVERY_CHANGE: 'DELIVERY_CHANGE',
	ROUTE_CHANGE: 'ROUTE_CHANGE',
})

export const STATUS: * = Object.freeze({
	ALL: 'ALL',
	READY_FOR_DELIVERY: 'READY_FOR_DELIVERY',
	NEEDS_ATTENTION: 'NEEDS_ATTENTION',
})

export const REGISTRANT: * = Object.freeze({
	ALL: 'ALL',
})
