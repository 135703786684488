import { createTheme } from '@mui/material/styles'

const orangeBase = '#f04e23'

export const omnivaOrangeTheme = createTheme({
	typography: {
		fontFamily: 'TT Wellingtons, sans-serif',
	},
	palette: {
		primary: {
			main: orangeBase,
		},
	},
})
