// @flow
import { Component } from 'react'
import classNames from 'classnames'

import imgLoading from 'assets/images/loading.svg'

import './Loading.scss'

export type LoadingProps = {
	className?: ?string,
}

class Loading extends Component<LoadingProps, void> {
	render() {
		const { className, ...restProps } = this.props

		return (
			<img {...restProps} className={classNames('loading', className)} src={imgLoading} alt="loading" />
		)
	}
}

export default Loading
