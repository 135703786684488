// @flow
import { connect } from 'react-redux'
import * as Selector from 'shared/subscription/subscriptionListSelectors'

import type { Store, ThunkAction } from 'types/Store'
import type { SubscriptionFilters, RowsPerPageChange, SubscriptionSort } from 'types/subscription/Subscription'
import type { SubscriptionListViewProps } from './SubscriptionListView'

import { updateFilters, updatePage, updateRowsPerPage, updateSort } from './SubscriptionListActions'
import SubscriptionListView from './SubscriptionListView'

type OwnProps = {||}

type StateProps = {|
	page: number,
	rowsPerPage: number,
	filters: SubscriptionFilters,
	sort: ?SubscriptionSort,
|}

type DispatchProps = {|
	updatePage: (page: number) => ThunkAction,
	updateRowsPerPage: (change: RowsPerPageChange) => ThunkAction,
	updateFilters: (filters: SubscriptionFilters) => ThunkAction,
	updateSort: (sort: SubscriptionSort) => ThunkAction,
|}

const mapStateToProps = (state: Store): StateProps => ({
	page: Selector.getPage(state),
	rowsPerPage: Selector.getRowsPerPage(state),
	filters: Selector.getFilters(state),
	sort: Selector.getSort(state),
})

const mapDispatchToProps: DispatchProps = {
	updatePage,
	updateRowsPerPage,
	updateFilters,
	updateSort,
}

export default connect<SubscriptionListViewProps, OwnProps, StateProps, DispatchProps, _, _>(
	mapStateToProps,
	mapDispatchToProps
)(SubscriptionListView)
