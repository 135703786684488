// @flow
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import currentUser from 'shared/current-user/currentUserReducer'
import subscriptionList from 'shared/subscription/subscriptionListReducer'
import deliveryList from 'shared/delivery/deliveryListReducer'
import eventList from 'shared/event/eventListReducer'

import type { BrowserHistory } from 'history'

function createRootReducer(history: BrowserHistory) {
	return combineReducers<*, *>({
		router: connectRouter(history),
		currentUser,
		subscriptionList,
		deliveryList,
		eventList,
	})
}

export default createRootReducer