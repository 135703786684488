// @flow
import { handleActions } from 'redux-actions'

import type { UserInfo } from 'types/auth'
import type { Action, CurrentUserStore } from 'types/Store'

import { updateCurrentUser } from './currentUserActions'

export const initialState: CurrentUserStore = {
	userInfo: null,
}

export default handleActions<CurrentUserStore, Action>({
	[updateCurrentUser.toString()](state, { payload }: { payload: UserInfo }) {
		return {
			...state,
			userInfo: payload,
		}
	},
}, initialState)
