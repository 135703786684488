// @flow
import { stringify } from 'query-string'
import Sort from 'constants/sort'

import type { Pageable } from 'types/Pageable'

import SortRequest from './SortRequest'

type PossibleSort = string | SortRequest | Array<SortRequest | string> | Array<SortRequest> | Array<string>

const QUERY_STRING_OPTIONS = {
	arrayFormat: 'index',
}

const DEFAULT_SIZE: number = 20
const DEFAULT_PAGE: number = 0

function safeParse(json: string) {
	try {
		return JSON.parse(json)
	} catch (e) {
		return null
	}
}

export default class PageRequest {
	page: number
	size: number
	sort: Array<SortRequest>

	constructor(
		page: number = DEFAULT_PAGE,
		size: number = DEFAULT_SIZE,
		sort?: ?PossibleSort,
	) {
		this.size = size
		this.page = page

		this.sort = [].concat(sort)
			.filter(s => !!s)
			.map((s) => {
				if (typeof s === 'string') {
					return SortRequest.fromString(s)
				}

				if (s instanceof SortRequest) {
					return s
				}

				throw new TypeError(`"sort" arg should be a string, a SortRequest or an array of them, but present ${String(sort)}, with invalid element ${String(s)}`); // eslint-disable-line
			})
	}

	toHash(): Object {
		return {
			page: this.page,
			size: this.size,
			sort: this.sort,
		}
	}

	toJSON(): string {
		const hash = {
			page: this.page,
			size: this.size,
			sort: this.sort.map(x => x.toString()),
		}

		return JSON.stringify(hash)
	}

	toString(): string {
		const hash = {
			size: this.size,
			page: this.page,
		}

		const hashAsString = stringify(hash, QUERY_STRING_OPTIONS)

		if (!this.sort.length) {
			return hashAsString
		}

		const sort = this.sort.reduce(
			(acc: string, x: SortRequest) => `${acc}&sort=${x.toString()}`,
			'',
		)

		return hashAsString + sort
	}

	withSort(fieldToAdd: string, direction: string = Sort.ASC): PageRequest {
		const sortRule = new SortRequest(fieldToAdd, direction)

		if (!this.sort.length) {
			return new PageRequest(this.page, this.size, sortRule)
		}

		const sortWithoutField = this.sort.filter(
			(sort: SortRequest) => sort.field !== fieldToAdd,
		)

		const sort = ([...sortWithoutField, sortRule]: Array<SortRequest>)
		return new PageRequest(this.page, this.size, sort)
	}

	withPage(page: number): PageRequest {
		return new PageRequest(page, this.size, this.sort)
	}

	slice<T>(data: Array<T>): Pageable<T> {
		const total: number = data.length
		const sliceFrom: number = Math.min(this.page * this.size, total)
		const sliceTo: number = Math.min(sliceFrom + this.size, total)
		const content: T[] = data.slice(sliceFrom, sliceTo)
		const totalPages: number = Math.ceil(total / this.size)
		return {
			content,
			last: (this.page + 1) === totalPages,
			totalElements: total,
			totalPages,
			numberOfElements: content.length,
			first: this.page === 0,
			size: this.size,
			number: this.page,
			sort: [],
		}
	}

	static asAllPagesRequest(sort: ?PossibleSort) {
		return new PageRequest(0, 0x7FFFFFFF, sort)
	}

	static fromJSONString(json: ?string): ?PageRequest {
		if (!json) {
			return null
		}

		const properties = safeParse(json)

		if (!properties) {
			return null
		}

		const sort = []
			.concat((properties.sort: any))
			.filter(x => !!x)
			.map(x => SortRequest.fromString(x))

		return new PageRequest(
			properties.page,
			properties.size,
			sort.length ? sort : null,
		)
	}
}