// @flow
import MuiDataTable from 'mui-datatables'
import { useTranslation } from 'react-i18next'

import { LOCALE_MUI_DATA_TABLE, LOCALE_NAMESPACE_COMMON } from 'constants/locale'
import { CSV_SEPARATOR, UTF_8_BOM } from 'constants/csv'

import type { Element } from 'react'
import type { Translator } from 'helpers'
import type { Column, HandleDownloadFunction, HandleDownloadData } from 'types/components/Table'

function LocalizedMuiDataTable(props: Object): Element<*> {
	const { t }: { t: Translator } = useTranslation(LOCALE_NAMESPACE_COMMON, { keyPrefix: LOCALE_MUI_DATA_TABLE })

	const translateLabels = (): Object => ({
		body: {
			noMatch: t('no_matching_records_found'),
			toolTip: t('sort_tooltip'),
		},
		pagination: {
			next: t('pagination.next'),
			previous: t('pagination.previous'),
			rowsPerPage: t('pagination.rows_per_page'),
		},
		toolbar: {
			search: t('toolbar.search'),
			downloadCsv: t('toolbar.download_csv'),
			print: t('toolbar.print'),
			viewColumns: t('toolbar.view_columns'),
		},
		viewColumns: {
			title: t('view_columns.title'),
			titleAria: t('view_columns.title_aria'),
		},
		selectedRows: {
			text: t('selected_rows.rows_selected'),
			delete: t('selected_rows.delete'),
			deleteAria: t('selected_rows.delete_aria'),
		},
	})

	/**
	 * See https://github.com/gregnb/mui-datatables/pull/722#issuecomment-526346440
	 */
	const handleDownload: HandleDownloadFunction = (
		buildHead: (Array<Column>) => string,
		buildBody: (Array<Object>) => string,
		columns: Array<Column>,
		data: Array<HandleDownloadData>,
	): string => `${UTF_8_BOM}${buildHead(columns)}${buildBody(data)}`

	return (
		<MuiDataTable
			{...props}
			options={{
				textLabels: translateLabels(),
				onDownload: handleDownload,
				...props.options,
				downloadOptions: {
					separator: CSV_SEPARATOR,
					...(props.options && props.options.downloadOptions),
				},
			}}
		/>
	)
}

export default LocalizedMuiDataTable