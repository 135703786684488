// @flow
import { RestClient } from 'helpers'

import type { Try } from 'types/Try'
import type { UserInfo } from 'types/auth'
import type { CustomerInfo } from 'types/auth/CustomerInfo'

import { API_URL } from 'Api'

export async function getCurrentUserMandates(): Promise<Try<Array<CustomerInfo>>> {
	return RestClient.get(`${API_URL}/users/me/mandates`)
}

export async function getCurrentUser(): Promise<Try<UserInfo>> {
	return RestClient.get(`${API_URL}/users/me`)
}

/**
 * Server will modify the auth-related Cookie as a result.
 */
export async function casRedirect(samlArtToken: string): Promise<Try<void>> {
	return RestClient.get(`${API_URL}/auth/handle-cas-redirection?SAMLart=${encodeURIComponent(samlArtToken)}`)
}

/**
 * Server will modify the auth-related Cookie as a result.
 */
export async function selectUserCustomer(customerId: number): Promise<Try<void>> {
	return RestClient.post(`${API_URL}/users/me/customer`, { customerId })
}

/**
 * Server will modify the auth-related Cookie as a result.
 */
export async function logout(): Promise<Try<void>> {
	return RestClient.get(`${API_URL}/auth/logout`)
}