// @flow
import { handleActions } from 'redux-actions'

import { ROWS_PER_PAGE_OPTIONS, DELIVERY_COLUMN } from 'constants/subscription/Subscription'

import type { Action, DeliveryListStore } from 'types/Store'
import type { DeliverySort, RowsPerPageChange } from 'types/subscription/Subscription'

import { updatePage, updateRowsPerPage, updateSort } from './deliveryListActions'

const initialState: DeliveryListStore = {
	page: 1,
	rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
	sort: {
		name: DELIVERY_COLUMN.DELIVERY_PERIOD,
		direction: 'desc',
	},
}

export default handleActions<DeliveryListStore, Action>({
	[updatePage.toString()](state, { payload }: { payload: number }) {
		return {
			...state,
			page: payload,
		}
	},
	[updateRowsPerPage.toString()](state, { payload }: { payload: RowsPerPageChange }) {
		return {
			...state,
			rowsPerPage: payload.rowsPerPage,
			page: payload.page,
		}
	},
	[updateSort.toString()](state, { payload }: { payload: DeliverySort }) {
		return {
			...state,
			sort: payload,
		}
	},
}, initialState)