// @flow
import { isEmpty, toString } from 'lodash'

export const parsePostalCode = (destination: any, servicePointPostalCode: ?number): ?number => {
	const fullAddressPostalCode: ?number = destination.zip ?? destination.address?.postcode

	if (!isEmpty(toString(servicePointPostalCode))) {
		return servicePointPostalCode
	}
	if (!isEmpty(toString(fullAddressPostalCode))) {
		return fullAddressPostalCode
	}
	return null
}
