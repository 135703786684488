// @flow
import { RestClient } from 'helpers'

import { API_URL } from 'Api'

import type { Try } from 'types/Try'
import type { GetLatestActiveSubscriptionDeliveryResponse } from 'types/subscription/Subscription'

const SUBSCRIPTION_DELIVERIES_URL: string = `${API_URL}/subscription-deliveries`

export async function getLatestActiveSubscriptionDelivery(subscriptionId: number): Promise<Try<GetLatestActiveSubscriptionDeliveryResponse>> {
	return RestClient.get(`${SUBSCRIPTION_DELIVERIES_URL}/subscriptions/${subscriptionId}`)
}