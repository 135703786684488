// @flow
import type { FeatureToggles, FeatureToggleEnum } from 'types/FeatureToggle'

export const API_URL = '/webapp-api/v1'

class Api {
	static defaultPathDescription: string = ''
	static language: ?string = null
	static featureToggles: FeatureToggles = {}

	static setDefaultPathDescription(pathDescription: string): void {
		Api.defaultPathDescription = pathDescription
	}

	static getDefaultPathDescription(): string {
		return Api.defaultPathDescription
	}

	static setFeatureToggles(featureToggles: FeatureToggles): void {
		Api.featureToggles = featureToggles
	}

	static isFeatureToggleEnabled(featureToggle: FeatureToggleEnum): boolean {
		return Api.featureToggles[featureToggle]
	}

	static setLanguage(language: ?string): void {
		Api.language = language
	}

	static getLanguage(): ?string {
		return Api.language
	}
}

export default Api
