// @flow
import { get } from 'lodash'
import moment from 'moment'

import { isDate } from 'helpers/form-validation/Validators'
import { addError, t, Validators } from 'helpers'

import { DEFAULT_DATE_FORMAT } from 'constants/datetime'
import * as formFields from 'constants/subscription/form/SubscriptionSuspendForm'

import type { SubscriptionSuspendFormValues } from 'types/subscription/form/SubscriptionSuspendForm'

const REQUIRED_FIELDS: Set<string> = new Set([formFields.SUSPENDED_FROM, formFields.SUSPENDED_TO])

function validate(values: SubscriptionSuspendFormValues, validStartDate: moment): Object {
	let errors: Object = {}

	for (const requiredField of REQUIRED_FIELDS) {
		errors = addError(errors, requiredField, Validators.required(get(values, requiredField, null)))
	}

	errors = addError(
		errors,
		formFields.SUSPENDED_TO,
		Validators.isDateAfter(values[formFields.SUSPENDED_TO], values[formFields.SUSPENDED_FROM])
	)

	errors = addError(
		errors,
		formFields.SUSPENDED_FROM,
		isEnoughWorkdaysBeforeDate(validStartDate, values[formFields.SUSPENDED_FROM])
	)

	errors = addError(
		errors,
		formFields.SUSPENDED_FROM,
		Validators.isDateAfter(values[formFields.SUSPENDED_FROM], values.subscriptionStartDate)
	)

	errors = addError(
		errors,
		formFields.SUSPENDED_FROM,
		Validators.isDateAfter(values[formFields.SUSPENDED_FROM], values.delivery?.appliedFrom)
	)

	errors = addError(
		errors,
		formFields.SUSPENDED_FROM,
		Validators.isDateBeforeOrSame(values[formFields.SUSPENDED_FROM], values.delivery?.appliedTo)
	)

	return errors
}

const isEnoughWorkdaysBeforeDate = (
	validStartDate: moment,
	value: any,
	originalDate: moment = moment(),
	messageKey: string = 'validation.input.date_weekday_before'
) => {
	if (value == null || !!isDate(value)) {
		return undefined
	}

	const day = moment(value).startOf('day')
	const firstAvailableDay = moment(validStartDate).startOf('day')
	return day.isBefore(firstAvailableDay)
		? t(messageKey, { allowedDate: moment(firstAvailableDay).format(DEFAULT_DATE_FORMAT) })
		: undefined
}

export default validate
