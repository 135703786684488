// @flow
import axios from 'axios'

import { clearCurrentUser } from 'shared/current-user'

import type { Dispatch } from 'redux'

import { isUnauthorizedStatus } from './RestError'

const DO_NOT_INTERCEPT_SUCCESS: null = null

export const setupAutoLogout = (dispatch: Dispatch<any>): void => {
	axios.interceptors.response.use(DO_NOT_INTERCEPT_SUCCESS, (error: any): any => {
		if (isUnauthorizedStatus(error)) {
			dispatch(clearCurrentUser())
		}
		return Promise.reject(error)
	})
}