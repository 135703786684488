// @flow
import EastSharpIcon from '@mui/icons-material/EastSharp'
import WestSharpIcon from '@mui/icons-material/WestSharp'
import { FormControl, Grid, Pagination, PaginationItem, TableFooter, TableRow } from '@mui/material'

import PageSelect from './PageSelect'
import GridPagesDetails from './GridPagesDetails'

import './CustomFooter.scss'

export type CustomFooterProps = {|
	changeRowsPerPage: (number) => void,
	changePage: (number) => void,
	rowsPerPage: number,
	page: number,
	totalElements: number,
	rowsPerPageOptions: Array<number>,
|}

const getPaginationItem = (item) => (
	<PaginationItem
		className="pagination-item"
		{...item}
		slots={{
			previous: WestSharpIcon,
			next: EastSharpIcon,
		}}
		sx={{
			'&.Mui-selected': {
				backgroundColor: 'white',
			},
		}}
	/>
)

const CustomFooter = ({
	rowsPerPage,
	page,
	totalElements,
	rowsPerPageOptions,
	changeRowsPerPage,
	changePage,
}: CustomFooterProps) => {
	const handlePageChange = (_, newPage: number): void => {
		changePage(newPage)
	}

	const handleRowChange = (newRowsPerPage: number): void => {
		changeRowsPerPage(newRowsPerPage)
	}

	const sx = { m: 2, minWidth: 100 }

	return (
		<TableFooter className="table-footer">
			<TableRow>
				<Grid container justify="flex-start" alignItems="center">
					<Grid item xs>
						<FormControl className="grid-page-details" sx={sx}>
							<GridPagesDetails page={page} rowsPerPage={rowsPerPage} totalElements={totalElements} />
						</FormControl>
					</Grid>
					<Grid item>
						<FormControl className="custom-pagination" sx={sx}>
							<Pagination
								renderItem={getPaginationItem}
								onChange={handlePageChange}
								page={page}
								count={Math.ceil(totalElements / rowsPerPage)}
								shape="rounded"
							/>
						</FormControl>
					</Grid>
					<Grid item xs>
						<FormControl variant="filled" className="page-select" sx={sx}>
							<PageSelect
								onChange={handleRowChange}
								rowsPerPageOptions={rowsPerPageOptions}
								rowsPerPage={rowsPerPage}
							/>
						</FormControl>
					</Grid>
				</Grid>
			</TableRow>
		</TableFooter>
	)
}

export default CustomFooter
