// @flow
import { NotificationManager } from 'react-notifications'
import { get } from 'lodash'

import * as formFields from 'constants/subscription/form/SubscriptionForm'
import { SubscriptionRestService } from 'services'
import { getError, getValue, isSuccess } from 'types/Try'

import { LOCALE_SUBSCRIPTIONS, LOCALE_NAMESPACE_SUBSCRIPTIONS } from 'constants/locale'
import { STATUS } from 'constants/subscription/Subscription'

import type { Try } from 'types/Try'
import type { FormApi } from 'final-form'
import type { SubscriptionFormValues } from 'types/subscription/form/SubscriptionForm'
import type { CustomerSearchDto } from 'types/customer'
import type { AddressDto } from 'types/address'
import type {
	ClientContact,
	Destination,
	SubscriptionIdResponse,
	SubscriptionSaveRequest,
	Client, SubscriptionFilters,
} from 'types/subscription/Subscription'

import { browserHistory } from 'browserHistory'
import { getSubscriptionsCreateUrl, getSubscriptionsDetailUrl } from 'Paths'
import { t } from 'helpers'
import { parsePostalCode } from 'helpers/converter/subscriptionPostalCodeExtractor'

const NOTIFICATION_SHOW_TIME_MS: number = 10000
const POST_OFFICE: string = 'POST_OFFICE'
const POST_STATION: string = 'POST_STATION'
const PERSONAL_BOX: string = 'PERSONAL_BOX'

const isOnDemand = (typeCode: ?string): boolean => (typeCode === POST_OFFICE || typeCode === POST_STATION)

export function onSubmit(formValues: SubscriptionFormValues): Promise<SubscriptionIdResponse> {
	return saveSubscription(formValues)
}

export function handleSubscriberChange(form: FormApi<SubscriptionFormValues>, formValues: SubscriptionFormValues): Function {
	form.change(formFields.SUBSCRIBER_NAME, formValues.name)
	form.change(formFields.SUBSCRIBER_SURNAME, formValues.surname)
	form.change(formFields.SUBSCRIBER_COMPANY_NAME, formValues.companyName)
}

export function clearSubscriber(form: FormApi<SubscriptionFormValues>) {
	form.change(formFields.SUBSCRIBER_NAME, undefined)
	form.change(formFields.SUBSCRIBER_SURNAME, undefined)
	form.change(formFields.SUBSCRIBER_COMPANY_NAME, undefined)
}

export function handleCustomerChange(form: FormApi<SubscriptionFormValues>): Function {
	return async (customer: ?CustomerSearchDto): Promise<void> => {
		if (customer) {
			form.change(formFields.CMS_ID, customer.id)
			form.change(formFields.CUSTOMER_NAME, customer.name)
			form.change(formFields.CUSTOMER_CODE, customer.code)
			form.change(formFields.CUSTOMER_ADDRESS, customer.primaryAddress)
			form.change(formFields.CUSTOMER_AX_CODE, customer.axaptaId)
		}
	}
}

export function handleAddressChange(form: FormApi<SubscriptionFormValues>): Function {
	return async (address: AddressDto): Promise<void> => {
		form.change(formFields.DESTINATION, address)
	}
}

export function handleAddressTextChange(form: FormApi<SubscriptionFilters>, address?: string): Function {
	form.change(formFields.DESTINATION_TEXT, address)
}

export function handleSubscriptionEndDateChange(form: FormApi<SubscriptionFormValues>, endDate?: string): Function {
	form.change(formFields.SUBSCRIPTION_END_DATE, endDate)
}

async function saveSubscription(formValues: SubscriptionFormValues): Promise<SubscriptionIdResponse> {
	const isAddAndNew: boolean = get(formValues, formFields.IS_ADD_AND_NEW)
	const recipient: Client = {
		name: get(formValues, formFields.NAME),
		surname: get(formValues, formFields.SURNAME),
		companyName: get(formValues, formFields.COMPANY_NAME),
	}
	const subscriber: Client = {
		name: get(formValues, formFields.SUBSCRIBER_NAME),
		surname: get(formValues, formFields.SUBSCRIBER_SURNAME),
		companyName: get(formValues, formFields.SUBSCRIBER_COMPANY_NAME),
		registryCode: get(formValues, formFields.REGISTER_CODE),
		contact: parseClientContactList(formValues),
	}
	const request: SubscriptionSaveRequest = {
		id: formValues.id || undefined,
		subscriptionSubscriber: subscriber,
		subscriptionRecipient: recipient,
		destination: formValues.id ? updateDestination(formValues) : parseDestination(formValues),
		dataSource: 'EXT_UI',
		registrant: formValues.registrant !== undefined ? formValues.registrant : null,
		startDate: get(formValues, formFields.SUBSCRIPTION_START_DATE),
		endDate: get(formValues, formFields.SUBSCRIPTION_END_DATE),
		subscriptionCode: get(formValues, formFields.SUBSCRIPTION_CODE),
		product: {
			id: get(formValues, formFields.PUBLICATION),
		},
		status: STATUS.READY_FOR_DELIVERY,
		quantity: get(formValues, formFields.QUANTITY),
		receiptNumber: get(formValues, formFields.RECEIPT_NUMBER),
		subscriptionCost: get(formValues, formFields.SUBSCRIPTION_COST),
	}

	if (request.id) {
		const response: Try<SubscriptionIdResponse> = await SubscriptionRestService.updateSubscription(request)
		if (isSuccess(response)) {
			// in order to reload same page without the need to refresh
			browserHistory.replace('/empty')
			setTimeout(() => {
				browserHistory.replace(getSubscriptionsDetailUrl(response.id))
			}, 10)
			NotificationManager.success(t('update.success', null, LOCALE_SUBSCRIPTIONS, LOCALE_NAMESPACE_SUBSCRIPTIONS), null, NOTIFICATION_SHOW_TIME_MS)
			return getValue(response)
		} else {
			NotificationManager.error(
				t(
					'form.update_error_message',
					null,
					LOCALE_SUBSCRIPTIONS,
					LOCALE_NAMESPACE_SUBSCRIPTIONS,
				), null, NOTIFICATION_SHOW_TIME_MS,
			)
			throw getError(response)
		}
	} else {
		const response: Try<SubscriptionIdResponse> = await SubscriptionRestService.saveSubscription(request)
		if (isSuccess(response)) {
			if (isAddAndNew) {
				// in order to reload same page without the need to refresh
				browserHistory.replace('/empty')
				setTimeout(() => {
					browserHistory.push(getSubscriptionsCreateUrl())
				}, 10)
			} else {
				// in order to reload same page without the need to refresh
				browserHistory.replace('/empty')
				setTimeout(() => {
					browserHistory.replace(getSubscriptionsDetailUrl(response.id))
				}, 10)
			}
			NotificationManager.success(t('create.success', null, LOCALE_SUBSCRIPTIONS, LOCALE_NAMESPACE_SUBSCRIPTIONS), null, NOTIFICATION_SHOW_TIME_MS)
			return getValue(response)
		} else {
			NotificationManager.error(
				t(
					'form.error_message',
					null,
					LOCALE_SUBSCRIPTIONS,
					LOCALE_NAMESPACE_SUBSCRIPTIONS,
				), null, NOTIFICATION_SHOW_TIME_MS,
			)
			throw getError(response)
		}
	}
}

function parseDestination(formValues: SubscriptionFormValues): Destination {
	const destination: any = get(formValues, formFields.DESTINATION)
	const address: any = destination.address
	let nameboxNumber = null
	if (destination.typeCode === PERSONAL_BOX) {
		const r = /\d+/g
		const numberString = destination.label.match(r)
		if (numberString.length > 0) {
			nameboxNumber = Number(numberString[0])
		}
	}
	return {
		rmaId: address.rmaId,
		county: address.county,
		countyCode: address.countyCode,
		municipality: address.municipality,
		municipalityCode: address.municipalityCode,
		settlement: address.settlement,
		settlementCode: address.settlementCode,
		street: address.street,
		streetCode: address.streetCode,
		houseNumber: address.houseNumber ? address.houseNumber : get(formValues, formFields.HOUSE_NUMBER),
		apartmentNumber: address.apartmentNumber ? address.apartmentNumber : get(formValues, formFields.APARTMENT),
		zip: parsePostalCode(destination, get(formValues, formFields.ZIP)),
		psnId: destination.id,
		fullAddress: !destination.typeCode ? destination.label : null,
		hubName: destination.typeCode ? destination.label : null,
		onDemand: isOnDemand(destination.typeCode),
		nameboxNr: nameboxNumber,
		isFullRma: !address.partial,
		placeName: address.placeName ? address.placeName : get(formValues, formFields.PLACE_NAME),
		smallPlace: address.smallPlace,
	}
}

function updateDestination(formValues: SubscriptionFormValues): Destination {
	const destination: any = get(formValues, formFields.DESTINATION)
	const address: any = destination.address
	let nameboxNumber = null
	if (destination.typeCode === PERSONAL_BOX) {
		const r = /\d+/g
		const numberString = destination.label.match(r)
		if (numberString && numberString.length > 0) {
			nameboxNumber = Number(numberString[0])
		}
	}
	if (destination.address) {
		return {
			rmaId: destination.address.rmaId,
			county: destination.address.county,
			countyCode: destination.address.countyCode,
			municipality: destination.address.municipality,
			municipalityCode: destination.address.municipalityCode,
			settlement: destination.address.settlement,
			settlementCode: destination.address.settlementCode,
			street: destination.address.street,
			streetCode: destination.address.streetCode,
			houseNumber: destination.address.houseNumber ? destination.address.houseNumber : get(formValues, formFields.HOUSE_NUMBER),
			apartmentNumber: destination.address.apartmentNumber ? destination.address.apartmentNumber : get(formValues, formFields.APARTMENT),
			zip: parsePostalCode(destination, get(formValues, formFields.ZIP)),
			psnId: destination.id,
			fullAddress: !destination.typeCode ? destination.label : null,
			hubName: destination.typeCode ? destination.label : null,
			onDemand: isOnDemand(destination.typeCode),
			nameboxNr: nameboxNumber,
			isFullRma: !address.partial,
			placeName: get(formValues, formFields.PLACE_NAME),
		}
	} else {
		return {
			rmaId: destination.rmaId,
			county: destination.county,
			countyCode: destination.countyCode,
			municipality: destination.municipality,
			municipalityCode: destination.municipalityCode,
			settlement: destination.settlement,
			settlementCode: destination.settlementCode,
			street: destination.street,
			streetCode: destination.streetCode,
			houseNumber: destination.houseNumber ? destination.houseNumber : get(formValues, formFields.HOUSE_NUMBER),
			apartmentNumber: destination.apartmentNumber ? destination.apartmentNumber : get(formValues, formFields.APARTMENT),
			zip: parsePostalCode(destination, get(formValues, formFields.ZIP)),
			psnId: destination.id,
			fullAddress: destination.fullAddress,
			hubName: destination.typeCode ? destination.label : null,
			onDemand: isOnDemand(destination.typeCode),
			nameboxNr: nameboxNumber,
			isFullRma: !destination.partial,
			placeName: get(formValues, formFields.PLACE_NAME),
		}
	}
}

function parseClientContactList(formValues: SubscriptionFormValues): Array<ClientContact> {
	const email: string = get(formValues, formFields.SUBSCRIBER_EMAIL)
	const phone: string = get(formValues, formFields.SUBSCRIBER_PHONE)

	const contactList: ClientContact[] = []
	if (email) {
		contactList.push({
			type: 'EMAIL',
			value: email,
		})
	}
	if (phone) {
		contactList.push({
			type: 'PHONE',
			value: phone,
		})
	}
	return contactList
}
