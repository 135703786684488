// @flow
import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import promiseMiddleware from 'redux-loading-promise-middleware'

import { browserHistory } from 'browserHistory'

import type { Store } from 'types/Store'
import type { Store as ReduxStore } from 'redux'

import createRootReducer from './reducers'

export type StoreOptions = {
	logStore: boolean,
}

export default function configureStore(storeOptions: StoreOptions, initialState: any = {}): ReduxStore<*, *> {
	const loggerMiddleware = createLogger()

	const storeEnhancers: Function[] = []

	storeEnhancers.push(applyMiddleware(thunk))
	storeEnhancers.push(applyMiddleware(promiseMiddleware))
	// eslint-disable-next-line no-restricted-globals
	storeEnhancers.push(applyMiddleware(routerMiddleware(browserHistory)))

	// eslint-disable-next-line no-underscore-dangle
	if (process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION__) {
		// eslint-disable-next-line no-underscore-dangle
		storeEnhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__())
	}

	if (storeOptions.logStore) {
		storeEnhancers.push(applyMiddleware(loggerMiddleware))
	}

	return createStore<Store, *, *>(
		createRootReducer(browserHistory),
		initialState,
		compose(...storeEnhancers),
	)
}
