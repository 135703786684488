// @flow
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { OnChange } from 'react-final-form-listeners'

import TitleLine from 'components/Form/TitleLine'
import { renderTextField, renderTextArea } from 'components/Form/redux-material-ui/FormFieldBridges'
import { AutoSuggestAddress } from 'components'

import * as formFields from 'constants/subscription/form/SubscriptionForm'
import { LOCALE_NAMESPACE_SUBSCRIPTIONS, LOCALE_SUBSCRIPTIONS } from 'constants/locale'

import type { Translator } from 'helpers'
import type { FormApi } from 'final-form'
import type { AddressOption } from 'types/address'
import type { SubscriptionFormValues } from 'types/subscription/form/SubscriptionForm'
import type { SubscriptionFieldsCheckResponse } from 'types/subscription/Subscription'

import { handleAddressChange } from '../../SubscriptionCreateActions'

export type RecipientDataProps = {|
	form: FormApi<SubscriptionFormValues>,
	isNewSubscription: boolean,
	isNeedsAttention: boolean,
	checkFieldsResult?: ?SubscriptionFieldsCheckResponse,
|}

function RecipientDataSectionView({
	form,
	isNewSubscription,
	isNeedsAttention,
	checkFieldsResult,
}: RecipientDataProps) {
	const { t }: { t: Translator } = useTranslation(LOCALE_NAMESPACE_SUBSCRIPTIONS, { keyPrefix: LOCALE_SUBSCRIPTIONS })
	return (
		<div>
			<TitleLine title={t('form.recipient')} />
			<div className="py-3">
				<label className="form-label">{t('form.name')}</label>
				<Field
					disabled={!isNewSubscription}
					className="field"
					name={formFields.NAME}
					component={renderTextField}
				/>
			</div>
			<div className="py-3">
				<label className="form-label">{t('form.surname')}</label>
				<Field
					disabled={!isNewSubscription}
					className="field"
					name={formFields.SURNAME}
					component={renderTextField}
				/>
			</div>
			<div className="py-3">
				<label className="form-label">{t('form.company_name')}</label>
				<Field
					disabled={!isNewSubscription}
					className="field"
					name={formFields.COMPANY_NAME}
					component={renderTextField}
				/>
			</div>
			<div className="py-3 complex-flex-row">
				<div>
					<label className="form-label">{t('form.destination')}</label>
					<span className="required-label">*</span>
				</div>
				{!isNewSubscription && isNeedsAttention && !checkFieldsResult?.destinationOk && (
					<div className="address-box">
						<div className="red-text">{checkFieldsResult?.destinationString}</div>
						<Field
							disabled={!isNewSubscription}
							formControlClassName="form-control"
							className="field"
							name={formFields.DESTINATION}
							component={AutoSuggestAddress}
						/>
						<OnChange name={formFields.DESTINATION}>
							{(addressOption: AddressOption): void => {
								if (addressOption) {
									handleAddressChange(form)
								}
							}}
						</OnChange>
					</div>
				)}
				{isNewSubscription && (
					<div className="address-box">
						<Field
							disabled={!isNewSubscription}
							formControlClassName="form-control"
							className="field"
							name={formFields.DESTINATION}
							component={AutoSuggestAddress}
						/>
						<OnChange name={formFields.DESTINATION}>
							{(addressOption: AddressOption): void => {
								if (addressOption) {
									handleAddressChange(form)
								}
							}}
						</OnChange>
					</div>
				)}
				{!isNewSubscription && (!isNeedsAttention || checkFieldsResult?.destinationOk) && (
					<div className="address-box">
						<Field
							disabled={!isNewSubscription}
							formControlClassName="form-control"
							className="field"
							name={formFields.DESTINATION_TEXT}
							component={renderTextArea}
						/>
					</div>
				)}
			</div>
			<div className="py-3">
				<label className="form-label">{t('form.place_name')}</label>
				<Field
					disabled={!isNewSubscription}
					className="field"
					name={formFields.PLACE_NAME}
					component={renderTextField}
				/>
			</div>
			<div className="py-3">
				<label className="form-label">{t('form.house_number')}</label>
				<Field
					disabled={!isNewSubscription}
					className="field"
					name={formFields.HOUSE_NUMBER}
					component={renderTextField}
				/>
			</div>
			<div className="py-3">
				<label className="form-label">{t('form.apartment')}</label>
				<Field
					disabled={!isNewSubscription}
					className="field"
					name={formFields.APARTMENT}
					component={renderTextField}
				/>
			</div>
			<div className="py-3">
				<label className="form-label">{t('form.zip')}</label>
				<Field
					disabled={!isNewSubscription}
					className="field"
					name={formFields.ZIP}
					component={renderTextField}
				/>
			</div>
		</div>
	)
}

export default RecipientDataSectionView
