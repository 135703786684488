// @flow
import { get } from 'lodash'

import { addError, Validators } from 'helpers'

import * as formFields from 'constants/subscription/form/SubscriptionCancelForm'
import { OMNIVA_CUSTOMER } from 'constants/customer'

import type { SubscriptionCancelFormValues } from 'types/subscription/form/SubscriptionCancelForm'

const REQUIRED_FIELDS: Set<string> = new Set([formFields.CANCELLED_FROM])

function validate(values: SubscriptionCancelFormValues): Object {
	let errors: Object = {}

	for (const requiredField of REQUIRED_FIELDS) {
		errors = addError(errors, requiredField, Validators.required(get(values, requiredField, null)))
	}

	if (
		values?.applicationRegistrant?.customer &&
		values.applicationRegistrant.customer?.id === OMNIVA_CUSTOMER.cmsId
	) {
		errors = addError(
			errors,
			formFields.RETURN_PAYMENT,
			Validators.required(get(values, formFields.RETURN_PAYMENT, null))
		)
	}

	errors = addError(
		errors,
		formFields.CANCELLED_FROM,
		Validators.isDateTodayOrAfter(values[formFields.CANCELLED_FROM])
	)

	errors = addError(
		errors,
		formFields.CANCELLED_FROM,
		Validators.isDateBeforeOrSame(values[formFields.CANCELLED_FROM], values.deliveryEndDate)
	)

	return errors
}

export default validate
