// @flow
import { get } from 'lodash'
import { NotificationManager } from 'react-notifications'
import { browserHistory } from 'browserHistory'
import { getSubscriptionsDetailUrl } from 'Paths'
import { SubscriptionRestService } from 'services'
import { getError, getValue, isSuccess } from 'types/Try'
import * as formFields from 'constants/subscription/form/SubscriptionSuspendForm'
import { t } from 'helpers'
import { LOCALE_NAMESPACE_SUBSCRIPTIONS, LOCALE_SUBSCRIPTIONS } from 'constants/locale'

import type { Try } from 'types/Try'
import type { SubscriptionIdResponse, SubscriptionSuspendRequest } from 'types/subscription/Subscription'
import type { SubscriptionSuspendFormValues } from 'types/subscription/form/SubscriptionSuspendForm'
import type { CustomerInfo } from 'types/auth'

const NOTIFICATION_SHOW_TIME_MS: number = 10000

export const onSubmitWithCustomer = (customer: ?CustomerInfo) => (formValues: SubscriptionSuspendFormValues) =>
	saveSuspend(formValues, customer)

async function saveSuspend(
	formValues: SubscriptionSuspendFormValues,
	customer: ?CustomerInfo
): Promise<SubscriptionIdResponse> {
	const request: SubscriptionSuspendRequest = {
		subscriptionId: formValues.subscriptionId,
		registrantCustomer: {
			cmsId: customer?.customerId,
			name: customer?.name,
			code: customer?.regNumber,
		},
		appliedFrom: get(formValues, formFields.SUSPENDED_FROM),
		appliedTo: get(formValues, formFields.SUSPENDED_TO),
		dataSource: 'EXT_UI',
	}

	const response: Try<SubscriptionIdResponse> = await SubscriptionRestService.suspendSubscription(request)
	if (isSuccess(response)) {
		// in order to reload same page without the need to refresh
		browserHistory.replace('/empty')
		setTimeout(() => {
			browserHistory.replace(getSubscriptionsDetailUrl(response.id))
		}, 10)
		NotificationManager.success(
			t('suspend.success', null, LOCALE_SUBSCRIPTIONS, LOCALE_NAMESPACE_SUBSCRIPTIONS),
			null,
			NOTIFICATION_SHOW_TIME_MS
		)
		return getValue(response)
	} else {
		throw getError(response)
	}
}
