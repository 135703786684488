// @flow
import { ThemeProvider } from '@mui/styles'
import { createTheme } from '@mui/material/styles'

import type { Element } from 'react'

import LocalizedMuiDataTable from './Localized'

function LocalizedWithVisualFixMuiDataTable(props: Object): Element<*> {
	return (
		<ThemeProvider theme={themeWithVisualFix}>
			<LocalizedMuiDataTable {...props} />
		</ThemeProvider>
	)
}

/**
 * API: https://github.com/gregnb/mui-datatables#api
 * Theme nesting: https://mui.com/material-ui/customization/theming/#nesting-the-theme
 */
function themeWithVisualFix(outerTheme: *): * {
	return createTheme({
		...outerTheme,
		overrides: {
			...outerTheme?.overrides,
			/**
			 * Fix visual issue when cell covers other components, like DatePicker.
			 */
			MUIDataTableHeadCell: {
				fixedHeader: {
					zIndex: 0,
				},
			},
		},
	})
}

export default LocalizedWithVisualFixMuiDataTable
