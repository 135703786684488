// @flow
import type { SubscriptionFormValues } from 'types/subscription/form/SubscriptionForm'
import type { Destination, SubscriptionDto, Client } from 'types/subscription/Subscription'

export const mapDtoToFormValues = (subscriptionDto: SubscriptionDto): SubscriptionFormValues => (
	{
		id: subscriptionDto.id,
		name: subscriptionDto?.subscriptionRecipient?.name ?? null,
		surname: subscriptionDto?.subscriptionRecipient?.surname ?? null,
		companyName: subscriptionDto?.subscriptionRecipient?.companyName ?? null,
		subscriberName: subscriptionDto?.subscriptionSubscriber?.name ?? null,
		subscriberSurname: subscriptionDto?.subscriptionSubscriber?.surname ?? null,
		subscriberCompanyName: subscriptionDto?.subscriptionSubscriber?.companyName ?? null,
		subscriberPhone: subscriptionDto.subscriptionSubscriber ? subscriberPhoneFromContacts(subscriptionDto.subscriptionSubscriber) : null,
		subscriberEmail: subscriptionDto.subscriptionSubscriber ? subscriberEmailFromContacts(subscriptionDto.subscriptionSubscriber) : null,
		registerCode: subscriptionDto?.subscriptionSubscriber?.registryCode ?? null,
		destination: subscriptionDto.destination,
		placeName: subscriptionDto?.destination?.placeName ?? null,
		houseNumber: subscriptionDto?.destination?.houseNumber ?? null,
		apartment: subscriptionDto?.destination?.apartmentNumber ?? null,
		zip: subscriptionDto?.destination?.zip ?? null,
		quantity: subscriptionDto.quantity,
		subscriptionCode: subscriptionDto.subscriptionCode,
		publication: subscriptionDto?.product?.productId ?? null,
		subscriptionStartDate: subscriptionDto.startDate,
		subscriptionEndDate: subscriptionDto.endDate,
		registrant: subscriptionDto.registrant,
		registrationDate: subscriptionDto.registeringDate,
		destinationText: subscriptionDto.destination ? destinationTextFromDestination(subscriptionDto.destination) : null,
		registrantSubscriptionCode: subscriptionDto.registrantSubscriptionCode,
		status: subscriptionDto.status,
		receiptNumber: subscriptionDto?.payment?.receiptNumber ?? null,
		subscriptionCost: subscriptionDto?.payment?.grossSum ?? null,
	}
)

function destinationTextFromDestination(destination: Destination): ?string {
	return destination.hubName ? destination.hubName : destination.fullAddress
}

function subscriberPhoneFromContacts(subscriber: Client): any {
	let phone = null
	if (subscriber.contact && subscriber.contact.length > 0) {
		subscriber.contact.forEach(contact => {
			if (contact.type === 'PHONE') {
				phone = contact.value
			}
		})
	}
	return phone
}

function subscriberEmailFromContacts(subscriber: Client): any {
	let email = null
	if (subscriber.contact && subscriber.contact.length > 0) {
		subscriber.contact.forEach(contact => {
			if (contact.type === 'EMAIL') {
				email = contact.value
			}
		})
	}
	return email
}