// @flow
import 'react-notifications/lib/notifications.css'
import { Component } from 'react'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@mui/styles'
import { ConnectedRouter } from 'connected-react-router'
import { I18nextProvider } from 'react-i18next'
import { browserHistory } from 'browserHistory'

import type { Store } from 'redux'

import './index.scss'
import Root from './Root'
import { i18n } from './helpers'
import theme from './theme'

type AppProps = {
	store: Store<*, *>,
}

export default class App extends Component<AppProps> {
	render() {
		const { store } = this.props
		return (
			<ThemeProvider theme={theme}>
				<Provider store={store}>
					<I18nextProvider i18n={i18n}>
						<ConnectedRouter history={browserHistory}>
							<Root />
						</ConnectedRouter>
					</I18nextProvider>
				</Provider>
			</ThemeProvider>
		)
	}
}
