// @flow
import { addError, t, Validators } from 'helpers'
import { get, isEmpty, isString } from 'lodash'
import moment from 'moment'

import * as formFields from 'constants/subscription/form/SubscriptionForm'
import { DEFAULT_DATE_FORMAT } from 'constants/datetime'

import { isDate } from 'helpers/form-validation/Validators'

import type { SubscriptionFormValues } from 'types/subscription/form/SubscriptionForm'

const REQUIRED_FIELDS: Set<string> = new Set([
	formFields.DESTINATION,
	formFields.PUBLICATION,
	formFields.QUANTITY,
	formFields.SUBSCRIPTION_START_DATE,
])

function validate(values: SubscriptionFormValues, validStartDate: moment): Object {
	let errors: Object = {}

	for (const requiredField of REQUIRED_FIELDS) {
		errors = addError(errors, requiredField,
			Validators.required(get(values, requiredField, null)))
	}

	if (isBlank(values[formFields.COMPANY_NAME])) {
		errors = addError(errors, formFields.NAME,
			Validators.required(values[formFields.NAME]))
		errors = addError(errors, formFields.SURNAME,
			Validators.required(values[formFields.SURNAME]))
	}

	if (isBlank(values[formFields.NAME]) && isBlank(values[formFields.SURNAME])) {
		errors = addError(errors, formFields.COMPANY_NAME,
			Validators.required(values[formFields.COMPANY_NAME]))
	}

	if (!isBlank(values[formFields.PLACE_NAME]) || !isBlank(values[formFields.HOUSE_NUMBER]) || !isBlank(values[formFields.APARTMENT])) {
		errors = addError(errors, formFields.ZIP,
			Validators.required(values[formFields.ZIP]))
	}

	errors = addError(errors, formFields.SUBSCRIPTION_END_DATE,
		Validators.isDateAfter(values[formFields.SUBSCRIPTION_END_DATE], values[formFields.SUBSCRIPTION_START_DATE]))

	errors = addError(errors, formFields.SUBSCRIPTION_START_DATE,
		isDateInWorkdayRange(validStartDate, values[formFields.SUBSCRIPTION_START_DATE]))

	return errors
}

const isDateInWorkdayRange = (
	validStartDate: moment,
	value: any,
	originalDate: moment = moment(),
	messageKey: string = 'validation.input.date_weekday_before',
) => {
	if (value == null || !!isDate(value)) {
		return undefined
	}

	const day = moment(value).startOf('day')
	const firstAvailableDay = moment(validStartDate).startOf('day')
	return day.isBefore(firstAvailableDay) ? t(messageKey, { allowedDate: moment(firstAvailableDay).format(DEFAULT_DATE_FORMAT) }) : undefined
}

function isBlank(str: any): boolean {
	return str == null || (isString(str) && isEmpty(str.trim()))
}

export default validate