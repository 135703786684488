// @flow
import type { Element } from 'react'

import './TitleLine.scss'

export type TitleLineProps = {|
	title: string
|}

function TitleLine({ title }: TitleLineProps): Element<*> {
	return (
		<div className="title-line">
			<h3>{title}</h3>
			<hr />
		</div>
	)
}

export default TitleLine