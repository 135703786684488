// @flow
export const SUBSCRIPTION_CODE: string = 'registrantSubscriptionCode'
export const RECIPIENT_NAME: string = 'recipientName'
export const RECIPIENT_SURNAME: string = 'recipientSurname'
export const DESTINATION: string = 'destination'
export const STATUS: string = 'status'
export const PUBLICATION: string = 'productNameAndCode'
export const INCLUDE_EXPIRED: string = 'includeExpired'
export const PROVIDED_FROM: string = 'providedFrom'
export const PROVIDED_TO: string = 'providedTo'
export const REGISTRANT: string = 'registrantId'
