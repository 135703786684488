// @flow
import { PureComponent } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
	AccordionSummary,
	AccordionDetails,
	Accordion,
	Typography,
} from '@mui/material'
import { withStyles } from '@mui/styles'

import type { Node } from 'react'

type ExpansionPanelWrapperProps = {|
	children: Node,
	summary: string,
	defaultExpanded?: boolean,
	classes: any,
|}

const styles = theme => ({
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular,
	},
	accordionDetails: {
		display: 'block',
	},
})

class ExpansionPanelWrapper extends PureComponent<ExpansionPanelWrapperProps> {
	render() {
		const { children, summary, defaultExpanded, classes } = this.props
		return (
			<Accordion defaultExpanded={defaultExpanded}>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography className={classes.heading}>{summary}</Typography>
				</AccordionSummary>
				<AccordionDetails className={classes.accordionDetails}>
					{children}
				</AccordionDetails>
			</Accordion>
		)
	}
}

export default withStyles(styles)(ExpansionPanelWrapper)