// @flow
import type { UserInfo } from 'types/auth'
import type { Dispatch, ThunkAction } from 'types/Store'

import { updateCurrentUser } from './currentUserActions'

export function setCurrentUser(user: UserInfo): ThunkAction {
	return async (dispatch: Dispatch) => {
		dispatch(updateCurrentUser(user))
	}
}

export function clearCurrentUser(): ThunkAction {
	return async (dispatch: Dispatch) => {
		dispatch(updateCurrentUser(null))
	}
}