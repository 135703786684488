// @flow

export function thenWait<R>(delay: number): (R) => Promise<R> {
	return function onFulfill(result: R): Promise<R> {
		return new Promise(resolve => setTimeout(() => resolve(result), delay))
	}
}

export function catchWait<R>(delay: number): (any) => Promise<R> {
	return function onReject(error: any): Promise<R> {
		return new Promise((resolve, reject) => setTimeout(() => reject(error), delay))
	}
}
