// @flow
import { set } from 'lodash'

/**
 * Modifies original `errors` object by intention, for performance reason: O(N) instead of O(N^2).
 */
export function addError(errors: Object, path: string, error: ?string | ?Object): Object {
	if (error) {
		set(errors, path, error)
	}
	return errors
}