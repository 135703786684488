// @flow
import { useTranslation } from 'react-i18next'
import { Box, Divider, FormLabel } from '@mui/material'
import { LOCALE_COMMON_COMPONENTS, LOCALE_NAMESPACE_COMMON } from 'constants/locale'

import type { Translator } from 'helpers'

import './GridPagesDetails.scss'

export type GridPagesDetailsProps = {|
	page: number,
	rowsPerPage: number,
	totalElements: number
|}

function GridPagesDetails({ page, rowsPerPage, totalElements }: GridPagesDetailsProps) {
	const { t }: { t: Translator } = useTranslation(LOCALE_NAMESPACE_COMMON, { keyPrefix: LOCALE_COMMON_COMPONENTS })

	return (
		<FormLabel className="form-label">
			<Box
				sx={{
					'display': 'flex',
					'alignItems': 'center',
					'borderRadius': 1,
					'& svg': {
						m: 1.5,
					},
					'& hr': {
						mx: 0.5,
					},
				}}
			>
				<span>{t('grid_pages_details.show')}: {getNumberOfFirstItemOnPage(page, rowsPerPage)}</span>
				<span> - </span>
				<span>{getNumberOfLastItemOnPage(page, rowsPerPage, totalElements)}&nbsp;&nbsp;&nbsp;</span>
				<Divider color="gray" orientation="vertical" flexItem />
				<span>&nbsp;&nbsp;&nbsp;{t('grid_pages_details.overall')}: {totalElements}</span>
			</Box>
		</FormLabel>
	)
}

const getNumberOfFirstItemOnPage = (page: number, rowsPerPage: number): number => {
	if (page === 0) {
		return 1
	}
	return (page * rowsPerPage) - rowsPerPage + 1
}

const getNumberOfLastItemOnPage = (page: number, rowsPerPage: number, totalElements: number): number => {
	if (page === 0) {
		if (rowsPerPage > totalElements) {
			return totalElements
		}
		return rowsPerPage
	}

	if (page * rowsPerPage > totalElements) {
		return totalElements
	}
	return page * rowsPerPage
}

export default GridPagesDetails