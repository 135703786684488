// @flow
import { get } from 'lodash'
import { NotificationManager } from 'react-notifications'

import { SubscriptionRestService } from 'services'
import type { Try } from 'types/Try'
import { getError, getValue, isSuccess } from 'types/Try'
import { browserHistory } from 'browserHistory'
import { getSubscriptionsDetailUrl } from 'Paths'
import { t } from 'helpers'
import { LOCALE_NAMESPACE_SUBSCRIPTIONS, LOCALE_SUBSCRIPTIONS } from 'constants/locale'

import * as formFields from 'constants/subscription/form/SubscriptionCancelForm'
import type { SubscriptionCancelRequest, SubscriptionIdResponse } from 'types/subscription/Subscription'
import type { SubscriptionCancelFormValues } from 'types/subscription/form/SubscriptionCancelForm'
import type { CustomerInfo } from 'types/auth'

const NOTIFICATION_SHOW_TIME_MS: number = 10000

export const onSubmitWithCustomer = (customer: ?CustomerInfo) => (formValues: SubscriptionCancelFormValues) =>
	saveCancel(formValues, customer)

async function saveCancel(
	formValues: SubscriptionCancelFormValues,
	customer: ?CustomerInfo
): Promise<SubscriptionIdResponse> {
	const request: SubscriptionCancelRequest = {
		subscriptionId: formValues.subscriptionId,
		registrantCustomer: {
			cmsId: customer?.customerId,
			name: customer?.name,
			code: customer?.regNumber,
		},
		appliedFrom: get(formValues, formFields.CANCELLED_FROM),
		returnedSum: get(formValues, formFields.RETURN_PAYMENT) || null,
		dataSource: 'EXT_UI',
	}

	const response: Try<SubscriptionIdResponse> = await SubscriptionRestService.cancelSubscription(request)
	if (isSuccess(response)) {
		// in order to reload same page without the need to refresh
		browserHistory.replace('/empty')
		setTimeout(() => {
			browserHistory.replace(getSubscriptionsDetailUrl(response.id))
		}, 10)
		NotificationManager.success(
			t('cancel.success', null, LOCALE_SUBSCRIPTIONS, LOCALE_NAMESPACE_SUBSCRIPTIONS),
			null,
			NOTIFICATION_SHOW_TIME_MS
		)
		return getValue(response)
	} else {
		throw getError(response)
	}
}
