// @flow
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { OnChange } from 'react-final-form-listeners'
import { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import moment from 'moment'
import { useSelector } from 'react-redux'

import { SubscriptionService } from 'services'
import DatePickerView from 'components/Form/DatePicker'
import { LOCALE_NAMESPACE_SUBSCRIPTIONS, LOCALE_SUBSCRIPTIONS } from 'constants/locale'
import * as formFields from 'constants/subscription/form/SubscriptionSuspendForm'

import type { Element } from 'react'
import type { FormRenderProps } from 'react-final-form'
import type { Translator } from 'helpers'
import type { SubscriptionSuspendFormValues } from 'types/subscription/form/SubscriptionSuspendForm'
import type { DeliveryDto } from 'types/subscription/Subscription'
import type { Store } from 'types/Store'
import { onSubmitWithCustomer } from '../SubscriptionSuspendActions'

import validate from './SubscriptionSuspendFormValidator'
import './SubscriptionSuspendForm.scss'

const NOTIFICATION_SHOW_TIME_MS: number = 10000

export type SubscriptionSuspendFormProps = {
	subscriptionStartDate: ?string,
	subscriptionId: ?number,
	delivery: ?DeliveryDto,
}

function SubscriptionSuspendForm({
	subscriptionStartDate,
	subscriptionId,
	delivery,
}: SubscriptionSuspendFormProps): Element<*> {
	const { t }: { t: Translator } = useTranslation(LOCALE_NAMESPACE_SUBSCRIPTIONS, { keyPrefix: LOCALE_SUBSCRIPTIONS })
	const [suspendedToMinDate, setSuspendedToMinDate] = useState<moment>(moment(subscriptionStartDate))
	const [minSuspendDate, setMinSuspendDate] = useState<moment>(moment(subscriptionStartDate))
	const customer = useSelector((state: Store) => state.currentUser?.userInfo?.customer)

	useEffect((): void => {
		SubscriptionService.getMinimalStartDate()
			.then((startDate: moment): void => {
				setMinSuspendDate(startDate)
			})
			.catch((error: Error): void => {
				NotificationManager.error(error.message, t('search.fail'), NOTIFICATION_SHOW_TIME_MS)
			})
	}, [t])

	return (
		<div className="subscription-suspend-form">
			<Form
				onSubmit={onSubmitWithCustomer(customer)}
				validate={(values) => validate(values, minSuspendDate)}
				initialValues={{ subscriptionStartDate, subscriptionId, delivery }}
				render={({ handleSubmit }: FormRenderProps<SubscriptionSuspendFormValues>): Element<*> => (
					<form onSubmit={handleSubmit}>
						<div className="mb-2 dates">
							<Field
								name={formFields.SUSPENDED_FROM}
								component={DatePickerView}
								minDate={moment.max(
									minSuspendDate,
									moment(subscriptionStartDate)
								)}
								maxDate={moment(delivery?.appliedTo)}
							/>
							<OnChange name={formFields.SUSPENDED_FROM}>
								{(date: moment): void => {
									setSuspendedToMinDate(moment(date) || moment())
								}}
							</OnChange>
							<div className="dash">&mdash;</div>
							<Field
								name={formFields.SUSPENDED_TO}
								component={DatePickerView}
								minDate={moment.max(suspendedToMinDate, moment(subscriptionStartDate))}
							/>
						</div>
						<div className="form-buttons">
							<button className="btn submit-button" type="submit">
								{t('suspend.submit')}
							</button>
						</div>
					</form>
				)}
			/>
		</div>
	)
}

export default SubscriptionSuspendForm
