// @flow
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { LOCALE_COMMON_COMPONENTS, LOCALE_NAMESPACE_COMMON } from 'constants/locale'

import type { Element } from 'react'
import type { Translator } from 'helpers'

import { LanguageSelectForLogin, Logout } from 'components'
import { PATH_AUTH } from 'Paths'

import omnivaLogo from 'assets/images/omniva.svg'

import './LoginView.scss'

function LoginView(): Element<*> {
	const { t }: { t: Translator } = useTranslation(LOCALE_NAMESPACE_COMMON, { keyPrefix: LOCALE_COMMON_COMPONENTS })

	return (
		<div className="login-view">
			<div className="login-view__container d-flex">
				<div className="login-view__container__left col">
					<div className="login-view__container__left__logo">
						<img src={omnivaLogo} alt="logo" />
						<div className="login-view__container__left__logo__slogan">{t('login.view.slogan')}</div>
					</div>
				</div>
				<div className="login-view__container__right col align-items-center">
					<div className="login-view__container__right__top d-flex justify-content-between">
						<LanguageSelectForLogin />
						<Logout />
					</div>
					<div
						className="login-view__container__welcome_text"
						dangerouslySetInnerHTML={{ __html: t('login.view.welcome.text') }}
					/>
					<div className="login-view__container__right__center">
						<Link to={PATH_AUTH}>
							<button className="btn-login" type="button">
								{t('auth.login')}
							</button>
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
}

export default LoginView
