// @flow
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'

import { LOCALE_NAMESPACE_SUBSCRIPTIONS, LOCALE_SUBSCRIPTIONS } from 'constants/locale'

import type { Element } from 'react'
import type { Translator } from 'helpers'
import type { Try } from 'types/Try'
import type { SubscriptionDto, SubscriptionFieldsCheckResponse } from 'types/subscription/Subscription'
import type { SubscriptionFormValues } from 'types/subscription/form/SubscriptionForm'

import { Breadcrumbs } from 'components'
import { getSubscription } from 'services/subscription-rest-service/SubscriptionRestService'
import { getSubscriptionsUrl } from 'Paths'
// $FlowIssue Flow does not recognize this approach: https://stackoverflow.com/a/52605225/8217032
import { ReactComponent as Left } from 'assets/images/outline-arrow-left.svg'
import { getError, isSuccess } from 'types/Try'

import { t as tt } from 'helpers'
import { mapDtoToFormValues } from './SubscriptionDtoConverter'
import SubscriptionForm from '../Form/SubscriptionForm'
import { DeliveryList } from '../Form/Delivery'
import { HistoryList } from '../Form/History'
import './SubscriptionDetailView.scss'

export type SubscriptionDetailProps = {|
	subscriptionId: ?string,
|}

const NOTIFICATION_SHOW_TIME_MS: number = 10000
const EMPTY_FORM: SubscriptionFormValues = { id: null }
const EMPTY_RESPONSE: SubscriptionFieldsCheckResponse = { id: null }

function SubscriptionDetailView({ subscriptionId }: SubscriptionDetailProps): Element<*> {
	const { t }: { t: Translator } = useTranslation(LOCALE_NAMESPACE_SUBSCRIPTIONS, { keyPrefix: LOCALE_SUBSCRIPTIONS })
	const [initialValues, setInitialValues] = useState<SubscriptionFormValues>(EMPTY_FORM)

	useEffect((): void => {
		getSubscription(Number(subscriptionId))
			.then((response: Try<SubscriptionDto>): void => {
				if (response instanceof Error) {
					NotificationManager.error(
						tt('permissions', null, LOCALE_SUBSCRIPTIONS, LOCALE_NAMESPACE_SUBSCRIPTIONS),
						null,
						NOTIFICATION_SHOW_TIME_MS
					)
				}
				else if (isSuccess(response)) {
					setInitialValues(mapDtoToFormValues(response))
				} else {
					NotificationManager.error(getError(response).message, t('search.fail'), NOTIFICATION_SHOW_TIME_MS)
				}
			})
	}, [subscriptionId, t])

	return (
		<div className="subscription-detail-view client-area">
			<Grid container justify="flex-start" alignItems="flex-start">
				<Grid className="mt-2" item xs={12}>
					<Breadcrumbs />
					<div className="title-row">
						<Link to={getSubscriptionsUrl()}>
							<Left className="left-arrow" />
						</Link>
						<h3>{t('detail_form.title')} {initialValues.registrantSubscriptionCode}</h3>
					</div>
					<SubscriptionForm
						initialValues={initialValues}
						isNewSubscription={false}
						checkFieldsResult={EMPTY_RESPONSE}
					/>
					<DeliveryList subscriptionId={subscriptionId} />
					<HistoryList subscriptionId={subscriptionId} />
				</Grid>
			</Grid>
		</div>
	)
}

export default SubscriptionDetailView