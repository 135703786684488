// @flow
import AsyncSelect from 'react-select/async'
import { debounce } from 'lodash'
import { NotificationManager } from 'react-notifications'
import { useTranslation } from 'react-i18next'
import { FormControl, FormHelperText } from '@mui/material'

import { CustomerRestService } from 'services'
import { getError, getValue, isSuccess } from 'types/Try'

import { LOCALE_AUTO_SUGGEST_CUSTOMER, LOCALE_NAMESPACE_COMMON } from 'constants/locale'

import type { FieldRenderProps } from 'react-final-form'
import type { Pageable } from 'types/Pageable'
import type { Translator } from 'helpers'
import type { Try } from 'types/Try'
import type { CustomerOption, CustomerSearchDto } from 'types/customer'
import type { Element } from 'react'

type AutoSuggestCustomerProps = {|
	className?: string,
	classNamePrefix?: string,
	formControlClassName: string,
|}

const MIN_LENGTH: number = 3
const WAIT_TIME_MS: number = 500
const NOTIFICATION_SHOW_TIME_MS: number = 10000

function AutoSuggestCustomerView({
	className,
	classNamePrefix,
	formControlClassName,
	input,
	meta: { touched, submitFailed, error },
}: AutoSuggestCustomerProps & FieldRenderProps): Element<*> {
	const { t }: { t: Translator } = useTranslation(LOCALE_NAMESPACE_COMMON, { keyPrefix: LOCALE_AUTO_SUGGEST_CUSTOMER })

	const handleOptionsLoading = (searchValue: string, setOptionsCallback: Function): void => debounceSuggestCustomers(searchValue, setOptionsCallback)

	const debounceSuggestCustomers: (searchValue: string, setOptionsCallback: Function) => void = debounce((searchValue, setOptionsCallback) => {
		suggestCustomers(searchValue).then(options => {
			setOptionsCallback(options)
		})
	}, WAIT_TIME_MS)

	const suggestCustomers = async (searchValue: string): Promise<Array<CustomerOption>> => {
		if (searchValue && searchValue.length >= MIN_LENGTH) {
			const response: Try<Pageable<CustomerSearchDto>> = await CustomerRestService.search(searchValue)
			if (isSuccess(response)) {
				return mapToOptions(getValue(response).content)
			} else {
				NotificationManager.error(getError(response).message, t('search.fail'), NOTIFICATION_SHOW_TIME_MS)
				return []
			}
		} else {
			return []
		}
	}

	return (
		<FormControl className={formControlClassName} error={error && (touched || submitFailed)}>
			<AsyncSelect
				className={className}
				classNamePrefix={classNamePrefix}
				loadOptions={handleOptionsLoading}
				placeholder={t('placeholder')}
				noOptionsMessage={() => t('no_options')}
				loadingMessage={() => t('loading')}
				isClearable
				defaultOptions
				onBlur={input.onBlur}
				onFocus={input.onFocus}
				onChange={input.onChange}
				value={input.value}
				getOptionLabel={(customer) => (customer: CustomerOption)?.label || createLabel((customer: CustomerSearchDto))}
			/>
			{(error && (touched || submitFailed)) ? (
				<FormHelperText>{(touched || submitFailed) && Object.values(error)}</FormHelperText>
			) : null}
		</FormControl>
	)
}

const mapToOptions = (customers: Array<CustomerSearchDto>): Array<CustomerOption> => customers
	.map((customer: CustomerSearchDto): CustomerOption => ({ customer, label: createLabel(customer) }))
	.filter((option: CustomerOption): boolean => Boolean(option.label))

const createLabel = (customer: CustomerSearchDto) => {
	if (customer.name && customer.code) {
		return `${customer.name} (${customer.code})`
	}
	return ''
}

export default AutoSuggestCustomerView