// @flow
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { NotificationManager } from 'react-notifications'

import { AuthRestService } from 'services'
import { browserHistory } from 'browserHistory'
import { isSuccess } from 'types/Try'

import { PATH_SPLASH } from 'Paths'
import { LOCALE_COMMON, LOCALE_NAMESPACE_COMMON } from 'constants/locale'

import type { Element } from 'react'
import type { Translator } from 'helpers'
import type { Try } from 'types/Try'

import imgLoading from 'assets/images/loading.svg'

import './CasRedirectView.scss'

export type CasRedirectViewProps = {
	SAMLart: ?string,
}

const SHOW_TIME_MS: number = 30000

function CasRedirectView({ SAMLart }: CasRedirectViewProps): Element<*> {
	const { t }: { t: Translator } = useTranslation(LOCALE_NAMESPACE_COMMON, { keyPrefix: LOCALE_COMMON })

	useEffect(() => {
		if (SAMLart) {
			AuthRestService.casRedirect(SAMLart)
				.then((response: Try<void>): void => {
					if (!isSuccess(response)) {
						NotificationManager.error(t('components.auth.cas_redirect.error.note'),
							t('components.auth.cas_redirect.error.title'), SHOW_TIME_MS)
					}
				})
				.finally((): void => {
					redirectTo(PATH_SPLASH)
				})
		} else {
			NotificationManager.error(t('components.auth.cas_redirect.samlart_token.missing.note'),
				t('components.auth.cas_redirect.samlart_token.missing.title'), SHOW_TIME_MS)
			redirectTo(PATH_SPLASH)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className="cas-redirect-view">
			<div className="cas-redirect-view__container d-flex flex-column justify-content-center align-items-center">
				<div className="h2 cas-redirect-view__banner">
					<img src={imgLoading} alt="loading" />
					<span>{t('components.loading')}</span>
				</div>
			</div>
		</div>
	)
}

function redirectTo(path: string) {
	setTimeout((): void => {
		browserHistory.push(path)
	}, 100)
}

export default CasRedirectView