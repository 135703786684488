// @flow
import type { RecipientDestination } from 'types/subscription/Subscription'
import { joinNonEmptyWithSeparator } from './string-util'

const getFormattedHouseAndApartmentNumber = (houseNumber: ?string, apartmentNumber: ?string = '') => (houseNumber?.length
	? joinNonEmptyWithSeparator([houseNumber, apartmentNumber], '-')
	: `${(apartmentNumber ?? '')}`).trim()

const getPlaceNameWithHouseAndApartmentNumber = (placeName: ?string, houseNumber: ?string, apartmentNumber: ?string) => {
	const joinWithHouseAndApartment = () => joinNonEmptyWithSeparator([placeName, getFormattedHouseAndApartmentNumber(houseNumber, apartmentNumber)], '/')
	const joinWithApartment = () => joinNonEmptyWithSeparator([placeName, apartmentNumber], '-')
	const formatted = houseNumber?.length ? joinWithHouseAndApartment() : joinWithApartment()
	return placeName?.length ? formatted.trim() : ''
}

const getStreetOrPlaceNameWithHouseAndApartmentNumber = (street: ?string, houseNumber: ?string, apartmentNumber: ?string, placeName: ?string) => {
	const formattedHouseAndApartmentNumber = getFormattedHouseAndApartmentNumber(houseNumber, apartmentNumber)
	const formattedPlaceName = joinNonEmptyWithSeparator([houseNumber, placeName], '-')
	const getFormattedStreet = () => (apartmentNumber?.length
		? joinNonEmptyWithSeparator([`${(street ?? '')} ${formattedHouseAndApartmentNumber}`, placeName], ', ')
		: `${(street ?? '')} ${formattedPlaceName}`)
	const result = street?.length
		? getFormattedStreet()
		: getPlaceNameWithHouseAndApartmentNumber(placeName, houseNumber, apartmentNumber)
	return result.trim()
}

export function recipientCustomAddressRender(destination: RecipientDestination) {
	const {
		street = '',
		placeName = '',
		houseNumber = '',
		apartmentNumber = '',
		smallPlace = '',
		settlement = '',
		municipality = '',
		county = '',
		zip = '',
		hubName,
	} = destination

	if (hubName?.length) {
		return hubName
	}
	const streetOrPlaceNameWithHouseAndApartmentNumber = getStreetOrPlaceNameWithHouseAndApartmentNumber(street, houseNumber, apartmentNumber, placeName)
	const formattedSmallPlace = (streetOrPlaceNameWithHouseAndApartmentNumber?.length
		? smallPlace
		: `${(smallPlace ?? '')} ${(getFormattedHouseAndApartmentNumber(houseNumber, apartmentNumber))}`)?.trim()

	const addressParts = [
		streetOrPlaceNameWithHouseAndApartmentNumber,
		formattedSmallPlace,
		settlement,
		municipality,
		county,
		zip,
	].filter(Boolean)
	return addressParts.join(', ')
}