// @flow
import { API_URL } from 'Api'
import { RestClient } from 'helpers'

import type { Try } from 'types/Try'
import type { Pageable } from 'types/Pageable'
import type { ServicePoint, ServicePointIdAndName } from 'types/Location'

const SERVICE_POINT_SERVICE: string = `${API_URL}/service-points`

export async function searchPostOfficesByName(searchText: string): Promise<Try<Pageable<ServicePoint>>> {
	return RestClient.get(`${SERVICE_POINT_SERVICE}/search-post-offices-by-name?searchText=${searchText}`)
}

export async function searchPostOfficesAndDistributionPointsByName(searchText: string): Promise<Try<Pageable<ServicePoint>>> {
	return RestClient.get(`${SERVICE_POINT_SERVICE}/search-post-offices-and-distribution-points-by-name?searchText=${searchText}`)
}

export async function getServicePointById(id: number): Promise<Try<ServicePointIdAndName>> {
	return RestClient.get(`${SERVICE_POINT_SERVICE}/${id}`)
}