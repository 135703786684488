// @flow
import { API_URL } from 'Api'
import { PageRequest, RestClient } from 'helpers'

import type { Try } from 'types/Try'
import type {
	DeliveryDto,
	EventDto,
	SubscriptionClosestDeliveriesDto,
	SubscriptionDto,
	SubscriptionFieldsCheckResponse,
	SubscriptionIdResponse,
	SubscriptionSaveRequest,
	SubscriptionRedirectRequest,
	SubscriptionSuspendRequest,
	SubscriptionCancelRequest,
} from 'types/subscription/Subscription'
import type { Pageable } from 'types/Pageable'
import type { CustomerDto } from 'types/customer'

const SUBSCRIPTIONS_URL: string = `${API_URL}/subscriptions`

export async function getAllSubscriptions(
	searchCriteria: string,
	page: PageRequest
): Promise<Try<Pageable<SubscriptionDto>>> {
	return RestClient.get(`${SUBSCRIPTIONS_URL}?${searchCriteria}&${page.toString()}`)
}

export async function getNeedsAttention(page: PageRequest): Promise<Try<Pageable<SubscriptionDto>>> {
	return RestClient.get(`${SUBSCRIPTIONS_URL}/needs-attention?&${page.toString()}`)
}

export async function saveSubscription(request: SubscriptionSaveRequest): Promise<Try<SubscriptionIdResponse>> {
	return RestClient.post(`${SUBSCRIPTIONS_URL}`, request)
}

export async function getSubscription(subscriptionId: number): Promise<Try<SubscriptionDto>> {
	return RestClient.get(`${SUBSCRIPTIONS_URL}/${subscriptionId}`)
}

export async function getSubscriptionDeliveries(
	subscriptionId: number,
	page: PageRequest
): Promise<Try<Pageable<DeliveryDto>>> {
	return RestClient.get(`${SUBSCRIPTIONS_URL}/delivery/${subscriptionId}?${page.toString()}`)
}

export async function getSubscriptionEvents(
	subscriptionId: number,
	page: PageRequest
): Promise<Try<Pageable<EventDto>>> {
	return RestClient.get(`${SUBSCRIPTIONS_URL}/event/${subscriptionId}?${page.toString()}`)
}

export async function getSubscriptionClosestDeliveries(
	subscriptionId: number
): Promise<Try<SubscriptionClosestDeliveriesDto>> {
	return RestClient.get(`${SUBSCRIPTIONS_URL}/${subscriptionId}/closest-deliveries`)
}

export async function suspendSubscription(request: SubscriptionSuspendRequest): Promise<Try<SubscriptionIdResponse>> {
	return RestClient.post(`${SUBSCRIPTIONS_URL}/suspend`, request)
}

export async function cancelSubscription(request: SubscriptionCancelRequest): Promise<Try<SubscriptionIdResponse>> {
	return RestClient.post(`${SUBSCRIPTIONS_URL}/cancel`, request)
}

export async function redirectSubscription(request: SubscriptionRedirectRequest): Promise<Try<SubscriptionIdResponse>> {
	return RestClient.post(`${SUBSCRIPTIONS_URL}/redirect`, request)
}

export async function updateSubscription(request: SubscriptionSaveRequest): Promise<Try<SubscriptionIdResponse>> {
	return RestClient.put(`${SUBSCRIPTIONS_URL}`, request)
}

export async function checkSubscriptionFields(subscriptionId: number): Promise<Try<SubscriptionFieldsCheckResponse>> {
	return RestClient.get(`${SUBSCRIPTIONS_URL}/check/${subscriptionId}`)
}

export async function getMinimalStartDate(): Promise<Try<string>> {
	return RestClient.get(`${SUBSCRIPTIONS_URL}/minimal-start-date`)
}
export async function getRegistrant(): Promise<Try<Pageable<CustomerDto>>> {
	return RestClient.get(`${SUBSCRIPTIONS_URL}/registrant`)
}
