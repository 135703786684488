// @flow
import { isNull } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Select, MenuItem } from '@mui/material'
import LanguageIcon from '@mui/icons-material/Language'

import { getLanguage, setLanguage } from 'helpers/localization/i18n'

import { LOCALE_LANGUAGE_SELECT, LOCALE_NAMESPACE_COMMON, SUPPORTED_LANGUAGES } from 'constants/locale'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import type { Element } from 'react'
import type { Translator } from 'helpers'

import './LanguageSelectView.scss'

function LanguageSelectView(): Element<*> {
	const { t }: { t: Translator } = useTranslation(LOCALE_NAMESPACE_COMMON, { keyPrefix: LOCALE_LANGUAGE_SELECT })

	const getLanguageItem = (code: string): Element<*> => (
		<MenuItem
			key={code}
			value={code}
			onClick={() => setLanguage(code)}
			selected={getLanguage() === code}
		>
			{t(`language.code.${code}`)}
		</MenuItem>
	)

	const getLanguageItemList = () => SUPPORTED_LANGUAGES.map(getLanguageItem).filter(x => !isNull(x))

	return (
		<div className="language-select">
			<LanguageIcon className="logo" />
			<Select
				value={getLanguage()}
				disableUnderline
				className="select"
				variant="standard"
				sx={{
					'& .MuiSelect-select:focus': {
						backgroundColor: 'white',
					},
				}}
				IconComponent={KeyboardArrowDownIcon}
			>
				{getLanguageItemList()}
			</Select>
		</div>
	)
}

export default LanguageSelectView