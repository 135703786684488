// @flow
import axios from 'axios'

import type { Axios, AxiosXHR, AxiosXHRConfigBase } from 'axios'
import type { Try } from 'types/Try'

import Api from 'Api'
import * as FeatureToggle from 'constants/feature-toggle'

import { thenWait, catchWait } from 'helpers'

const debugAxios: Axios = axios.create()

debugAxios.interceptors.request.use(request => {
	console.log('Axios Request', request)
	return request
})

debugAxios.interceptors.response.use(response => {
	console.log('Axios Response', response)
	return response
})

function getDefaultConfig<T, R>(): AxiosXHRConfigBase<T, R> {
	const headers = {}

	const language: ?string = Api.getLanguage()
	if (language) {
		headers['accept-language'] = language
	}

	return {
		headers,
		withCredentials: true,
	}
}

function getDefaultConfigForFile<T, R>(): AxiosXHRConfigBase<T, R> {
	return {
		...getDefaultConfig<T, R>(),
		responseType: 'blob',
	}
}

export function del<R>(url: string): Promise<Try<R>> {
	return filter(getAxios().delete(Api.getDefaultPathDescription() + url, getDefaultConfig<mixed, R>()))
		.then((res: AxiosXHR<mixed, R>): R => res.data)
		.catch(err => err)
}

export function get<R>(url: string): Promise<Try<R>> {
	return filter(getAxios().get(Api.getDefaultPathDescription() + url, getDefaultConfig<mixed, R>()))
		.then((res: AxiosXHR<mixed, R>): R => res.data)
		.catch(err => err)
}

export function getFile<R>(url: string): Promise<Try<R>> {
	return filter(getAxios().get(Api.getDefaultPathDescription() + url, getDefaultConfigForFile<mixed, R>()))
		.then((res: AxiosXHR<mixed, R>): R => res.data)
		.catch(err => err)
}

export function post<T, R>(url: string, data?: T): Promise<Try<R>> {
	return filter(getAxios().post(Api.getDefaultPathDescription() + url, data, getDefaultConfig<T, R>()))
		.then((res: AxiosXHR<T, R>): R => res.data)
		.catch(err => err)
}

export function put<T, R>(url: string, data?: T): Promise<Try<R>> {
	return filter(getAxios().put(Api.getDefaultPathDescription() + url, data, getDefaultConfig<T, R>()))
		.then((res: AxiosXHR<T, R>): R => res.data)
		.catch(err => err)
}

function filter<T>(input: Promise<T>): Promise<T> {
	return Api.isFeatureToggleEnabled(FeatureToggle.SIMULATE_SLOW_HTTP_CONNECTION)
		? input.then(thenWait(5000), catchWait(5000))
		: input
}

function getAxios(): Axios {
	return Api.isFeatureToggleEnabled(FeatureToggle.LOG_HTTP_CONNECTION)
		? debugAxios
		: axios
}