// @flow
import { API_URL } from 'Api'
import { RestClient } from 'helpers'

import type { Try } from 'types/Try'
import type { GeneratePvpSortPlanRequest, GeneratePvpSortPlanResponse } from './types'

const BASE_URL: string = `${API_URL}/pvp-sort-plan`

export async function startGeneration(request: GeneratePvpSortPlanRequest): Promise<Try<GeneratePvpSortPlanResponse>> {
	return RestClient.post(`${BASE_URL}/generate`, request)
}
