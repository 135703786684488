// @flow
import { MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'

import type { Translator } from 'helpers'
import type { Element } from 'react'

import { LOCALE_NAMESPACE_SUBSCRIPTIONS, LOCALE_SUBSCRIPTIONS } from 'constants/locale'

const fontStyle: * = Object.freeze({
	fontSize: 14,
	fontFamily: 'TT Wellingtons, sans-serif',
})

export const SelectItems = (object: Object, prefix: string): Array<Element<*>> => {
	const { t }: { t: Translator } = useTranslation(LOCALE_NAMESPACE_SUBSCRIPTIONS, { keyPrefix: LOCALE_SUBSCRIPTIONS })

	return Object.keys(object).map((key: string) => (
		<MenuItem style={fontStyle} key={key} value={key}>
			{t(`${prefix}.${key}`)}
		</MenuItem>
	))
}

export const SelectItemsWithoutTranslation = (map: Object): Array<Element<*>> =>
	Object.keys(map).map((key: string) => (
		<MenuItem style={fontStyle} key={key} value={key}>
			{map[key]}
		</MenuItem>
	))
