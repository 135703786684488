// @flow
import i18n from 'i18next'
import sprintf from 'i18next-sprintf-postprocessor'
import XHR from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import Api from 'Api'
import { EN, ET, LOCALE_COMMON, LOCALE_NAMESPACE_COMMON } from 'constants/locale'
import Cache from 'i18next-localstorage-cache'

export type Translator = (key: string, options?: Object) => string

// Custom language detector
const lngDetector = new LanguageDetector(null, {
	// order and from where user language should be detected
	order: ['localStorage', 'cookie', 'myDetector', 'navigator', 'querystring', 'htmlTag'],

	// keys or params to lookup language from
	lookupQuerystring: 'lng',
	lookupCookie: 'i18next',
	lookupLocalStorage: 'i18nextLng',

	// cache user language on
	caches: ['localStorage', 'cookie'],

	// optional expire and domain for set cookie
	cookieMinutes: 10,
	cookieDomain: 'myDomain',

	// optional htmlTag with lang attribute, the default is:
	htmlTag: document.documentElement,
})

lngDetector.addDetector({
	name: 'myDetector',

	lookup() {
		return ET
	},

	cacheUserLanguage(/* lng, options */) {
		// options -> are passed in options
		// lng -> current language, will be called after init and on changeLanguage

		// store it
	},
})

i18n.on('languageChanged', (lng) => {
	console.log('Language changed', lng)
	Api.setLanguage(lng)
})

i18n.use(XHR) // eslint-disable-line import/no-named-as-default-member
	.use(sprintf)
	.use(lngDetector)
	.use(Cache)
	.init({
		fallbackLng: EN,
		whitelist: [EN, ET],
		backend: {
			loadPath: `${String(process.env.PUBLIC_URL)}/locales/{{ns}}/{{ns}}_{{lng}}.json`,
		},
		react: {
			nsMode: 'fallback', // set it to 'fallback' to let passed namespaces to translated hoc act as fallbacks
			useSuspense: false,
		},
		ns: [LOCALE_NAMESPACE_COMMON],
		defaultNS: LOCALE_NAMESPACE_COMMON,
		debug: false,
		keySeparator: '.',
		interpolation: {
			escapeValue: false, // not needed for react!!
		},
		returnObjects: true,
	})

const LOCAL_STORAGE_KEY = 'i18nextLng'

export function getLanguage(): string {
	const saved = window.localStorage && window.localStorage.getItem(LOCAL_STORAGE_KEY)
	return i18n.language || saved || i18n.language
}

export const setLanguage = (code: string) => {
	i18n.changeLanguage(code) // eslint-disable-line import/no-named-as-default-member
}

export const t = (
	key: string,
	options?: ?Object,
	prefix: string = LOCALE_COMMON,
	namespace: string = LOCALE_NAMESPACE_COMMON,
): string => i18n.t(`${prefix}.${key}`, { ns: namespace, ...options })

export default i18n
