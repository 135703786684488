// @flow
import { RestClient } from 'helpers'
import type { Try } from 'types/Try'
import type { AddressDto } from 'types/address'
import type { Pageable } from 'types/Pageable'
import { API_URL } from 'Api'

const ADDRESS_SERVICE: string = `${API_URL}/addresses`

export async function searchAddress(searchText: string): Promise<Try<Pageable<AddressDto>>> {
	return RestClient.get(`${ADDRESS_SERVICE}/search?searchText=${searchText}`)
}
