// @flow
import { stringify } from 'query-string'

import { COLUMN, REGISTRANT, STATUS } from 'constants/subscription/Subscription'
import { SubscriptionRestService } from 'services'
import { PageRequest, SortRequest } from 'helpers'

import type { Try } from 'types/Try'
import type { Pageable } from 'types/Pageable'
import type { SubscriptionDto, SubscriptionFilters, SubscriptionSort } from 'types/subscription/Subscription'
import type { CustomerDto } from 'types/customer'

const COLUMN_TO_SORT_NAME: { [string]: string } = Object.freeze({
	[COLUMN.PRODUCT_NAME_AND_CODE]: 'productName',
	[COLUMN.RECIPIENT]: 'subscriptionRecipient_recipient_name',
	[COLUMN.REGISTRANT]: 'registrant_name',
	[COLUMN.REGISTRANT_SUBSCRIPTION_CODE]: 'subscriptionRegistrantCode_registrantSubscriptionCode',
})

export function getAllSubscriptions(
	page: number,
	rowsPerPage: number,
	filters: SubscriptionFilters,
	sort: ?SubscriptionSort
): Promise<Try<Pageable<SubscriptionDto>>> {
	return SubscriptionRestService.getAllSubscriptions(
		buildSearchCriteria(filters),
		buildRequestRecip(sort, page, rowsPerPage)
	)
}

export function getRegistrant(): Promise<Try<Pageable<CustomerDto>>> {
	return SubscriptionRestService.getRegistrant()
}

const buildSearchCriteria = (filters: SubscriptionFilters): string =>
	stringify({
		registrantSubscriptionCode: filters.registrantSubscriptionCode,
		productId:
			filters.productNameAndCode && filters.productNameAndCode.product
				? filters.productNameAndCode.product.id
				: null,
		recipientName: filters.recipientName,
		recipientSurname: filters.recipientSurname,
		destination: filters?.destination?.address?.rmaId,
		destinationText: filters?.destinationText,
		status: filters.status === STATUS.ALL ? null : filters.status,
		includeExpired: filters?.includeExpired,
		registeringDateFrom: filters?.providedFrom,
		registeringDateTo: filters?.providedTo,
		registrantId: filters.registrantId === REGISTRANT.ALL ? null : filters.registrantId,
	})

const buildRequestRecip = (sort?: ?SubscriptionSort, page: number, rowsPerPage: number): PageRequest =>
	new PageRequest(page, rowsPerPage, recipientSort(sort))

const recipientSort = (sort?: ?SubscriptionSort): ?Array<SortRequest> =>
	sort
		? [
				new SortRequest(COLUMN_TO_SORT_NAME[sort.name] || sort.name, sort.direction),
				new SortRequest('subscriptionRecipient_recipient_companyName', sort.direction),
			]
		: null
