// @flow
import { SubscriptionRestService } from 'services'
import { PageRequest, SortRequest } from 'helpers'

import { HISTORY_COLUMN } from 'constants/subscription/Subscription'

import type { Try } from 'types/Try'
import type { Pageable } from 'types/Pageable'
import type { EventDto, EventSort } from 'types/subscription/Subscription'

const COLUMN_TO_SORT_NAME: { [string]: string } = Object.freeze({
	[HISTORY_COLUMN.DATE]: 'created',
	[HISTORY_COLUMN.EVENT]: 'eventType',
	[HISTORY_COLUMN.APPLICATION_PERIOD]: 'appliedFrom',
	[HISTORY_COLUMN.EVENT_PERFORMER]: 'createdBy',
})

export function getSubscriptionEvents(
	subscriptionId: number,
	page: number,
	rowsPerPage: number,
	sort: ?EventSort,
): Promise<Try<Pageable<EventDto>>> {
	return SubscriptionRestService.getSubscriptionEvents(subscriptionId, buildPageRequest(sort, page, rowsPerPage))
}

const buildPageRequest = (sort?: ?EventSort, page: number, rowsPerPage: number): PageRequest => new PageRequest(page, rowsPerPage, nullableSort(sort))

const nullableSort = (sort?: ?EventSort): ?SortRequest => (sort ? new SortRequest(COLUMN_TO_SORT_NAME[sort.name] || sort.name, sort.direction) : null)