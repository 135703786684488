// @flow
import withBreadcrumbs from 'react-router-breadcrumbs-hoc'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Breadcrumbs, Typography } from '@mui/material'

import type { Node } from 'react'
import type { Translator } from 'helpers'

import { LOCALE_COMMON_BREADCRUMB, LOCALE_NAMESPACE_COMMON } from 'constants/locale'

import './BreadcrumbsView.scss'

export type BreadcrumbsObjectType = {
	// eslint-disable-next-line react/no-unused-prop-types
	breadcrumbs: Array<BreadcrumbsType>
}

export type BreadcrumbsType = {
	breadcrumb: BreadcrumbType
}

export type BreadcrumbType = {
	key: string
}

function BreadcrumbsView(): Node {
	const { t }: { t: Translator } = useTranslation(LOCALE_NAMESPACE_COMMON, { keyPrefix: LOCALE_COMMON_BREADCRUMB })
	const BreadcrumbsComponent = withBreadcrumbs()(({ breadcrumbs }: BreadcrumbsObjectType): Node => {
		breadcrumbs.shift()
		removeId(breadcrumbs)
		return (
			<Breadcrumbs aria-label="breadcrumb" className="breadcrumb-content">
				{breadcrumbs.map(({ breadcrumb }: BreadcrumbsType, i, { length }) => {
					const breadcrumbPart: string = getBreadcrumbTranslation(breadcrumb.key, t)
					const isLastElement: boolean = i + 1 === length
					if (isLastElement) {
						return (
							<Typography
								variant="caption"
								className="breadcrumb-text-last"
								key={breadcrumb.key}
							>
								{breadcrumbPart}
							</Typography>
						)
					}
					return (
						// eslint-disable-next-line react/no-array-index-key
						<Link key={i} to={breadcrumb.key}>
							<Typography
								variant="caption"
								className="breadcrumb-text"
								key={breadcrumb.key}
							>
								{breadcrumbPart}
							</Typography>
						</Link>
					)
				})}
			</Breadcrumbs>
		)
	})
	return <BreadcrumbsComponent />
}

function getBreadcrumbTranslation(breadcrumbKey: string, t: Translator): string {
	const translationKey: string = breadcrumbKey.substring(1).split('/').join('.')
	return t(translationKey)
}

function removeId(breadcrumbs: Array<BreadcrumbsType>): void {
	const lastPart: string = breadcrumbs[breadcrumbs.length - 1].breadcrumb.key.split('/').pop()
	if (!isNaN(lastPart)) {
		breadcrumbs.pop()
	}
}

export default BreadcrumbsView