// @flow
class Cas {
	static authUrl: string = ''
	static logoutUrl: string = ''
	static strongAuthOnly: boolean = true

	static setCasUrl(authUrl: string): void {
		Cas.authUrl = authUrl
	}

	static getCasUrl(): string {
		return Cas.authUrl
	}

	static setCasLogoutUrl(logoutUrl: string): void {
		Cas.logoutUrl = logoutUrl
	}

	static getCasLogoutUrl(): string {
		return Cas.logoutUrl
	}

	/**
	 * Assign `false` if and only if the value === false. Assign `true` otherwise.
	 */
	static setStrongAuthOnly(value: ?boolean): void {
		Cas.strongAuthOnly = value !== false
	}

	static getStrongAuthOnly(): boolean {
		return Cas.strongAuthOnly
	}
}

export default Cas