// @flow
import { connect } from 'react-redux'

import * as Selector from 'shared/event/eventListSelectors'

import type { Store, ThunkAction } from 'types/Store'
import type { EventSort, RowsPerPageChange } from 'types/subscription/Subscription'

import HistoryListView from './HistoryListView'
import {
	updatePage,
	updateRowsPerPage,
	updateSort,
} from './HistoryListActions'

import type { HistoryListViewProps } from './HistoryListView'

type OwnProps = {|
	subscriptionId: ?string,
|}

type StateProps = {|
	page: number,
	rowsPerPage: number,
	sort: ?EventSort,
|}

type DispatchProps = {|
	updatePage: (page: number) => ThunkAction,
	updateRowsPerPage: (change: RowsPerPageChange) => ThunkAction,
	updateSort: (sort: EventSort) => ThunkAction,
|}

const mapStateToProps = (state: Store): StateProps => ({
	page: Selector.getPage(state),
	rowsPerPage: Selector.getRowsPerPage(state),
	sort: Selector.getSort(state),
})

const mapDispatchToProps: DispatchProps = {
	updatePage,
	updateRowsPerPage,
	updateSort,
}

export default connect<HistoryListViewProps, OwnProps, StateProps, DispatchProps, _, _>(mapStateToProps, mapDispatchToProps)(HistoryListView)