// @flow
import { ROLE } from 'constants/auth/roles'

import type { Role, UserInfo } from 'types/auth'

export const PATH_LOGIN: string = '/login'
export const PATH_SPLASH: string = '/'
export const PATH_AUTH: string = '/auth'
export const PATH_CUSTOMER: string = '/customer'
export const PATH_CAS_REDIRECT: string = '/cas-redirect'

const SUBSCRIPTIONS_BASE = 'subscriptions'
const SORTING_PLANS_BASE = 'sorting-plans'

export const getSubscriptionsUrl = () => `/${SUBSCRIPTIONS_BASE}`
export const getSubscriptionsCreateUrl = () => `/${SUBSCRIPTIONS_BASE}/create`
export const getSubscriptionsDetailUrl = (subscriptionId?: number) =>
	`/${SUBSCRIPTIONS_BASE}/detail/${subscriptionId || ':subscriptionId'}`

export const getHomePageUrl = (userInfo: UserInfo): string => getHomePageUrlPath(userInfo)

const getHomePageUrlPath = (userInfo: UserInfo): string => {
	const userRoles: Set<Role> = new Set(userInfo.roles)
	if (userRoles.has(ROLE.ROLE_PER_EXT_EXAMPLE)) {
		return getSubscriptionsUrl()
	} else {
		return PATH_LOGIN
	}
}

export const getSortingPlansUrl = () => `/${SORTING_PLANS_BASE}`

export const getCustomerSelectsUrl = () => PATH_CUSTOMER
