// @flow
import AddIcon from '@mui/icons-material/Add'

import './NewPublicationButton.scss'

export type ButtonProps = {|
	children: any
|}

function NewPublicationButton({ children }: ButtonProps) {
	return (
		<button type="submit" className="btn btn-success btn-primary new-publication pl-2">
			<AddIcon className="icon-btn" />&nbsp;&nbsp;{children}
		</button>
	)
}

export default NewPublicationButton