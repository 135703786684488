// @flow
import { PureComponent } from 'react'

import type { Node } from 'react'
import type { Role, UserInfo } from 'types/auth'

export type PermissionComponentProps = {|
	userInfo: ?UserInfo,
	availableForRole: Role | Array<Role>,
	children: Node,
|}

export default class PermissionComponent extends PureComponent<PermissionComponentProps> {
	render() {
		return this.userHasPermission() ? this.props.children : null
	}

	/**
	 * Note that time complexity is O(N * M), where N - number of user roles, and M - number of "available for" roles.
	 * It is possible to reduce the complexity to O(M) by storing user roles as a Set rather than an Array.
	 * Not making this change for now for the sake of simplicity, as the number of user roles is small at the moment: 6.
	 */
	userHasPermission = (): boolean => {
		const { userInfo, availableForRole } = this.props

		if (userInfo) {
			return Array.isArray(availableForRole)
				? availableForRole.some((r: Role): boolean => userInfo.roles.includes(r))
				: userInfo.roles.includes(availableForRole)
		}
		return false
	}
}