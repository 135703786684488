// @flow
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { OnChange } from 'react-final-form-listeners'
import type { Element } from 'react'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { NotificationManager } from 'react-notifications'

import { SubscriptionService } from 'services'
import DatePickerView from 'components/Form/DatePicker'
import { renderTextField } from 'components/Form/redux-material-ui/FormFieldBridges'
import { AutoSuggestAddress } from 'components'
import TitleLine from 'components/Form/TitleLine'

import { LOCALE_NAMESPACE_SUBSCRIPTIONS, LOCALE_SUBSCRIPTIONS } from 'constants/locale'
import * as formFields from 'constants/subscription/form/SubscriptionRedirectForm'
import type { Store } from 'types/Store'
import type { Translator } from 'helpers'
import type { SubscriptionRedirectFormValues } from 'types/subscription/form/SubscriptionRedirectForm'
import type { AddressOption } from 'types/address'
import type { DeliveryDto } from 'types/subscription/Subscription'
import type { FormRenderProps } from 'react-final-form'
import validate from './SubscriptionRedirectFormValidator'
import { handleAddressChange, onSubmitWithCustomer } from '../SubscriptionRedirectActions'

import './SubscriptionRedirectForm.scss'

export type SubscriptionRedirectFormProps = {
	subscriptionStartDate: ?string,
	name?: ?string,
	surname?: ?string,
	companyName?: ?string,
	subscriptionId?: ?number,
	delivery?: ?DeliveryDto,
}
const NOTIFICATION_SHOW_TIME_MS: number = 10000

const EMPTY_FORM: SubscriptionRedirectFormValues = { subscriptionStartDate: null }

function SubscriptionRedirectForm({
	subscriptionStartDate,
	name,
	surname,
	companyName,
	subscriptionId,
	delivery,
}: SubscriptionRedirectFormProps): Element<*> {
	const { t }: { t: Translator } = useTranslation(LOCALE_NAMESPACE_SUBSCRIPTIONS, { keyPrefix: LOCALE_SUBSCRIPTIONS })
	const [redirectedToToMinDate, setRedirectedToMinDate] = useState<moment>(moment())
	const [initialValues, setInitialValues] = useState<SubscriptionRedirectFormValues>(EMPTY_FORM)
	const [minRedirectDate, setMinRedirectDate] = useState<moment>(moment(subscriptionStartDate))

	const customer = useSelector((state: Store) => state.currentUser?.userInfo?.customer)

	useEffect((): void => {
		setInitialValues({
			subscriptionId,
			subscriptionRecipientName: name,
			subscriptionRecipientSurname: surname,
			subscriptionRecipientCompanyName: companyName,
			subscriptionDeliveryDate: delivery?.appliedTo ?? null,
		})
		SubscriptionService.getMinimalStartDate()
			.then((startDate: moment): void => {
				setMinRedirectDate(startDate)
			})
			.catch((error: Error): void => {
				NotificationManager.error(error.message, t('search.fail'), NOTIFICATION_SHOW_TIME_MS)
			})
	}, [subscriptionId, name, surname, companyName, delivery, t])

	return (
		<div className="subscription-redirect-form">
			<Form
				onSubmit={onSubmitWithCustomer(customer)}
				validate={(values) => validate(values, minRedirectDate)}
				initialValues={initialValues}
				render={({ handleSubmit }: FormRenderProps<SubscriptionRedirectFormValues>): Element<*> => (
					<form onSubmit={handleSubmit}>
						<TitleLine title={t('redirect.period')} />
						<div className="mb-2 dates">
							<Field
								name={formFields.REDIRECTED_FROM}
								component={DatePickerView}
								minDate={moment.max(minRedirectDate, moment(subscriptionStartDate))}
							/>
							<OnChange name={formFields.REDIRECTED_FROM}>
								{(date: moment): void => {
									setRedirectedToMinDate(moment(date) || moment())
								}}
							</OnChange>
							<div className="dash">&mdash;</div>
							<Field
								name={formFields.REDIRECTED_TO}
								component={DatePickerView}
								minDate={redirectedToToMinDate}
							/>
						</div>
						<TitleLine title={t('redirect.recipient')} />
						<div>
							<Field
								name={formFields.SUBSCRIPTION_RECIPIENT_NAME}
								component={renderTextField}
								placeholder={t('redirect.label.name')}
							/>
						</div>
						<div>
							<Field
								name={formFields.SUBSCRIPTION_RECIPIENT_SURNAME}
								component={renderTextField}
								placeholder={t('redirect.label.surname')}
							/>
						</div>
						<div>
							<Field
								name={formFields.SUBSCRIPTION_RECIPIENT_COMPANY_NAME}
								component={renderTextField}
								placeholder={t('redirect.label.company')}
							/>
						</div>
						<hr />
						<div>
							<div className="address-box">
								<Field
									formControlClassName="form-control"
									className="field"
									name={formFields.DESTINATION}
									component={AutoSuggestAddress}
								/>
								<OnChange name={formFields.DESTINATION}>
									{(addressOption: AddressOption): void => {
										if (addressOption) {
											handleAddressChange(this)
										}
									}}
								</OnChange>
							</div>
						</div>
						<div>
							<Field
								name={formFields.PLACE_NAME}
								component={renderTextField}
								placeholder={t('redirect.label.place_name')}
							/>
						</div>
						<div>
							<Field
								name={formFields.HOUSE_NUMBER}
								component={renderTextField}
								placeholder={t('redirect.label.house_number')}
							/>
						</div>
						<div>
							<Field
								name={formFields.APARTMENT}
								component={renderTextField}
								placeholder={t('redirect.label.apartment')}
							/>
						</div>
						<div>
							<Field
								name={formFields.ZIP}
								component={renderTextField}
								placeholder={t('redirect.label.zip')}
							/>
						</div>
						<hr />
						<div className="form-buttons">
							<button className="btn submit-button" type="submit">
								{t('redirect.submit')}
							</button>
						</div>
					</form>
				)}
			/>
		</div>
	)
}

export default SubscriptionRedirectForm
