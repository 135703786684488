// @flow
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import moment from 'moment'

import { SubscriptionService } from 'services'
import { onSubmitWithCustomer } from 'views/Subscription/Cancel/SubscriptionCancelActions'
import DatePickerView from 'components/Form/DatePicker'
import { renderTextField } from 'components/Form/redux-material-ui/FormFieldBridges'

import { LOCALE_NAMESPACE_SUBSCRIPTIONS, LOCALE_SUBSCRIPTIONS } from 'constants/locale'
import * as formFields from 'constants/subscription/form/SubscriptionCancelForm'
import { OMNIVA_CUSTOMER } from 'constants/customer'
import { useSelector } from 'react-redux'
import './SubscriptionCancelForm.scss'

import type { FormRenderProps } from 'react-final-form'
import type { Element } from 'react'
import type { Translator } from 'helpers'
import type { SubscriptionCancelFormValues } from 'types/subscription/form/SubscriptionCancelForm'
import type { Store } from 'types/Store'

import validate from './SubscriptionCancelFormValidator'

const NOTIFICATION_SHOW_TIME_MS: number = 10000

export type SubscriptionCancelFormProps = {
	subscriptionStartDate: ?string,
	deliveryEndDate: ?string,
	subscriptionId: ?number,
}

function SubscriptionCancelForm({
	subscriptionStartDate,
	deliveryEndDate,
	subscriptionId,
}: SubscriptionCancelFormProps): Element<*> {
	const { t }: { t: Translator } = useTranslation(LOCALE_NAMESPACE_SUBSCRIPTIONS, { keyPrefix: LOCALE_SUBSCRIPTIONS })
	const [minCancelDate, setMinCancelDate] = useState<moment>(moment(subscriptionStartDate))
	const customer = useSelector((state: Store) => state.currentUser?.userInfo?.customer)

	useEffect((): void => {
		SubscriptionService.getMinimalStartDate()
			.then((startDate: moment): void => {
				setMinCancelDate(startDate)
			})
			.catch((error: Error): void => {
				NotificationManager.error(error.message, t('search.fail'), NOTIFICATION_SHOW_TIME_MS)
			})
	}, [t])

	return (
		<div className="subscription-cancel-form">
			<Form
				onSubmit={onSubmitWithCustomer(customer)}
				validate={validate}
				initialValues={{ subscriptionId }}
				render={({ handleSubmit, values }: FormRenderProps<SubscriptionCancelFormValues>): Element<*> => (
					<form onSubmit={handleSubmit}>
						<div className="mb-2 date">
							<Field
								name={formFields.CANCELLED_FROM}
								component={DatePickerView}
								minDate={moment.max(minCancelDate, moment(subscriptionStartDate))}
								maxDate={moment(deliveryEndDate)}
							/>
						</div>
						{((values?.applicationRegistrant?.customer &&
							values?.applicationRegistrant?.customer?.cmsId === OMNIVA_CUSTOMER.cmsId) ||
							(values?.applicationRegistrant?.servicePoint &&
								values?.applicationRegistrant?.servicePoint?.typeCode === 'POST_OFFICE')) && (
							<Field
								name={formFields.RETURN_PAYMENT}
								className="mb-2 half-width"
								component={renderTextField}
								placeholder={t('cancel.label.return_payment')}
							/>
						)}
						<div className="form-buttons">
							<button className="btn submit-button" type="submit">
								{t('cancel.submit')}
							</button>
						</div>
					</form>
				)}
			/>
		</div>
	)
}

export default SubscriptionCancelForm
