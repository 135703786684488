// @flow
import { get } from 'lodash/object'
import { NotificationManager } from 'react-notifications'
import { LOCALE_NAMESPACE_SUBSCRIPTIONS, LOCALE_SUBSCRIPTIONS } from 'constants/locale'

import type { FormApi } from 'final-form'
import type { AddressDto, AddressOption } from 'types/address'
import type { SubscriptionRedirectFormValues } from 'types/subscription/form/SubscriptionRedirectForm'
import type { Destination, SubscriptionIdResponse, SubscriptionRedirectRequest } from 'types/subscription/Subscription'
import type { Try } from 'types/Try'
import { getError, getValue, isSuccess } from 'types/Try'

import { SubscriptionRestService } from 'services'
import { browserHistory } from 'browserHistory'
import { getSubscriptionsDetailUrl } from 'Paths'
import { parsePostalCode } from 'helpers/converter/subscriptionPostalCodeExtractor'
import * as formFields from 'constants/subscription/form/SubscriptionRedirectForm'
import { t } from 'helpers'
import type { CustomerInfo } from 'types/auth'

const NOTIFICATION_SHOW_TIME_MS: number = 10000
const POST_OFFICE: string = 'POST_OFFICE'
const POST_STATION: string = 'POST_STATION'
const PERSONAL_BOX: string = 'PERSONAL_BOX'

const isOnDemand = (typeCode: ?string): boolean => typeCode === POST_OFFICE || typeCode === POST_STATION

export function handleAddressChange(form: FormApi<SubscriptionRedirectFormValues>): Function {
	return async (address: AddressDto): Promise<void> => {
		form.change(formFields.DESTINATION, address)
	}
}

export const onSubmitWithCustomer = (customer: ?CustomerInfo) => (formValues: SubscriptionRedirectFormValues) =>
	saveRedirect(formValues, customer)

async function saveRedirect(
	formValues: SubscriptionRedirectFormValues,
	customer: ?CustomerInfo
): Promise<SubscriptionIdResponse> {
	const request: SubscriptionRedirectRequest = {
		subscriptionId: formValues.subscriptionId,
		registrantCustomer: {
			cmsId: customer?.customerId,
			name: customer?.name,
			code: customer?.regNumber,
		},
		appliedFrom: get(formValues, formFields.REDIRECTED_FROM),
		appliedTo: get(formValues, formFields.REDIRECTED_TO),
		recipient: {
			name: get(formValues, formFields.SUBSCRIPTION_RECIPIENT_NAME),
			surname: get(formValues, formFields.SUBSCRIPTION_RECIPIENT_SURNAME),
			companyName: get(formValues, formFields.SUBSCRIPTION_RECIPIENT_COMPANY_NAME),
		},
		destination: parseDestination(formValues),
		dataSource: 'EXT_UI',
	}

	const response: Try<SubscriptionIdResponse> = await SubscriptionRestService.redirectSubscription(request)
	if (isSuccess(response)) {
		// in order to reload same page without the need to refresh
		browserHistory.replace('/empty')
		setTimeout(() => {
			browserHistory.replace(getSubscriptionsDetailUrl(response.id))
		}, 10)
		NotificationManager.success(
			t('redirect.success', null, LOCALE_SUBSCRIPTIONS, LOCALE_NAMESPACE_SUBSCRIPTIONS),
			null,
			NOTIFICATION_SHOW_TIME_MS
		)
		return getValue(response)
	} else {
		NotificationManager.error(
			t('redirect.fail', null, LOCALE_SUBSCRIPTIONS, LOCALE_NAMESPACE_SUBSCRIPTIONS),
			null,
			NOTIFICATION_SHOW_TIME_MS
		)
		throw getError(response)
	}
}

function parseDestination(formValues: SubscriptionRedirectFormValues): Destination {
	const destination: AddressOption = get(formValues, formFields.DESTINATION)
	const address: AddressDto = destination.address
	let nameboxNumber = null
	if (destination.typeCode === PERSONAL_BOX) {
		const r = /\d+/g
		const numberString = destination.label.match(r)
		if (numberString && numberString.length > 0) {
			nameboxNumber = Number(numberString[0])
		}
	}
	return {
		rmaId: address.rmaId,
		county: address.county,
		countyCode: address.countyCode,
		municipality: address.municipality,
		municipalityCode: address.municipalityCode,
		settlement: address.settlement,
		settlementCode: address.settlementCode,
		street: address.street,
		streetCode: address.streetCode,
		houseNumber: address.houseNumber ? address.houseNumber : get(formValues, formFields.HOUSE_NUMBER),
		apartmentNumber: address.apartmentNumber ? address.apartmentNumber : get(formValues, formFields.APARTMENT),
		zip: parsePostalCode(destination, get(formValues, formFields.ZIP)),
		psnId: destination.id,
		fullAddress: !destination.typeCode ? destination.label : null,
		hubName: destination.typeCode ? destination.label : null,
		onDemand: isOnDemand(destination.typeCode),
		nameboxNr: nameboxNumber,
		isFullRma: !address.partial,
		placeName: address.placeName ? address.placeName : get(formValues, formFields.PLACE_NAME),
	}
}
