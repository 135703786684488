// @flow
import { connect } from 'react-redux'

import { getCurrentUser } from 'shared/current-user'

import type { Location } from 'react-router'
import type { Store, Dispatch } from 'types/Store'
import type { UserInfo } from 'types/auth'

import WorkspaceView from './WorkspaceView'

import type { WorkspaceViewProps } from './WorkspaceView'

type OwnProps = {|
	location: Location,
|}

type StateProps = {|
	userInfo: ?UserInfo
|}

type DispatchProps = null

const mapStateToProps = (state: Store): StateProps => ({
	userInfo: getCurrentUser(state),
})

const mapDispatchToProps: DispatchProps = null

export default connect<WorkspaceViewProps, OwnProps, StateProps, DispatchProps, Store, Dispatch>(mapStateToProps, mapDispatchToProps)(WorkspaceView)