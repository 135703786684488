// @flow
import { stringify } from 'query-string'

import { ProductRestService } from 'services'
import { PageRequest, SortRequest } from 'helpers'

import type { ProductDto, ProductFilters, ProductSort } from 'types/product'
import type { Try } from 'types/Try'
import type { Pageable } from 'types/Pageable'
import type { ProductFilterOption, ProductFiltersRequest } from 'types/product/Product'

import { COLUMN } from 'constants/product/Product'

export function getAllProducts(
	page: number,
	rowsPerPage: number,
	filters: ProductFilters,
	sort: ?ProductSort
): Promise<Try<Pageable<ProductDto>>> {
	return ProductRestService.getAllProducts(buildPageRequest(sort, page, rowsPerPage), buildSearchCriteria(filters))
}

export function getAllProductsWithPackages(
	page: number,
	rowsPerPage: number,
	filters: ProductFilters,
	sort: ?ProductSort
): Promise<Try<Pageable<ProductDto>>> {
	return ProductRestService.getAllProductsWithPackages(buildPageRequest(sort, page, rowsPerPage), buildSearchCriteria(filters))
}

const buildSearchCriteria = (filters: ProductFilters): string => {
	const requestParams = filtersToRequestParams(filters)
	return stringify(requestParams)
}

const buildPageRequest = (sort?: ?ProductSort, page: number, rowsPerPage: number): PageRequest =>
	new PageRequest(page, rowsPerPage, nullableSort(sort))

const nullableSort = (sort?: ?ProductSort): ?SortRequest =>
	sort ? new SortRequest(COLUMN_TO_SORT_NAME[sort.name] || sort.name, sort.direction) : null

const COLUMN_TO_SORT_NAME: { [string]: string } = Object.freeze({
	[COLUMN.VALID_PERIOD]: 'servicesProvidedFrom',
	[COLUMN.PRODUCT_NAME_AND_CODE]: 'name',
	[COLUMN.SUBSCRIPTIONS_MEDIATION_SERVICE]: 'isSubscriptionsMediationService',
	[COLUMN.DELIVERY_SERVICE]: 'isDeliveryService',
})

const filtersToRequestParams = (filters: ProductFilters): ProductFiltersRequest => ({
	name: typeof filters.name?.value === 'string' ? filters.name?.value : filters.name?.toString(),
	code: filters.code,
	codeList: filters.codeList?.map((code: ProductFilterOption) => code.value),
	customerId: filters.customerId || filters.customer?.customer.cmsId.toString(),
	servicesProvidedFrom: filters.servicesProvidedFrom,
	servicesProvidedTo: filters.servicesProvidedTo,
	type: filters.type,
	registerStatus: filters.registerStatus,
	category: filters.category,
	publishingFrequencyCategory: filters.publishingFrequencyCategory,
	isSubscriptionsMediationService: filters.isSubscriptionsMediationService,
	isDeliveryService: filters.isDeliveryService,
	subscriptionsManager: filters.subscriptionsManager,
})
