// @flow

import { get, isEmpty, isString } from 'lodash'
import moment from 'moment'

import * as formFields from 'constants/subscription/form/SubscriptionRedirectForm'
import { addError, t, Validators } from 'helpers'
import { isDate } from 'helpers/form-validation/Validators'
import { DEFAULT_DATE_FORMAT } from 'constants/datetime'

import type { SubscriptionRedirectFormValues } from 'types/subscription/form/SubscriptionRedirectForm'

const REQUIRED_FIELDS: Set<string> = new Set([formFields.REDIRECTED_FROM, formFields.DESTINATION])

function validate(values: SubscriptionRedirectFormValues, validStartDate: moment): Object {
	let errors: Object = {}

	for (const requiredField of REQUIRED_FIELDS) {
		errors = addError(errors, requiredField, Validators.required(get(values, requiredField, null)))
	}

	errors = addError(
		errors,
		formFields.REDIRECTED_FROM,
		isEnoughWorkdaysBeforeDate(validStartDate, values[formFields.REDIRECTED_FROM])
	)

	errors = addError(
		errors,
		formFields.REDIRECTED_FROM,
		Validators.isDateAfter(values[formFields.SUBSCRIPTION_START_DATE], values[formFields.REDIRECTED_FROM])
	)

	if (isBlank(values[formFields.SUBSCRIPTION_RECIPIENT_COMPANY_NAME])) {
		errors = addError(
			errors,
			formFields.SUBSCRIPTION_RECIPIENT_NAME,
			Validators.required(values[formFields.SUBSCRIPTION_RECIPIENT_NAME])
		)
		errors = addError(
			errors,
			formFields.SUBSCRIPTION_RECIPIENT_SURNAME,
			Validators.required(values[formFields.SUBSCRIPTION_RECIPIENT_SURNAME])
		)
	}

	if (
		isBlank(values[formFields.SUBSCRIPTION_RECIPIENT_NAME]) &&
		isBlank(values[formFields.SUBSCRIPTION_RECIPIENT_SURNAME])
	) {
		errors = addError(
			errors,
			formFields.SUBSCRIPTION_RECIPIENT_COMPANY_NAME,
			Validators.required(values[formFields.SUBSCRIPTION_RECIPIENT_COMPANY_NAME])
		)
	}

	errors = addError(
		errors,
		formFields.REDIRECTED_TO,
		Validators.isDateAfter(values[formFields.REDIRECTED_TO], values[formFields.REDIRECTED_FROM])
	)

	errors = addError(
		errors,
		formFields.REDIRECTED_FROM,
		Validators.isDateAfter(
			values[formFields.SUBSCRIPTION_DELIVERY_DATE],
			values[formFields.REDIRECTED_FROM],
			'validation.input.is_start_date_after_delivery'
		)
	)

	errors = addError(
		errors,
		formFields.REDIRECTED_TO,
		Validators.isDateAfter(
			values[formFields.SUBSCRIPTION_DELIVERY_DATE],
			values[formFields.REDIRECTED_TO],
			'validation.input.is_end_date_after_delivery'
		)
	)

	return errors
}

const isEnoughWorkdaysBeforeDate = (
	validStartDate: moment,
	value: any,
	originalDate: moment = moment(),
	messageKey: string = 'validation.input.date_weekday_before'
) => {
	if (value == null || !!isDate(value)) {
		return undefined
	}

	const day = moment(value).startOf('day')
	const firstAvailableDay = moment(validStartDate).startOf('day')
	return day.isBefore(firstAvailableDay)
		? t(messageKey, { allowedDate: moment(firstAvailableDay).format(DEFAULT_DATE_FORMAT) })
		: undefined
}

function isBlank(str: any): boolean {
	return str == null || (isString(str) && isEmpty(str.trim()))
}

export default validate
