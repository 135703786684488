// @flow
import EastSharpIcon from '@mui/icons-material/EastSharp'
import WestSharpIcon from '@mui/icons-material/WestSharp'
import { FormControl, Grid, Pagination, PaginationItem } from '@mui/material'

import PageSelect from './PageSelect'
import GridPagesDetails from './GridPagesDetails'

import type { CustomFooterProps } from './CustomFooter'
import './CustomFooter.scss'

const getPaginationItem = (item) => (
	<PaginationItem
		className="pagination-item"
		{...item}
		slots={{
			previous: WestSharpIcon,
			next: EastSharpIcon,
		}}
		sx={{
			'&.Mui-selected': {
				backgroundColor: 'white',
			},
		}}
	/>
)

const AccordionFooter = ({
	rowsPerPage,
	page,
	totalElements,
	rowsPerPageOptions,
	changeRowsPerPage,
	changePage,
}: CustomFooterProps) => {
	const handlePageChange = (_, newPage: number): void => {
		changePage(newPage)
	}

	const handleRowChange = (newRowsPerPage: number): void => {
		changeRowsPerPage(newRowsPerPage)
	}

	const sx = { m: 2, minWidth: 100 }

	return (
		<Grid container justify="flex-start" alignItems="center">
			<Grid item xs>
				<FormControl className="grid-page-details" sx={sx}>
					<GridPagesDetails page={page} rowsPerPage={rowsPerPage} totalElements={totalElements} />
				</FormControl>
			</Grid>
			<Grid item>
				<FormControl className="custom-pagination" sx={sx}>
					<Pagination
						renderItem={getPaginationItem}
						onChange={handlePageChange}
						page={page}
						count={Math.ceil(totalElements / rowsPerPage)}
						shape="rounded"
					/>
				</FormControl>
			</Grid>
			<Grid item xs>
				<FormControl variant="filled" className="page-select" sx={sx}>
					<PageSelect
						onChange={handleRowChange}
						rowsPerPageOptions={rowsPerPageOptions}
						rowsPerPage={rowsPerPage}
					/>
				</FormControl>
			</Grid>
		</Grid>
	)
}

export default AccordionFooter
