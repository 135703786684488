// @flow
import {
	TextField,
	Select,
	FormControl,
	InputLabel,
	Checkbox,
	FormControlLabel,
	FormHelperText,
} from '@mui/material'

import type { AbstractComponent, Element } from 'react'
import type { FieldRenderProps } from 'react-final-form'

export const renderTextField = ({ input, meta: { touched, submitFailed, error }, ...custom }: FieldRenderProps): React$Element<typeof TextField> => (
	// $FlowIgnore[cannot-spread-inexact]
	<TextField
		error={error && (touched || submitFailed)}
		helperText={(touched || submitFailed) && error}
		{...input}
		{...custom}
		value={input.value || ''}
	/>
)

export const renderTextArea = ({ input, meta: { touched, submitFailed, error }, ...custom }: FieldRenderProps): React$Element<typeof TextField> => (
	// $FlowIgnore[cannot-spread-inexact]
	<TextField
		error={error && (touched || submitFailed)}
		multiline
		helperText={(touched || submitFailed) && error}
		{...input}
		{...custom}
		value={input.value || ''}
	/>
)

export const renderSelectField = ({
	input,
	meta: { touched, submitFailed, error },
	children,
	labelForId,
	label,
	formControlClassName,
	onChange,
	fieldSize,
	// $FlowIgnore[incompatible-use]
	...custom
}: FieldRenderProps & {
	children: any,
	labelForId: string,
	label: string,
	formControlClassName: string,
	onChange: (SyntheticInputEvent<*>) => void,
	fieldSize?: string
}) => (
	<FormControl className={formControlClassName} error={error && (touched || submitFailed)} size={fieldSize}>
		<InputLabel htmlFor={labelForId}>{label}</InputLabel>
		<Select
			label={label}
			onChange={onChange}
			{...input}
			{...custom}
		>
			{children}
		</Select>
		{(error && (touched || submitFailed)) ? (
			<FormHelperText>{(touched || submitFailed) && error}</FormHelperText>
		) : null}
	</FormControl>
)

// $FlowIgnore[incompatible-use]
export const renderCheckbox = ({ input, label, labelClassName, ...custom }: FieldRenderProps & { label: string, labelClassName?: string }) => (
	<FormControlLabel
		label={<span className={labelClassName || ''}>{label}</span>}
		control={<Checkbox
			checked={!!input.value}
			onChange={input.onChange}
			{...input}
			{...custom}
		/>}
	/>
)

export const renderSpan = ({ input, label, meta: { touched, submitFailed, error } }: FieldRenderProps & { label: string }) => (
	<FormControl error={error && (touched || submitFailed)}>
		<span>{`${label} ${input.value || ''}`}</span>
		{(error && (touched || submitFailed)) ? (
			<FormHelperText>{(touched || submitFailed) && error}</FormHelperText>
		) : null}
	</FormControl>
)

export function withAlwaysTouched(WrappedComponent: AbstractComponent<*>): AbstractComponent<*> {
	return function WithTouched(props: *): Element<*> {
		const { meta, ...restProps } = props
		const newMeta = {
			...meta,
			touched: true,
		}
		return <WrappedComponent meta={newMeta} {...restProps} />
	}
}

type ContainsFieldArrayMeta = {
	meta: {
		error: ?string,
		dirty: boolean,
		submitFailed: boolean,
	}
}

export function withErrorMessage<T>(WrappedComponent: AbstractComponent<T & ContainsFieldArrayMeta>): AbstractComponent<T & ContainsFieldArrayMeta> {
	return function WithErrorMessage(props: T & ContainsFieldArrayMeta): Element<*> {
		const { meta: { error, dirty, submitFailed } } = props
		return (
			<>
				<WrappedComponent {...props} />
				<FormControl error={error && (dirty || submitFailed)}>
					{(error && (dirty || submitFailed)) ? (
						<FormHelperText className="mt-2">{error}</FormHelperText>
					) : null}
				</FormControl>
			</>
		)
	}
}