// @flow
import type { Element } from 'react'
import { useState } from 'react'
import type { ContextRouter } from 'react-router-dom'
import { Link, Route } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { useTranslation } from 'react-i18next'
import { AppBar, Toolbar } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import AppsIcon from '@mui/icons-material/Apps'

import { LOCALE_COMMON, LOCALE_NAMESPACE_COMMON } from 'constants/locale'
import {
	getCustomerSelectsUrl,
	getSortingPlansUrl,
	getSubscriptionsCreateUrl,
	getSubscriptionsDetailUrl,
	getSubscriptionsUrl,
} from 'Paths'

import { LanguageSelect, UserAppBar } from 'components'
import Primary from 'views/Primary'
import Splash from 'views/Splash'
import { SubscriptionCreate, SubscriptionDetail, SubscriptionList } from 'views/Subscription'
import { SortingPlansListView } from 'views/SortingPlans'
// $FlowIssue Flow does not recognize this approach: https://stackoverflow.com/a/52605225/8217032
import { ReactComponent as MainLogo } from 'assets/images/omniva.svg'

import type { Location } from 'react-router'
import type { UserInfo } from 'types/auth'
import type { Translator } from 'helpers'

import { SortingPlansContextProvider } from '../SortingPlans/SortingPlansContext'
import './WorkspaceView.scss'

export type WorkspaceViewProps = {
	userInfo: ?UserInfo,
	location: Location,
}

const routeSubscriptionList = (): Element<*> => <SubscriptionList />

const routeSubscriptionCreateUrl = (): Element<*> => <SubscriptionCreate />

const routeSubscriptionDetailUrl = ({ match }: ContextRouter): Element<*> => (
	<SubscriptionDetail subscriptionId={match.params.subscriptionId} />
)

const sortingPlansView = () => (
	<SortingPlansContextProvider>
		<SortingPlansListView />
	</SortingPlansContextProvider>
)

const routing = (): Array<Element<*>> => [
	<Route exact key={getSubscriptionsUrl()} path={getSubscriptionsUrl()} render={routeSubscriptionList} />,
	<Route
		exact
		key={getSubscriptionsCreateUrl()}
		path={getSubscriptionsCreateUrl()}
		render={routeSubscriptionCreateUrl}
	/>,
	<Route
		exact
		key={getSubscriptionsDetailUrl()}
		path={getSubscriptionsDetailUrl()}
		render={routeSubscriptionDetailUrl}
	/>,
	<Route exact key={getSortingPlansUrl()} path={getSortingPlansUrl()} render={sortingPlansView} />,
]

function WorkspaceView({ userInfo, location }: WorkspaceViewProps): Element<*> {
	const { t }: { t: Translator } = useTranslation(LOCALE_NAMESPACE_COMMON, { keyPrefix: LOCALE_COMMON })

	const [showMenu, setShowMenu] = useState<boolean>(false)

	const revertOpen = (): void => setShowMenu(!showMenu)

	const renderWorkspace = (): Element<*> => (
		<div className="workspace-view">
			<AppBar position="sticky" className="app-bar" color="inherit">
				<Toolbar>
					<div className="toolbar-body">
						<div className="main-menu">
							<MenuIcon onClick={revertOpen} />
							<MainLogo className="logo" />
						</div>
						<div className="secondary-menu">
							<div className="flex-left ml-2">
								<AppsIcon className="logo" />
								<Link to={getCustomerSelectsUrl()}>
									<h4 className="workspace-title">{t('workspace.title')}</h4>
								</Link>
							</div>
							<div className="flex-right">
								<LanguageSelect drop="down" />
								<UserAppBar />
							</div>
						</div>
					</div>
				</Toolbar>
			</AppBar>
			<div className="content-container">
				<CSSTransition in={showMenu} timeout={300} classNames="primary-view" unmountOnExit>
					<Primary key="top" path={location.pathname} />
				</CSSTransition>
				<div className="content">{routing()}</div>
			</div>
		</div>
	)

	return userInfo?.customer ? renderWorkspace() : <Splash />
}

export default WorkspaceView
