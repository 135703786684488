// @flow
import { Route, Switch } from 'react-router-dom'
import { NotificationContainer } from 'react-notifications'

import { PATH_AUTH, PATH_CAS_REDIRECT, PATH_CUSTOMER, PATH_LOGIN, PATH_SPLASH } from 'Paths'

import type { Element } from 'react'

import Splash from 'views/Splash'
import Login from 'views/Login'
import Workspace from 'views/Workspace'
import Customer from 'views/Customer'
import CasRedirectView from 'views/CasRedirect'
import { CasAuth } from 'components'

import type { ContextRouter } from 'react-router-dom'

function Root(): Element<*> {
	return (
		<div onDragOver={handleDragOver}>
			<NotificationContainer />
			<Switch>
				<Route exact path={PATH_SPLASH} component={SplashComponent} />
				<Route exact path={PATH_LOGIN} component={Login} />
				<Route exact path={PATH_AUTH} component={CasAuth} />
				<Route exact path={PATH_CAS_REDIRECT} render={renderCasRedirect} />
				<Route exact path={PATH_CUSTOMER} component={Customer} />
				<Route component={Workspace} />
			</Switch>
		</div>
	)
}

function renderCasRedirect({ location }: ContextRouter): Element<*> {
	const urlParams: URLSearchParams = new URLSearchParams(location.search)
	return <CasRedirectView SAMLart={urlParams.get('SAMLart')} />
}

function handleDragOver(e: Object): void {
	e.preventDefault()
	e.stopPropagation()

	// block it!
	e.dataTransfer.dropEffect = 'none'
}

function SplashComponent(): Element<*> {
	return <Splash redirectToHomePage />
}

export default Root