// @flow
import { stringify } from 'query-string'
import { RestClient, PageRequest } from 'helpers'

import { API_URL } from 'Api'

import type { Try } from 'types/Try'
import type { ProductDto, ProductSaveRequest, ProductIdResponse } from 'types/product'
import type { Pageable } from 'types/Pageable'

const PRODUCTS_URL: string = `${API_URL}/products`

export async function getAllProducts(page: PageRequest, searchCriteria: string): Promise<Try<Pageable<ProductDto>>> {
	return RestClient.get(`${PRODUCTS_URL}?${searchCriteria}&${page.toString()}`)
}

export async function getAllProductsWithPackages(page: PageRequest, searchCriteria: string): Promise<Try<Pageable<ProductDto>>> {
	return RestClient.get(`${PRODUCTS_URL}/customer?${searchCriteria}&${page.toString()}`)
}

export async function getCustomerProducts(customerId: number): Promise<Try<Pageable<ProductDto>>> {
	return RestClient.get(`${PRODUCTS_URL}/customer/${customerId}?${PageRequest.asAllPagesRequest().toString()}`)
}

export async function getProductsByState(state: string): Promise<Try<Pageable<ProductDto>>> {
	return RestClient.get(`${PRODUCTS_URL}/state/${state}?${PageRequest.asAllPagesRequest().toString()}`)
}

export async function getAllProductsByState(state: string): Promise<Try<Pageable<ProductDto>>> {
	const requestParams = { registerStatus: state }
	return RestClient.get(`${PRODUCTS_URL}?${stringify(requestParams)}&${PageRequest.asAllPagesRequest().toString()}`)
}

export async function getProduct(productId: number): Promise<Try<ProductDto>> {
	return RestClient.get(`${PRODUCTS_URL}/${productId}`)
}

export async function saveProduct(request: ProductSaveRequest): Promise<Try<ProductIdResponse>> {
	return RestClient.post(`${PRODUCTS_URL}`, request)
}

export async function updateProduct(request: ProductSaveRequest): Promise<Try<ProductIdResponse>> {
	return RestClient.put(`${PRODUCTS_URL}`, request)
}
