// @flow
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { MenuItem, Select } from '@mui/material'
import './PageSelect.scss'

export type PageSelectProps = {|
	onChange: (number) => void,
	rowsPerPageOptions: Array<number>,
	rowsPerPage: number
|}

const PageSelect = ({ onChange, rowsPerPageOptions, rowsPerPage }: PageSelectProps) => {
	const rowsParPageMenuItems = rowsPerPageOptions.map((rowsPerPageOption) => (
		<MenuItem style={{ fontSize: 14, fontFamily: 'TT Wellingtons, sans-serif' }} key={rowsPerPageOption} value={rowsPerPageOption}>
			{rowsPerPageOption}
		</MenuItem>
	))

	return (
		<Select
			variant="outlined"
			className="page-select"
			IconComponent={KeyboardArrowDownIcon}
			value={rowsPerPage}
			onChange={(e) => onChange(e.target.value)}
		>
			{rowsParPageMenuItems}
		</Select>
	)
}

export default PageSelect