// @flow
import { Typography } from '@mui/material'

import type { Element } from 'react'
import type { UserInfo } from 'types/auth'

import { Logout } from 'components'

import './UserAppBarView.scss'

export type InitialsIconViewProps = {|
	userInfo: ?UserInfo,
|}

function UserAppBarView({ userInfo }: InitialsIconViewProps): Element<*> {
	const firstName: string = userInfo?.firstName || '???'
	const lastName: string = userInfo?.lastName || '???'
	const customerName: string = userInfo?.customer?.name || '???'
	return (
		<div className="avatar-container">
			<Typography variant="subtitle2" className="user-name">{firstName} {lastName}</Typography>
			<Typography variant="subtitle2" className="user-name">{customerName}</Typography>
			<Logout />
		</div>
	)
}

export default UserAppBarView