// @flow
import { connect } from 'react-redux'

import { getCurrentUser } from 'shared/current-user'

import type { Store } from 'types/Store'
import type { UserInfo } from 'types/auth'
import type { InitialsIconViewProps } from 'components/UserAppBar/UserAppBarView'

import UserAppBarView from 'components/UserAppBar/UserAppBarView'

type StateProps = {|
	userInfo: ?UserInfo,
|}

type DispatchProps = null

const mapStateToProps = (state: Store): StateProps => ({
	userInfo: getCurrentUser(state),
})

const mapDispatchToProps: DispatchProps = null

export default connect<InitialsIconViewProps, any, _, _, _, _>(mapStateToProps, mapDispatchToProps)(UserAppBarView)