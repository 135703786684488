// @flow
export const REDIRECTED_FROM: string = 'redirectedFrom'
export const REDIRECTED_TO: string = 'redirectedTo'
export const SUBSCRIPTION_RECIPIENT_NAME: string = 'subscriptionRecipientName'
export const SUBSCRIPTION_RECIPIENT_SURNAME: string = 'subscriptionRecipientSurname'
export const SUBSCRIPTION_RECIPIENT_COMPANY_NAME: string = 'subscriptionRecipientCompanyName'
export const SUBSCRIPTION_DESTINATION: string = 'subscriptionDestination'
export const DESTINATION: string = 'destination'
export const PLACE_NAME: string = 'placeName'
export const HOUSE_NUMBER: string = 'houseNumber'
export const APARTMENT: string = 'apartment'
export const ZIP: string = 'zip'
export const APPLICATION_REGISTRANT: string = 'applicationRegistrant'
export const SUBSCRIPTION_START_DATE: string = 'subscriptionStartDate'
export const SUBSCRIPTION_DELIVERY_DATE: string = 'subscriptionDeliveryDate'