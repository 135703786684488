// @flow
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import without from 'lodash/without'
import uniq from 'lodash/uniq'

import type { ProductDto, RelatedPackage } from 'types/product'

import { useSortingPlansContext } from '../SortingPlansContext'

type Props = {|
	products: ProductDto[],
	disabled?: boolean,
|}

const packageCodesFromProducts = (value: RelatedPackage) => value.code

const singlePredicate = ({ category }) => category === 'SINGLE'

export const AccordionView = ({ products, disabled = false }: Props) => {
	const { state, dispatch } = useSortingPlansContext()

	const handleCheckBoxChange = (productCode: string, packageCodes: Array<string>) => (event) => {
		const currentPackages = state.selectedPackagesCodes[productCode] ?? []
		if (event.target.checked) {
			dispatch({
				type: 'setSelectedProductsIds',
				payload: [...state.selectedProductsCodes, productCode],
			})
			dispatch({
				type: 'setSelectedPackagesIds',
				payload: {
					[productCode]: uniq([...currentPackages, ...packageCodes]),
				},
			})
		} else {
			dispatch({
				type: 'setSelectedProductsIds',
				payload: without(state.selectedProductsCodes, productCode),
			})
			dispatch({
				type: 'removeProductIdFromSelectedPackages',
				payload: productCode,
			})
		}
	}

	const handlePackageCheck = (code: string, productCode: string) => (event) => {
		const currentPackages = state.selectedPackagesCodes[productCode] ?? []
		if (event.target.checked) {
			dispatch({
				type: 'setSelectedPackagesIds',
				payload: {
					[productCode]: uniq([...currentPackages, code]),
				},
			})
		} else {
			dispatch({
				type: 'setSelectedPackagesIds',
				payload: {
					[productCode]: without(currentPackages, code),
				},
			})
		}
	}

	return (
		<div>
			{products.filter(singlePredicate).map((product: ProductDto) => (
				<Accordion key={product.code} disabled={disabled} square>
					<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${product.name}-control`}>
						<Typography>
							<FormControlLabel
								onClick={(event) => event.stopPropagation()}
								control={
									<Checkbox
										onChange={handleCheckBoxChange(
											product.code,
											product.relatedPackages?.map(packageCodesFromProducts) || []
										)}
									/>
								}
								label={`${product.code} - ${product.name}`}
							/>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<FormGroup>
							{product.relatedPackages?.map(({ name, code }) => {
								const checked = (state.selectedPackagesCodes[product.code] ?? []).includes(code)
								return (
									<FormControlLabel
										className="mx-2"
										key={code}
										control={
											<Checkbox
												disabled={disabled}
												checked={checked}
												onChange={handlePackageCheck(code, product.code)}
											/>
										}
										label={`${code} - ${name}`}
									/>
								)
							})}
						</FormGroup>
					</AccordionDetails>
				</Accordion>
			))}
		</div>
	)
}
