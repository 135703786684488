// @flow
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import { Popover } from '@mui/material'

import SubscriptionSuspendForm from 'views/Subscription/Suspend/Form/SubscriptionSuspendForm'

import { LOCALE_NAMESPACE_SUBSCRIPTIONS, LOCALE_SUBSCRIPTIONS } from 'constants/locale'

import type { Translator } from 'helpers'
import type { DeliveryDto } from 'types/subscription/Subscription'

import './SubscriptionSuspendView.scss'

export type SubscriptionSuspendProps = {
	open: boolean,
	setOpen: (boolean) => void,
	subscriptionStartDate: ?string,
	subscriptionId: ?number,
	delivery: ?DeliveryDto,
}

function SubscriptionSuspendView({ open, setOpen, subscriptionStartDate, subscriptionId, delivery }: SubscriptionSuspendProps) {
	const { t }: { t: Translator } = useTranslation(LOCALE_NAMESPACE_SUBSCRIPTIONS, { keyPrefix: LOCALE_SUBSCRIPTIONS })

	return (
		<Popover
			className="suspend-popover"
			open={open}
			onClose={(): void => {
				setOpen(false)
			}}
			anchorReference="anchorPosition"
			anchorPosition={{ top: 0, left: window.innerWidth }}
			marginThreshold={0}
		>
			<div className="top">
				<h1 className="suspend-heading">{t('suspend.title')}</h1>
				<button
					className="close-button"
					type="button"
					onClick={(): void => {
						setOpen(false)
					}}
				>
					<CloseIcon />
				</button>
			</div>
			<SubscriptionSuspendForm
				subscriptionStartDate={subscriptionStartDate}
				subscriptionId={subscriptionId}
				delivery={delivery}
			/>
		</Popover>
	)
}

export default SubscriptionSuspendView