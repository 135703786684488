// @flow

export const PRODUCT_REGISTER_STATUS: * = Object.freeze({
	ACTIVE: 'ACTIVE',
	ON_HOLD: 'ON_HOLD',
	INACTIVE: 'INACTIVE',
})

export const PRODUCT_TYPE: * = Object.freeze({
	NEWSPAPER: 'NEWSPAPER',
	WEEKLY: 'WEEKLY',
	MAGAZINE: 'MAGAZINE',
	REGIONAL_NEWSPAPER: 'REGIONAL_NEWSPAPER',
})

export const PUBLISHING_FREQUENCY_CATEGORY: * = Object.freeze({
	N0: 'N0',
	N1: 'N1',
	N2: 'N2',
	N5: 'N5',
})

export const CATEGORY: * = Object.freeze({
	SINGLE: 'SINGLE',
	PACKAGE: 'PACKAGE',
})

export const SUBSCRIPTION_MANAGER: * = Object.freeze({
	AS_EXPRESS_POST: 'AS_EXPRESS_POST',
	AS_EESTI_POST: 'AS_EESTI_POST',
})

export const LANGUAGE: * = Object.freeze({
	ENG: 'ENG',
	EST: 'EST',
	GER: 'GER',
	RUS: 'RUS',
	OTHER: 'OTHER',
})

/**
 * Values should be the same as field names in type {@link ProductDto}.
 */
export const COLUMN: * = Object.freeze({
	PRODUCT_NAME_AND_CODE: 'productNameAndCode',
	CUSTOMER_NAME: 'customerName',
	TYPE: 'type',
	VALID_PERIOD: 'validPeriod',
	PUBLISHING_FREQUENCY_CATEGORY: 'publishingFrequencyCategory',
	REGISTER_STATUS: 'registerStatus',
	SUBSCRIPTIONS_MEDIATION_SERVICE: 'subscriptionsMediationService',
	DELIVERY_SERVICE: 'deliveryService',
	SUBSCRIPTIONS_MANAGER: 'subscriptionsManager',
	CATEGORY: 'category',
})

export const VAT_RATE: * = Object.freeze({
	BASE: 'BASE',
	BOOKS: 'BOOKS',
	PERIODICALS: 'PERIODICALS',
})

export const PERIOD_UNIT: * = Object.freeze({
	MONTH: 'MONTH',
	YEAR: 'YEAR',
})

export const PUBLISHING_FREQUENCY_UNIT: * = Object.freeze({
	WEEK: 'WEEK',
	MONTH: 'MONTH',
	YEAR: 'YEAR',
})

export const PUBLISHING_DAYS_OF_ISSUING: * = Object.freeze({
	MONDAY: 'MONDAY',
	TUESDAY: 'TUESDAY',
	WEDNESDAY: 'WEDNESDAY',
	THURSDAY: 'THURSDAY',
	FRIDAY: 'FRIDAY',
	SATURDAY: 'SATURDAY',
})

export const PRODUCT_SORTED_BY: * = Object.freeze({
	AS_EESTI_POST: 'AS EESTI POST',
	KROONPRESS: 'KROONPRESS',
	LEHEPUNKT: 'LEHEPUNKT',
	PUBLISHER: 'PUBLISHER',
})

export const PRODUCT_TRANSPORTED_BY: * = Object.freeze({
	AS_EESTI_POST: 'AS EESTI POST',
	KROONPRESS: 'KROONPRESS',
	PUBLISHER: 'PUBLISHER',
})
