// @flow
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { ListItemText, ListItem } from '@mui/material'

import type { Element } from 'react'
import type { RoleEnum } from 'constants/auth/roles'

import { Permission } from 'components'

export type PrimaryViewListItemProps = {
	availableForRole: RoleEnum,
	linkTo: string,
	text: string,
	icon: Element<*>,
}

function PrimaryViewListItem({ availableForRole, linkTo, text, icon }: PrimaryViewListItemProps): Element<*> {
	function useLinkSelected(link: string): boolean {
		return useLocation().pathname === link
	}

	return (
		<Permission availableForRole={availableForRole}>
			<Link className="navigation-link" to={linkTo}>
				<ListItem button selected={useLinkSelected(linkTo)}>
					{icon}
					<ListItemText primary={text} className="ml-2" />
				</ListItem>
			</Link>
		</Permission>
	)
}

export default PrimaryViewListItem