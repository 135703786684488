// @flow
export const EN: string = 'en'
export const ET: string = 'et'
export const SUPPORTED_LANGUAGES = [ET, EN]

const LOCALE_PREFIX: string = 'com.omniva.phoenix'
export const LOCALE_VIEWS: string = `${LOCALE_PREFIX}.views`

export const LOCALE_NAMESPACE_COMMON: string = 'common'
export const LOCALE_COMMON: string = `${LOCALE_VIEWS}.${LOCALE_NAMESPACE_COMMON}`
export const LOCALE_COMMON_COMPONENTS: string = `${LOCALE_COMMON}.components`
export const LOCALE_COMMON_CONFIRMS: string = `${LOCALE_COMMON}.dialog.confirm`
export const LOCALE_COMMON_BREADCRUMB = `${LOCALE_COMMON}.breadcrumb`
export const LOCALE_LANGUAGE_SELECT: string = `${LOCALE_COMMON_COMPONENTS}.language_select`
export const LOCALE_MUI_DATA_TABLE: string = `${LOCALE_COMMON_COMPONENTS}.mui_data_table`
export const LOCALE_AUTO_SUGGEST_CUSTOMER: string = `${LOCALE_COMMON_COMPONENTS}.auto_suggest_customer`
export const LOCALE_AUTO_SUGGEST_ADDRESS: string = `${LOCALE_COMMON_COMPONENTS}.auto_suggest_address`
export const LOCALE_AUTO_SUGGEST_PRODUCTS: string = `${LOCALE_COMMON_COMPONENTS}.auto_suggest_product`
export const LOCALE_AUTO_SUGGEST_HUB_OR_CUSTOMER: string = `${LOCALE_COMMON_COMPONENTS}.auto_suggest_hub_or_customer`
export const LOCALE_AUTO_SUGGEST_HUB: string = `${LOCALE_COMMON_COMPONENTS}.auto_suggest_hub`

export const LOCALE_NAMESPACE_SUBSCRIPTIONS = 'subscriptions'
export const LOCALE_SUBSCRIPTIONS = `${LOCALE_VIEWS}.${LOCALE_NAMESPACE_SUBSCRIPTIONS}`
export const LOCALE_NAMESPACE_SUBSCRIPTIONS_FILTER: string = `${LOCALE_SUBSCRIPTIONS}.filter`
export const LOCALE_COMPONENT: string = `${LOCALE_PREFIX}.component`

export const LOCALE_NAMESPACE_PRODUCTS = 'products'

export const LOCALE_NAMESPACE_SORTING_PLANS = 'sortingPlans'
export const LOCALE_SORTING_PLANS = `${LOCALE_VIEWS}.${LOCALE_NAMESPACE_SORTING_PLANS}`
