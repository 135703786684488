// @flow

import noop from 'lodash/noop'
import { createContext, useContext, useReducer } from 'react'
import { sortingPlansStateReducer } from './reducer'

export type ProductCodes = Array<string>
export type Record<V, K> = {
	[key: V]: Array<K>,
}

export type SortingPlansContextState = {|
	selectedDate: string,
	selectedProductsCodes: ProductCodes,
	selectedPackagesCodes: Record<string, ProductCodes>,
|}

const defaultState: SortingPlansContextState = {
	selectedDate: '',
	selectedProductsCodes: [],
	selectedPackagesCodes: {},
}

interface SetSelectedDate {
	type: 'setCurrentDate';
	payload: string;
}

interface SetSelectedProductsIds {
	type: 'setSelectedProductsIds';
	payload: ProductCodes;
}

interface SetSelectedPackagesIds {
	type: 'setSelectedPackagesIds';
	payload: Record<string, ProductCodes>;
}

interface RemoveProductIdFromSelectedPackages {
	type: 'removeProductIdFromSelectedPackages';
	payload: string;
}

export type Action =
	| SetSelectedDate
	| SetSelectedProductsIds
	| SetSelectedPackagesIds
	| RemoveProductIdFromSelectedPackages

interface SortingPlansContextProps {
	state: SortingPlansContextState;
	dispatch: (Action) => void;
}

export const SortingPlansContext = createContext<SortingPlansContextProps>({
	state: defaultState,
	dispatch: noop,
})

type Props = {| children: any |}

export function SortingPlansContextProvider({ children }: Props) {
	const [state, dispatch] = useReducer(sortingPlansStateReducer, defaultState)

	const exposed: SortingPlansContextProps = {
		state,
		dispatch,
	}

	return <SortingPlansContext.Provider value={exposed}>{children}</SortingPlansContext.Provider>
}

export const useSortingPlansContext = () => useContext(SortingPlansContext)
