// @flow

import omit from 'lodash/omit'
import type { SortingPlansContextState, Action } from './SortingPlansContext'

export const sortingPlansStateReducer = (state: SortingPlansContextState, action: Action): SortingPlansContextState => {
	switch (action.type) {
		case 'setCurrentDate': {
			return { ...state, selectedDate: action.payload }
		}
		case 'setSelectedProductsIds': {
			return { ...state, selectedProductsCodes: action.payload }
		}
		case 'setSelectedPackagesIds': {
			const prevPackagesMap = state.selectedPackagesCodes
			return { ...state, selectedPackagesCodes: { ...prevPackagesMap, ...action.payload } }
		}
		case 'removeProductIdFromSelectedPackages': {
			const prevPackagesMap = state.selectedPackagesCodes
			return { ...state, selectedPackagesCodes: omit(prevPackagesMap, action.payload) }
		}
		default:
			return { ...state }
	}
}
