// @flow
import { createTheme, Switch, ThemeProvider } from '@mui/material'

import type { FieldRenderProps } from 'react-final-form'
import type { Element } from 'react'

import './FormSwitch.scss'

const theme = createTheme({
	palette: {
		primary: {
			main: '#ff6600',
		},
	},
})

function FormSwitch({ input }: FieldRenderProps): Element<*> {
	return (
		<div>
			<ThemeProvider theme={theme}>
				<Switch
					color="primary"
					className="switch"
					value={input.value}
					onChange={(value: boolean): void => {
						input.onChange(value)
					}}
					checked={input.checked}
				/>
			</ThemeProvider>
		</div>
	)
}

export default FormSwitch