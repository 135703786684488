// @flow
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { LOCALE_COMMON, LOCALE_NAMESPACE_COMMON } from 'constants/locale'

import type { Element } from 'react'
import type { Translator } from 'helpers'

import imgLoading from 'assets/images/loading.svg'

import './SplashView.scss'

export type SplashViewProps = {
	redirectToHomePage?: boolean,
	logIn: (redirectToHomePage: boolean) => void,
}

function SplashView({ redirectToHomePage, logIn }: SplashViewProps): Element<*> {
	const { t }: { t: Translator } = useTranslation(LOCALE_NAMESPACE_COMMON, { keyPrefix: LOCALE_COMMON })

	useEffect(() => {
		logIn(!!redirectToHomePage)
	}, [logIn, redirectToHomePage])

	return (
		<div className="splash-view">
			<div className="splash-view__container d-flex flex-column justify-content-center align-items-center">
				<div className="h2 splash-view__banner">
					<img src={imgLoading} alt="loading" />
					<span>{t('components.loading')}</span>
				</div>
			</div>
		</div>
	)
}

export default SplashView