// @flow
export const NAME: string = 'name'
export const SURNAME: string = 'surname'
export const COMPANY_NAME: string = 'companyName'
export const DESTINATION: string = 'destination'
export const PLACE_NAME: string = 'placeName'
export const HOUSE_NUMBER: string = 'houseNumber'
export const APARTMENT: string = 'apartment'
export const ZIP: string = 'zip'
export const SUBSCRIPTION_CODE: string = 'subscriptionCode'
export const PUBLICATION: string = 'publication'
export const QUANTITY: string = 'quantity'
export const PERIOD: string = 'period'
export const PERIOD_UNIT: string = 'periodUnit'
export const SUBSCRIPTION_START_DATE: string = 'subscriptionStartDate'
export const SUBSCRIPTION_END_DATE: string = 'subscriptionEndDate'
export const SUBSCRIPTION_COST: string = 'subscriptionCost'
export const RECEIPT_NUMBER: string = 'receiptNumber'
export const SUBSCRIBER_NAME: string = 'subscriberName'
export const SUBSCRIBER_SURNAME: string = 'subscriberSurname'
export const SUBSCRIBER_COMPANY_NAME: string = 'subscriberCompanyName'
export const REGISTER_CODE: string = 'registerCode'
export const SUBSCRIBER_PHONE: string = 'subscriberPhone'
export const SUBSCRIBER_EMAIL: string = 'subscriberEmail'
export const REGISTRANT: string = 'registrant'
export const CMS_ID: string = `${REGISTRANT}.cmsId`
export const CUSTOMER_NAME: string = `${REGISTRANT}.name`
export const CUSTOMER_CODE: string = `${REGISTRANT}.code`
export const CUSTOMER_ADDRESS: string = `${REGISTRANT}.address`
export const CUSTOMER_AX_CODE: string = `${REGISTRANT}.axCode`
export const REGISTRATION_DATE: string = 'registrationDate'
export const IS_SAME_AS_RECIPIENT: string = 'isSameAsRecipient'
export const IS_ADD_AND_NEW: string = 'isAddAndNew'
export const DESTINATION_TEXT: string = 'destinationText'
