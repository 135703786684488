// @flow
import { Grid } from '@mui/material'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// $FlowIssue Flow does not recognize this approach: https://stackoverflow.com/a/52605225/8217032
import { ReactComponent as Left } from 'assets/images/outline-arrow-left.svg'
import { Breadcrumbs } from 'components'
import { getSubscriptionsUrl } from 'Paths'

import { LOCALE_NAMESPACE_SUBSCRIPTIONS, LOCALE_SUBSCRIPTIONS } from 'constants/locale'

import type { Element } from 'react'
import type { Translator } from 'helpers'

import './SubscriptionCreateView.scss'
import SubscriptionForm from '../Form/SubscriptionForm'

function SubscriptionCreateView(): Element<*> {
	const { t }: { t: Translator } = useTranslation(LOCALE_NAMESPACE_SUBSCRIPTIONS, { keyPrefix: LOCALE_SUBSCRIPTIONS })

	return (
		<div className="subscription-create-view client-area">
			<Grid container justify="flex-start" alignItems="flex-start">
				<Grid className="mt-2" item xs={12}>
					<Breadcrumbs />
					<div className="title-row">
						<Link to={getSubscriptionsUrl()}>
							<Left className="left-arrow" />
						</Link>
						<h3>{t('form.title')}</h3>
					</div>
					<SubscriptionForm
						initialValues={{
							isSameAsRecipient: false,
						}}
						isNewSubscription
						checkFieldsResult={null}
					/>
				</Grid>
			</Grid>
		</div>
	)
}

export default SubscriptionCreateView