// @flow
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { OnChange } from 'react-final-form-listeners'

import { AutoSuggestCustomer } from 'components'
import TitleLine from 'components/Form/TitleLine'
import DatePickerView from 'components/Form/DatePicker'

import { LOCALE_NAMESPACE_SUBSCRIPTIONS, LOCALE_SUBSCRIPTIONS } from 'constants/locale'
import * as formFields from 'constants/subscription/form/SubscriptionForm'

import type { CustomerOption } from 'types/customer'
import type { SubscriptionFormValues } from 'types/subscription/form/SubscriptionForm'
import type { Translator } from 'helpers'
import type { FormApi } from 'final-form'

import { handleCustomerChange } from '../../SubscriptionCreateActions'

export type SubscriptionRegistrationDataProps = {|
	form: FormApi<SubscriptionFormValues>,
	isNewSubscription: boolean,
|}

function SubscriptionRegistrationDataSectionView({ form, isNewSubscription }: SubscriptionRegistrationDataProps) {
	const { t }: { t: Translator } = useTranslation(LOCALE_NAMESPACE_SUBSCRIPTIONS, { keyPrefix: LOCALE_SUBSCRIPTIONS })
	return (
		<div className="subscription-registration-data">
			<TitleLine title={t('form.subscription_registration')} />
			<div className="py-3">
				<label className="form-label">{t('form.registrant')}</label><span className="required-label">*</span>
				<Field
					disabled={!isNewSubscription}
					formControlClassName="form-control"
					className="field"
					name={formFields.REGISTRANT}
					component={AutoSuggestCustomer}
				/>
				<OnChange name={formFields.REGISTRANT}>
					{(customerOption: CustomerOption): void => {
						if (customerOption) {
							const onChange = handleCustomerChange(form)
							onChange(customerOption.customer)
						}
					}}
				</OnChange>
			</div>

			{!isNewSubscription && (
				<div className="py-3">
					<label className="form-label">{t('form.registration_date')}</label>
					<div className="dates">
						<div className="date-left">
							<Field name={formFields.REGISTRATION_DATE} disabled={!isNewSubscription} width={180} component={DatePickerView} />
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

export default SubscriptionRegistrationDataSectionView