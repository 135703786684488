// @flow
import { Component } from 'react'

export type InputTextProps = {
	className?: ?string,
	password?: boolean,
}

class InputText extends Component<InputTextProps, void> {
	static defaultProps = {
		password: false,
	}

	render() {
		const { password, className, ...restProps } = this.props

		return (<input {...restProps} type={password ? 'password' : 'text'} className={className} />)
	}
}

export default InputText
