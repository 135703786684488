// @flow
import type { Node } from 'react'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

type Props = {|
	children: Node,
	onClick: MouseEventHandler,
	isGenerating?: boolean,
	readyToDownload?: boolean,
	disabled?: boolean,
|}

export const DownloadButton = ({ children, isGenerating, readyToDownload, disabled = false, onClick }: Props) => (
	<Button
		onClick={onClick}
		variant="contained"
		size="medium"
		disabled={disabled || isGenerating}
		color={readyToDownload ? 'success' : 'primary'}
		startIcon={
			isGenerating ? (
				<CircularProgress size="1rem" color="primary" fourColor={false} variant="indeterminate" />
			) : null
		}
	>
		{children}
	</Button>
)
