// @flow
import * as Action from 'shared/delivery/deliveryListActions'

import type { DeliverySort, RowsPerPageChange } from 'types/subscription/Subscription'
import type { Dispatch, ThunkAction } from 'types/Store'

export const updatePage = (page: number): ThunkAction => (dispatch: Dispatch): void => {
	dispatch(Action.updatePage(page))
}

export const updateRowsPerPage = (change: RowsPerPageChange): ThunkAction => (dispatch: Dispatch): void => {
	dispatch(Action.updateRowsPerPage(change))
}

export const updateSort = (sort: DeliverySort): ThunkAction => (dispatch: Dispatch): void => {
	dispatch(Action.updateSort(sort))
}
