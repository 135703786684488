// @flow
import { browserHistory } from 'browserHistory'

import type { Dispatch, ThunkAction } from 'types/Store'
import type { UserInfo } from 'types/auth'
import type { Try } from 'types/Try'

import { getHomePageUrl, PATH_CUSTOMER, PATH_LOGIN } from 'Paths'
import { setCurrentUser, clearCurrentUser } from 'shared/current-user'
import { getValue, isSuccess } from 'types/Try'
import { getCurrentUser } from 'services/auth-rest-service/AuthRestService'

export function logIn(redirectToHomePage: boolean): ThunkAction {
	return async (dispatch: Dispatch): Promise<void> => {
		const response: Try<UserInfo> = await getCurrentUser()
		if (isSuccess(response)) {
			dispatch(setCurrentUser(response))
			if (response.customer == null) {
				redirectTo(PATH_CUSTOMER)
			} else if (redirectToHomePage) {
				redirectTo(getHomePageUrl(getValue(response)))
			}
		} else {
			dispatch(clearCurrentUser())
			redirectTo(PATH_LOGIN)
		}
	}
}

function redirectTo(path: string): void {
	setTimeout(() => {
		browserHistory.push(path)
	}, 100)
}
