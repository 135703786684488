// @flow
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Loading from 'components/Loading'
import { AuthRestService } from 'services'

import { LOCALE_COMMON_COMPONENTS, LOCALE_NAMESPACE_COMMON } from 'constants/locale'

import type { Element } from 'react'
import type { Translator } from 'helpers'

import Cas from 'Cas'

function LogoutView(): Element<*> {
	const { t }: { t: Translator } = useTranslation(LOCALE_NAMESPACE_COMMON, { keyPrefix: LOCALE_COMMON_COMPONENTS })
	const [loading, setLoading] = useState<boolean>(false)

	const handleLogout = (): void => {
		setLoading(true)
		AuthRestService.logout()
			.finally(() => {
				setLoading(false)
				window.location.replace(`${Cas.getCasLogoutUrl()}?redirect=${window.location.origin}`)
			})
	}

	return (
		<button className="btn btn-link" onClick={handleLogout} type="button" disabled={loading}>
			{t('logout.button.label')}
			{loading ? <Loading /> : null}
		</button>

	)
}

export default LogoutView