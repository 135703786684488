// @flow
import { SubscriptionRestService } from 'services'
import { PageRequest, SortRequest } from 'helpers'

import { DELIVERY_COLUMN } from 'constants/subscription/Subscription'

import type { Try } from 'types/Try'
import type { Pageable } from 'types/Pageable'
import type { DeliveryDto, DeliverySort } from 'types/subscription/Subscription'

const COLUMN_TO_SORT_NAME: { [string]: string } = Object.freeze({
	[DELIVERY_COLUMN.DELIVERY_PERIOD]: 'appliedFrom',
	[DELIVERY_COLUMN.DELIVERY_DESTINATION]: 'destination_street',
})

export function getSubscriptionDeliveries(
	subscriptionId: number,
	page: number,
	rowsPerPage: number,
	sort: ?DeliverySort
): Promise<Try<Pageable<DeliveryDto>>> {
	return SubscriptionRestService.getSubscriptionDeliveries(subscriptionId, buildPageRequest(sort, page, rowsPerPage))
}

const buildPageRequest = (sort?: ?DeliverySort, page: number, rowsPerPage: number): PageRequest =>
	new PageRequest(page, rowsPerPage, nullableSort(sort))

const nullableSort = (sort?: ?DeliverySort): ?SortRequest =>
	sort ? new SortRequest(COLUMN_TO_SORT_NAME[sort.name] || sort.name, sort.direction) : null
