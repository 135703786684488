// @flow
import { useTranslation } from 'react-i18next'
import { List } from '@mui/material'
import DashboardIcon from '@mui/icons-material/Dashboard'
import SortIcon from '@mui/icons-material/Sort'
import type { Translator } from 'helpers'

import { getSortingPlansUrl, getSubscriptionsUrl } from 'Paths'
import { ROLE } from 'constants/auth/roles'
import { LOCALE_COMMON, LOCALE_NAMESPACE_COMMON } from 'constants/locale'

import PrimaryViewListItem from './PrimaryViewListItem'
import './PrimaryView.scss'

function PrimaryView() {
	const { t }: { t: Translator } = useTranslation(LOCALE_NAMESPACE_COMMON, { keyPrefix: LOCALE_COMMON })

	return (
		<div className="primary-view">
			<List>
				<PrimaryViewListItem
					availableForRole={ROLE.ROLE_PER_EXT_EXAMPLE}
					linkTo={getSubscriptionsUrl()}
					text={t('primary.navigation.subscriptions')}
					icon={<DashboardIcon />}
				/>
				<PrimaryViewListItem
					availableForRole={ROLE.ROLE_PER_EXT_EXAMPLE}
					linkTo={getSortingPlansUrl()}
					text={t('primary.navigation.sortingPlans')}
					icon={<SortIcon />}
				/>
			</List>
		</div>
	)
}

export default PrimaryView
