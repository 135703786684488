// @flow
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import { Popover } from '@mui/material'

import { LOCALE_NAMESPACE_SUBSCRIPTIONS, LOCALE_SUBSCRIPTIONS } from 'constants/locale'

import type { Translator } from 'helpers'
import type { DeliveryDto } from 'types/subscription/Subscription'

import './SubscriptionRedirectView.scss'
import SubscriptionRedirectForm from './Form/SubscriptionRedirectForm'

export type SubscriptionRedirectProps = {
	open: boolean,
	setOpen: (boolean) => void,
	subscriptionStartDate: ?string,
	name?: ?string,
	surname?: ?string,
	companyName?: ?string,
	subscriptionId?: ?number,
	delivery?: ?DeliveryDto,
}

function SubscriptionRedirectView({ open, setOpen, subscriptionStartDate, name, surname, companyName, subscriptionId, delivery }: SubscriptionRedirectProps) {
	const { t }: { t: Translator } = useTranslation(LOCALE_NAMESPACE_SUBSCRIPTIONS, { keyPrefix: LOCALE_SUBSCRIPTIONS })
	return (
		<Popover
			className="redirect-popover"
			open={open}
			onClose={(): void => {
				setOpen(false)
			}}
			anchorReference="anchorPosition"
			anchorPosition={{ top: 0, left: window.innerWidth }}
			marginThreshold={0}
		>
			<div className="top">
				<h1 className="redirect-heading">{t('redirect.title')}</h1>
				<button
					className="close-button"
					type="button"
					onClick={(): void => {
						setOpen(false)
					}}
				>
					<CloseIcon />
				</button>
			</div>
			<SubscriptionRedirectForm
				subscriptionStartDate={subscriptionStartDate}
				name={name}
				surname={surname}
				companyName={companyName}
				subscriptionId={subscriptionId}
				delivery={delivery}
			/>
		</Popover>
	)
}

export default SubscriptionRedirectView