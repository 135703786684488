// @flow
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { OnChange } from 'react-final-form-listeners'

import FormSwitch from 'components/Form/Switch'
import TitleLine from 'components/Form/TitleLine'
import { renderTextField } from 'components/Form/redux-material-ui/FormFieldBridges'

import * as formFields from 'constants/subscription/form/SubscriptionForm'
import { LOCALE_NAMESPACE_SUBSCRIPTIONS, LOCALE_SUBSCRIPTIONS } from 'constants/locale'

import type { Translator } from 'helpers'
import type { SubscriptionFormValues } from 'types/subscription/form/SubscriptionForm'
import type { FormApi } from 'final-form'

import { clearSubscriber, handleSubscriberChange } from '../../SubscriptionCreateActions'

export type SubscriberDataProps = {|
	form: FormApi<SubscriptionFormValues>,
	values: ?SubscriptionFormValues,
	isNewSubscription: boolean,
|}

function SubscriberDataSectionView({ form, values, isNewSubscription }: SubscriberDataProps) {
	const { t }: { t: Translator } = useTranslation(LOCALE_NAMESPACE_SUBSCRIPTIONS, { keyPrefix: LOCALE_SUBSCRIPTIONS })
	return (
		<div>
			<TitleLine title={t('form.subscriber')} />
			{isNewSubscription && (
				<div className="py-3">
					<label className="form-label">{t('form.is_same_as_recipient')}</label>
					<div className="field">
						<Field
							disabled={!isNewSubscription}
							name={formFields.IS_SAME_AS_RECIPIENT}
							type="checkbox"
							component={FormSwitch}
						/>
						<OnChange name={formFields.IS_SAME_AS_RECIPIENT}>
							{(formValues: SubscriptionFormValues): void => {
								if (formValues && values) {
									handleSubscriberChange(form, values)
								} else {
									clearSubscriber(form)
								}
							}}
						</OnChange>
					</div>
				</div>
			)}
			<div className="py-3">
				<label className="form-label">{t('form.subscriber_name')}</label>
				<Field
					disabled={!isNewSubscription}
					className="field"
					name={formFields.SUBSCRIBER_NAME}
					component={renderTextField}
				/>
			</div>
			<div className="py-3">
				<label className="form-label">{t('form.subscriber_surname')}</label>
				<Field
					disabled={!isNewSubscription}
					className="field"
					name={formFields.SUBSCRIBER_SURNAME}
					component={renderTextField}
				/>
			</div>
			<div className="py-3">
				<label className="form-label">{t('form.company_name')}</label>
				<Field
					disabled={!isNewSubscription}
					className="field"
					name={formFields.SUBSCRIBER_COMPANY_NAME}
					component={renderTextField}
				/>
			</div>
			<div className="py-3">
				<label className="form-label">{t('form.register_code')}</label>
				<Field
					disabled={!isNewSubscription}
					className="field"
					name={formFields.REGISTER_CODE}
					component={renderTextField}
				/>
			</div>
			<div className="py-3">
				<label className="form-label">{t('form.subscriber_phone')}</label>
				<Field
					disabled={!isNewSubscription}
					className="field"
					name={formFields.SUBSCRIBER_PHONE}
					component={renderTextField}
				/>
			</div>
			<div className="py-3">
				<label className="form-label">{t('form.subscriber_email')}</label>
				<Field
					disabled={!isNewSubscription}
					className="field"
					name={formFields.SUBSCRIBER_EMAIL}
					component={renderTextField}
				/>
			</div>
		</div>
	)
}

export default SubscriberDataSectionView