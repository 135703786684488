// @flow
import { isArray, isEmpty, isString } from 'lodash'
import moment from 'moment'

import { t } from 'helpers'

import { DEFAULT_DATE_FORMAT } from 'constants/datetime'
import { SERVER_DATE_FORMAT } from 'helpers/converter/datesTransfomer'

const NUMBER_REGEXP = /^[0-9]*$/
const EMAIL_REGEXP = /^\S+@\S+\.\S+$/
const PRODUCT_CODE_REGEXP = /^[A-Za-z0-9]*$/

export const required = (
	value: any,
	messageKey: string = 'validation.input.required_field',
) => {
	if (isString(value)) {
		return isEmpty(value.trim()) ? t(messageKey) : undefined
	}
	if (isArray(value)) {
		return isEmpty(value) ? t(messageKey) : undefined
	}
	return (value == null) ? t(messageKey) : undefined
}

export const minInt = (
	value: any,
	min: any,
	messageKey: string = 'validation.input.less_than_min',
): ?string => (
	value != null && min != null && parseInt(value, 10) < parseInt(min, 10) ? t(messageKey, { min }) : undefined
)

export const maxInt = (
	value: any,
	max: any,
	messageKey: string = 'validation.input.greater_than_max',
): ?string => (
	value != null && max != null && parseInt(value, 10) > parseInt(max, 10) ? t(messageKey, { max }) : undefined
)

export const maxLength = (
	value: any = '',
	length: number = 255,
	messageKey: string = 'validation.input.maximum_length',
): ?string => {
	const currentValue: string = isString(value) ? value : String(value)
	return currentValue && currentValue.length > length
		? t(messageKey, { maxLength: length })
		: undefined
}

export const minLength = (
	value: any = '',
	length: number = 0,
	messageKey: string = 'validation.input.minimum_length',
) => {
	const currentValue: string = isString(value) ? value : String(value)
	return currentValue && currentValue.length < length
		? t(messageKey, { minLength: length })
		: undefined
}

export const isDate = (
	value: any = null,
	format: string = DEFAULT_DATE_FORMAT,
	messageKey: string = 'validation.input.is_date',
) => {
	if (!value) {
		return undefined
	}

	if (moment(value).isValid()) {
		return undefined
	}

	if (moment.isMoment(value)) {
		return undefined
	}

	return moment(value.trim(), format, true).isValid() ? undefined : t(messageKey)
}

export const isDateValid = (
	value: ?string = null,
	format: string = SERVER_DATE_FORMAT,
	messageKey: string = 'validation.input.is_date',
) => (value == null || moment(value, format, true).isValid() ? undefined : t(messageKey))


export const isDateBeforeOrSame = (
	value: any = null,
	dateAfter: any = null,
	messageKey: string = 'validation.input.is_date_before_or_same',
) => {
	if (value == null || dateAfter == null || !!isDate(value) || !!isDate(dateAfter)) {
		return undefined
	}
	const start = moment(value).startOf('day')
	const end = moment(dateAfter).startOf('day')
	return start.isAfter(end) ? t(messageKey, { dateAfter: moment(dateAfter).format(DEFAULT_DATE_FORMAT) }) : undefined
}

export const isDateBefore = (
	value: any = null,
	dateAfter: any = null,
	messageKey: string = 'validation.input.is_date_before',
) => {
	if (value == null || dateAfter == null || !!isDate(value) || !!isDate(dateAfter)) {
		return undefined
	}
	const start = moment(value).startOf('day')
	const end = moment(dateAfter).startOf('day')
	return start.isBefore(end) ? undefined : t(messageKey, { dateAfter: moment(dateAfter).format(DEFAULT_DATE_FORMAT) })
}

export const isDateTodayOrAfter = (
	value: any = null,
	dateAfter: any = moment(),
	messageKey: string = 'validation.input.is_date_today_or_afer',
) => {
	if (value == null || dateAfter == null || !!isDate(value) || !!isDate(dateAfter)) {
		return undefined
	}
	const start = moment(value).startOf('day')
	const end = moment(dateAfter).startOf('day')
	return start.isAfter(end) || start.isSame(end) ? undefined : t(messageKey, { dateAfter: moment(dateAfter).format(DEFAULT_DATE_FORMAT) })
}

export const isDateRangeInLimits = (
	startDate: any = null,
	endDate: any = null,
	rangeInDays: number,
	messageKey: string = 'validation.input.is_date_range_in_limits',
) => {
	if (isDateBeforeOrSame(startDate, endDate)) {
		return undefined
	}

	return moment(startDate)
		.startOf('day')
		.add(rangeInDays, 'days')
		.isBefore(endDate) ? t(messageKey, { rangeInDays }) : undefined
}

export const numberAsString = (
	value: any = null,
	messageKey: string = 'validation.input.only_numbers',
) => {
	if (!value) {
		return undefined
	}

	return NUMBER_REGEXP.test(value)
		? undefined
		: t(messageKey)
}

export const email = (
	value: any = null,
	messageKey: string = 'validation.input.is_email',
): ?string => {
	if (!value) {
		return undefined
	}

	return EMAIL_REGEXP.test(value)
		? undefined
		: t(messageKey)
}

export const unique = (
	value: any = null,
	previousValues: Array<any> = [],
	messageKey: string = 'validation.input.is_unique',
) => (
	!value || !previousValues.includes(value)
		? undefined
		: t(messageKey)
)

export const isValidProductCode = (
	value: any = null,
	messageKey: string = 'validation.input.valid_product_code',
) => {
	if (!value) {
		return undefined
	}

	return PRODUCT_CODE_REGEXP.test(value)
		? undefined
		: t(messageKey)
}

export const isDateAfter = (
	value: any = null,
	dateBefore: any = null,
	messageKey: string = 'validation.input.is_date_earlier',
) => {
	if (value == null || dateBefore == null || !!isDate(value) || !!isDate(dateBefore)) {
		return undefined
	}
	const start = moment(dateBefore).startOf('day')
	const end = moment(value).startOf('day')
	return start.isAfter(end) ? t(messageKey, { dateBefore: moment(dateBefore).format(DEFAULT_DATE_FORMAT) }) : undefined
}

export const isDateWeekday = (
	value: any,
	messageKey: string = 'validation.input.date_weekday',
) => {
	if (value == null || !!isDate(value)) {
		return undefined
	}
	const day = moment(value).startOf('day')
	return (day.isoWeekday() === 6 || day.isoWeekday() === 7) ? t(messageKey) : undefined
}