// @flow
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Typography } from '@mui/material'
import { NotificationManager } from 'react-notifications'

import { AuthRestService } from 'services'

import { LOCALE_COMMON_COMPONENTS, LOCALE_NAMESPACE_COMMON } from 'constants/locale'

import type { Element } from 'react'
import type { Translator } from 'helpers'
import type { UserInfo } from 'types/auth'
import type { Try } from 'types/Try'
import type { Store } from 'types/Store'
import type { CustomerInfo } from 'types/auth/CustomerInfo'

import { browserHistory } from 'browserHistory'
import { LanguageSelectForLogin, Loading, Logout } from 'components'
import { PATH_SPLASH } from 'Paths'
import { getError, isSuccess } from 'types/Try'

import omnivaLogo from 'assets/images/omniva.svg'

import './CustomerView.scss'

const SHOW_TIME_MS: number = 10000

function CustomerView(): Element<*> {
	const { t }: { t: Translator } = useTranslation(LOCALE_NAMESPACE_COMMON, { keyPrefix: LOCALE_COMMON_COMPONENTS })

	const [customers, setCustomers] = useState<Array<CustomerInfo>>([])
	const [loading, setLoading] = useState<boolean>(true)

	const userInfo: ?UserInfo = useSelector((state: Store) => state.currentUser?.userInfo)
	const userName: string = userInfo ? `${userInfo.firstName} ${userInfo.lastName}` : ''

	useEffect(() => {
		if (userInfo == null) {
			redirectTo(PATH_SPLASH)
			return
		}

		AuthRestService.getCurrentUserMandates()
			.then((response: Try<Array<CustomerInfo>>) => {
				if (isSuccess(response)) {
					setCustomers(response)
					if (response.length === 0) {
						NotificationManager.error(t('auth.contact.admin'),
							t('auth.represent.customer.missing'), SHOW_TIME_MS)
					}
				} else {
					NotificationManager.error(getError(response).message, t('auth.customers.load.error'), SHOW_TIME_MS)
				}
			})
			.finally(() => {
				setLoading(false)
			})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className="customer-view">
			<div className="customer-view__container d-flex">
				<div className="customer-view__container__left col">
					<div className="customer-view__container__left__logo">
						<img src={omnivaLogo} alt="logo" />
						<div className="customer-view__container__left__logo__slogan">{t('login.view.slogan')}</div>
					</div>
				</div>
				<div className="customer-view__container__right col align-items-center">
					<div className="customer-view__container__right__top">
						<LanguageSelectForLogin />
					</div>
					<div className="customer-select__container">
						<div className="space-between-container mt-4">
							<Typography variant="title" sx={{ textTransform: 'uppercase' }}>
								{t('auth.user')}: {userName}
							</Typography>
							<Logout />
						</div>
						<hr />
						<Typography variant="subtitle2">{t('auth.represent.customer')}</Typography>
						<hr />
						<div>
							{customers.map((customer: CustomerInfo): Element<*> => (
								<div key={customer.customerId} className="space-between-container">
									<button
										className="btn btn-link"
										type="button"
										onClick={(): void => {
											selectCustomer(customer)
										}}
										disabled={loading}
									>
										<Typography variant="subtitle1">{customer.name}</Typography>
									</button>
									<Typography variant="subtitle1">{customer.regNumber}</Typography>
								</div>
							))}
							{loading ? <Loading /> : null}
						</div>
						<hr />
					</div>
				</div>
			</div>
		</div>
	)

	function selectCustomer(customer: CustomerInfo): void {
		setLoading(true)
		AuthRestService.selectUserCustomer(customer.customerId)
			.then((response: Try<void>): void => {
				if (isSuccess(response)) {
					redirectTo(PATH_SPLASH)
				} else {
					NotificationManager.error(getError(response).message, t('auth.customer.fail'), SHOW_TIME_MS)
				}
			})
			.finally((): void => {
				setLoading(false)
			})
	}
}

function redirectTo(path: string) {
	setTimeout((): void => {
		browserHistory.push(path)
	}, 100)
}

export default CustomerView
