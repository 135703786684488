// @flow
import AsyncSelect from 'react-select/async'
import { debounce } from 'lodash'
import { NotificationManager } from 'react-notifications'
import { useTranslation } from 'react-i18next'
import { FormControl, FormHelperText } from '@mui/material'

import AddressRestService from 'services/address-rest-service'
import { getError, getValue, isSuccess } from 'types/Try'
import { handleAddressTextChange } from 'views/Subscription/SubscriptionCreateActions'
import type { SubscriptionFilters } from 'types/subscription/Subscription'
import { LOCALE_AUTO_SUGGEST_ADDRESS, LOCALE_NAMESPACE_COMMON } from 'constants/locale'

import type { FormApi } from 'final-form'
import type { Pageable } from 'types/Pageable'
import type { Translator } from 'helpers'
import type { Try } from 'types/Try'
import type { AddressDto, AddressOption } from 'types/address'
import type { Element } from 'react'
import type { FieldRenderProps } from 'react-final-form'
import type { ServicePointOrAddressDto } from 'types/address/Address'

export type AutoSuggestAddressWithTextProps = {|
	onBlur?: () => void,
	onChange: (AddressDto) => void,
	onFocus?: () => void,
	formControlClassName: string,
	form: FormApi<SubscriptionFilters>,
|}

const MIN_LENGTH: number = 3
const WAIT_TIME_MS: number = 500
const NOTIFICATION_SHOW_TIME_MS: number = 10000

function AutoSuggestAddressViewWithText({
	onBlur,
	onFocus,
	formControlClassName,
	input,
	meta: { touched, submitFailed, error },
	form,
}: AutoSuggestAddressWithTextProps & FieldRenderProps): Element<*> {
	const { t }: { t: Translator } = useTranslation(LOCALE_NAMESPACE_COMMON, { keyPrefix: LOCALE_AUTO_SUGGEST_ADDRESS })

	const onInputChange = (userInput, { action }) => {
		if (action === 'input-change') {
			handleAddressTextChange(form, userInput)
		}
	}
	const handleBlur = (): void => {
		if (onBlur) {
			onBlur()
		}
	}

	const handleFocus = (): void => {
		if (onFocus) {
			onFocus()
		}
	}

	const handleOptionsLoading = (searchValue: string, setOptionsCallback: Function): void => debounceSuggestAddress(searchValue, setOptionsCallback)

	const debounceSuggestAddress: (searchValue: string, setOptionsCallback: Function) => void = debounce((searchValue, setOptionsCallback) => {
		suggestAddresses(searchValue).then(options => {
			setOptionsCallback(options)
		})
	}, WAIT_TIME_MS)

	const suggestAddresses = async (searchValue: string): Promise<Array<AddressOption>> => {
		if (searchValue && searchValue.length >= MIN_LENGTH) {
			const response: Try<Pageable<AddressDto>> = await AddressRestService.searchAddress(searchValue)
			if (isSuccess(response)) {
				return mapToOptions(getValue(response).content)
			} else {
				NotificationManager.error(getError(response).message, t('search.fail'), NOTIFICATION_SHOW_TIME_MS)
				return []
			}
		} else {
			return []
		}
	}

	const mapToOptions = (addresses: Array<ServicePointOrAddressDto>): Array<AddressOption> => addresses
		.map((servicePointOrAddress: ServicePointOrAddressDto): AddressOption => {
			if (servicePointOrAddress.servicePoint && servicePointOrAddress.servicePoint.address) {
				return {
					address: servicePointOrAddress.servicePoint.address,
					label: servicePointOrAddress.servicePoint.name,
					id: servicePointOrAddress.servicePoint.id,
					typeCode: servicePointOrAddress.servicePoint.typeCode,
				}
			} else if (servicePointOrAddress.address) {
				return {
					address: servicePointOrAddress.address,
					label: servicePointOrAddress.address.fullAddress,
				}
			}
			throw new TypeError('servicePointOrAddress.servicePoint or servicePointOrAddress.address must not be null')
		})
		.filter((option: AddressOption): boolean => Boolean(option.label))

	return (
		<FormControl className={formControlClassName} error={error && (touched || submitFailed)}>
			<AsyncSelect
				loadOptions={handleOptionsLoading}
				onChange={input.onChange}
				value={input.value}
				placeholder={t('placeholder')}
				noOptionsMessage={() => t('no_options')}
				loadingMessage={() => t('loading')}
				onBlur={handleBlur}
				onFocus={handleFocus}
				classNamePrefix="per-address-box"
				isClearable
				onInputChange={onInputChange}
				defaultOptions
				styles={{
					// Fixes the overlapping problem of the component
					menu: provided => ({ ...provided, zIndex: 9999 }),
				}}
			/>
			{(error && (touched || submitFailed)) ? (
				<FormHelperText>{(touched || submitFailed) && Object.values(error)}</FormHelperText>
			) : null}
		</FormControl>
	)
}

export default AutoSuggestAddressViewWithText