// @flow
import { batch } from 'react-redux'
import type { RowsPerPageChange, SubscriptionFilters, SubscriptionSort } from 'types/subscription/Subscription'
import type { Dispatch, ThunkAction } from 'types/Store'

import * as Action from 'shared/subscription/subscriptionListActions'

export const updatePage = (page: number): ThunkAction => (dispatch: Dispatch): void => {
	dispatch(Action.updatePage(page))
}

export const updateRowsPerPage = (change: RowsPerPageChange): ThunkAction => (dispatch: Dispatch): void => {
	dispatch(Action.updateRowsPerPage(change))
}

export const updateFilters = (filters: SubscriptionFilters): ThunkAction => (dispatch: Dispatch): void => {
	batch(() => {
		dispatch(Action.updateFilters(filters))
		dispatch(Action.updatePage(0))
	})
}

export const updateSort = (sort: SubscriptionSort): ThunkAction => (dispatch: Dispatch): void => {
	dispatch(Action.updateSort(sort))
}