// @flow
import get from 'lodash/get'

import type { ClientError } from 'types/ClientError'

export function isUnauthorizedStatus(error: Error): boolean {
	return getStatus(error) === 401
}

export function isNotFoundStatus(error: Error): boolean {
	return getStatus(error) === 404
}

export function isGatewayTimeoutStatus(error: Error): boolean {
	return getStatus(error) === 504
}

export function getClientError(error: mixed): ?ClientError {
	return get(error, 'response.data.code', null)
}

function getStatus(error: Error): ?number {
	return get(error, 'response.status')
}
