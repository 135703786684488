// @flow
import { FormControl, FormHelperText, TextField } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import { useEffect } from 'react'

import type { FieldRenderProps } from 'react-final-form'
import type { Element } from 'react'

import { UI_DATE_FORMAT, uiDateToServerDateString } from 'helpers/converter/datesTransfomer'

import './DatePickerView.scss'

type OwnProps = {|
	minDate?: moment,
	maxDate?: moment,
	width?: number,
	disableWeekends?: boolean,
	disabled?: boolean
|}

function isWeekend(date: moment): boolean {
	return date.isoWeekday() === 6 || date.isoWeekday() === 7
}

function DatePickerView({
	input,
	meta: { touched, submitFailed, error },
	minDate,
	maxDate,
	width,
	disableWeekends,
	disabled,
}: FieldRenderProps & OwnProps): Element<*> {
	useEffect(() => {
		moment.locale('en', { week: { dow: 1 } })
	}, [])

	return (
		<div className="date-picker">
			<FormControl error={error && (touched || submitFailed)}>
				<LocalizationProvider className="date-picker" dateAdapter={AdapterMoment}>
					<DatePicker
						className="date-picker"
						value={input.value || null}
						onChange={(value: Date): void => {
							input.onChange(uiDateToServerDateString(value))
						}}
						disableToolbar
						variant="inline"
						margin="normal"
						inputFormat={UI_DATE_FORMAT}
						format={UI_DATE_FORMAT}
						minDate={minDate}
						maxDate={maxDate}
						disabled={disabled}
						renderInput={(params) => <TextField sx={{ width }} className="date-picker" {...params} />}
						shouldDisableDate={disableWeekends && isWeekend}
						calendarStartDay={2}
						defaultCalendarMonth={minDate}
						InputProps={{
							onFocus: (): void => {
								input.onFocus()
							},
							onBlur: (): void => {
								input.onBlur()
							},
						}}
					/>
				</LocalizationProvider>
				{(error && (touched || submitFailed)) ? (
					<FormHelperText>{(touched || submitFailed) && error}</FormHelperText>
				) : null}
			</FormControl>
		</div>
	)
}

export default DatePickerView