// @flow
import { handleActions } from 'redux-actions'
import { COLUMN, ROWS_PER_PAGE_OPTIONS, STATUS, REGISTRANT } from 'constants/subscription/Subscription'

import type { Action, SubscriptionListStore } from 'types/Store'
import type { RowsPerPageChange, SubscriptionFilters, SubscriptionSort } from 'types/subscription/Subscription'

import { updateFilters, updatePage, updateRowsPerPage, updateSort } from './subscriptionListActions'

const initialState: SubscriptionListStore = {
	page: 1,
	rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
	filters: {
		limit: 100,
		status: STATUS.ALL,
		registrantId: REGISTRANT.ALL,
	},
	sort: {
		name: COLUMN.RECIPIENT,
		direction: 'asc',
	},
}

export default handleActions<SubscriptionListStore, Action>(
	{
		[updatePage.toString()](state, { payload }: { payload: number }) {
			return {
				...state,
				page: payload,
			}
		},
		[updateRowsPerPage.toString()](state, { payload }: { payload: RowsPerPageChange }) {
			return {
				...state,
				rowsPerPage: payload.rowsPerPage,
				page: payload.page,
			}
		},
		[updateFilters.toString()](state, { payload }: { payload: SubscriptionFilters }) {
			return {
				...state,
				filters: payload,
			}
		},
		[updateSort.toString()](state, { payload }: { payload: SubscriptionSort }) {
			return {
				...state,
				sort: payload,
			}
		},
	},
	initialState
)
