// @flow
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import type { Element } from 'react'
import { useMemo, useState } from 'react'
import useSWR from 'swr'
import moment from 'moment'
import get from 'lodash/get'
import type { FormRenderProps } from 'react-final-form'
import { Field, Form } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import Box from '@mui/material/Box'
import { ThemeProvider } from '@mui/material/styles'
import noop from 'lodash/noop'
import identity from 'lodash/identity'
import DatePickerView from 'components/Form/DatePicker'
import AccordionFooter from 'components/CustomFooter/AccordionFooter'
import { LOCALE_NAMESPACE_SORTING_PLANS, LOCALE_SORTING_PLANS } from 'constants/locale'
import { getAllProductsWithPackages } from 'views/Product/List/serviceWrapper'
import { getValue, isSuccess } from 'types/Try'
import { PRODUCT_REGISTER_STATUS } from 'constants/product/Product'
import { ROWS_PER_PAGE_OPTIONS } from 'constants/subscription/Subscription'
import { NotificationManager } from 'react-notifications'

import type { SortOrder } from 'types/components/Table'
import type { SubscriptionFormValues } from 'types/subscription/form/SubscriptionForm'
import type { Translator } from 'helpers'
import type { GeneratePvpSortPlanRequest, PvpSortPlanGenerationProductDto } from 'services/sort-plan-rest-service/types'
import type { SortingPlansContextState } from '../SortingPlansContext'

import { DownloadButton } from './DownloadButton'
import { AccordionView } from './AccordionView'
import { omnivaOrangeTheme } from '../Theme'
import { useSortingPlansContext } from '../SortingPlansContext'
import { startGeneration } from '../Service/serviceWrapper'

const PUBLISH_DATE = 'publishDate'
const INITIAL_ROWS_PAGE = ROWS_PER_PAGE_OPTIONS[0]

const fetcher =
	({ page, limit, direction }: { page: number, limit: number, direction: SortOrder }) =>
	async () =>
		getAllProductsWithPackages(
			page - 1,
			limit,
			{ registerStatus: PRODUCT_REGISTER_STATUS.ACTIVE },
			{ name: 'name', direction }
		)

const makeProductsDataFromState = (state: SortingPlansContextState) =>
	Object.keys(state.selectedPackagesCodes).reduce((acc: PvpSortPlanGenerationProductDto[], val: string) => {
		const current: PvpSortPlanGenerationProductDto = {
			productCode: val,
			selected: state.selectedProductsCodes.includes(val),
			relatedPackageCodes: get(state.selectedPackagesCodes, val),
		}
		return [...acc, current]
	}, [])

export const SortingPlansListView = () => {
	const { t }: { t: Translator } = useTranslation(LOCALE_NAMESPACE_SORTING_PLANS, { keyPrefix: LOCALE_SORTING_PLANS })

	const [rowsPerPage, setRowsPerPage] = useState(INITIAL_ROWS_PAGE)
	const [currentPage, setCurrentPage] = useState(1)
	const [sortDirection] = useState<SortOrder>('asc')
	const { state, dispatch } = useSortingPlansContext()
	const [started, setStarted] = useState<boolean>(false)
	const [fileLink, setFileLink] = useState<?string>()

	const allSelectedPackages = Object.values(state.selectedPackagesCodes).flatMap(identity)

	const { data, error, isLoading } = useSWR(
		`products-with-packages-${rowsPerPage}-page:${currentPage}-${sortDirection}`,
		fetcher({
			page: currentPage,
			limit: rowsPerPage,
			direction: sortDirection,
		})
	)

	const handleStart = async () => {
		setStarted(true)

		const request: GeneratePvpSortPlanRequest = {
			issuingDate: state.selectedDate,
			products: makeProductsDataFromState(state),
		}

		const startGenerationResponse = await startGeneration(request)

		if (isSuccess(startGenerationResponse)) {
			setFileLink(startGenerationResponse.url)
		} else {
			NotificationManager.error('Error Starting Generation: translate me')
		}

		setStarted(false)
	}

	const handleFileDownload = (generatedFileLink: string) => (event: Object) => {
		event.preventDefault()
		event.stopPropagation()
		window.location.href = getValue(generatedFileLink)

		setStarted(false)
		setFileLink()
	}

	const canGenerate = useMemo(
		() => state.selectedDate && (state.selectedProductsCodes.length || allSelectedPackages.length),
		[state.selectedDate, state.selectedProductsCodes.length, allSelectedPackages.length]
	)

	const products = isSuccess(data) ? getValue(data).content : []
	const totalElements = isSuccess(data) ? getValue(data).totalElements : 0

	if (error) return <div>Failed to load products</div>
	if (isLoading) return <div>Product are loading...</div>

	return (
		<ThemeProvider theme={omnivaOrangeTheme}>
			<Grid className="subscription-list-view client-area" container justify="flex-start" alignItems="flex-start">
				<Grid item xs={12}>
					<div className="title-row">
						<h3>{t('view.title')}</h3>
					</div>
				</Grid>

				<Form
					onSubmit={noop}
					render={({ handleSubmit }: FormRenderProps<SubscriptionFormValues>): Element<*> => (
						<Grid container direction="row" justifyContent="space-between" alignItems="center">
							<Grid item xs={6}>
								<form
									onSubmit={async (event) => {
										event.preventDefault()
										await handleSubmit(event)
									}}
								>
									<div className="py-4 pr-4">
										<label className="form-label">{t('form.publish.date')}</label>
										<Field name={PUBLISH_DATE} width={180} component={DatePickerView} />
										<OnChange name={PUBLISH_DATE}>
											{(date: moment): void => {
												dispatch({
													type: 'setCurrentDate',
													payload: moment(date).format('YYYY-MM-DD'),
												})
											}}
										</OnChange>
									</div>
								</form>
							</Grid>
							<Grid container justifyContent="flex-end" xs={6}>
								<Box my={4} display="flex" alignItems="flex-end" gap={4} p={2}>
									{!(started || fileLink) && (
										<DownloadButton disabled={!canGenerate} onClick={handleStart}>
											{t('button.pvp.start')}
										</DownloadButton>
									)}
									{started && !fileLink && (
										<DownloadButton onClick={noop} isGenerating>
											{t('button.pvp.generating')}
										</DownloadButton>
									)}
									{fileLink && (
										<DownloadButton onClick={handleFileDownload(fileLink)} readyToDownload>
											{t('button.pvp.download')}
										</DownloadButton>
									)}
								</Box>
							</Grid>
						</Grid>
					)}
				/>

				<Grid item xs={12} className="table-grid">
					{isSuccess(data) && <AccordionView products={products} disabled={started} />}
				</Grid>
				<Grid item xs={12}>
					<AccordionFooter
						page={currentPage}
						rowsPerPage={rowsPerPage}
						changeRowsPerPage={setRowsPerPage}
						changePage={setCurrentPage}
						totalElements={totalElements}
						rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
					/>
				</Grid>
			</Grid>
			<span className="m-4 p-4 bg-dark text-white invisible">{JSON.stringify(state, null, 2)}</span>
		</ThemeProvider>
	)
}
