// @flow
import { connect } from 'react-redux'

import * as Selector from 'shared/delivery/deliveryListSelectors'

import type { Store, ThunkAction } from 'types/Store'
import type { DeliverySort, RowsPerPageChange } from 'types/subscription/Subscription'

import DeliveryListView from './DeliveryListView'
import {
	updatePage,
	updateRowsPerPage,
	updateSort,
} from './DeliveryListActions'

import type { DeliveryListViewProps } from './DeliveryListView'

type OwnProps = {|
	subscriptionId: ?string,
|}

type StateProps = {|
	page: number,
	rowsPerPage: number,
	sort: ?DeliverySort,
|}

type DispatchProps = {|
	updatePage: (page: number) => ThunkAction,
	updateRowsPerPage: (change: RowsPerPageChange) => ThunkAction,
	updateSort: (sort: DeliverySort) => ThunkAction,
|}

const mapStateToProps = (state: Store): StateProps => ({
	page: Selector.getPage(state),
	rowsPerPage: Selector.getRowsPerPage(state),
	sort: Selector.getSort(state),
})

const mapDispatchToProps: DispatchProps = {
	updatePage,
	updateRowsPerPage,
	updateSort,
}

export default connect<DeliveryListViewProps, OwnProps, StateProps, DispatchProps, _, _>(mapStateToProps, mapDispatchToProps)(DeliveryListView)